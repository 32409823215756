import axios from "axios";
import { config } from "../../config/config";

const projectsUrl = `${config.apiURL}/reports`;
const previewUrl = `${config.apiURL}/preview`;

export const saveProject = (
  title: string,
  width: number,
  height: number,
  canvasJSONs: any[]
): Promise<any> => {
  return axios.post(
    projectsUrl,
    {
      title,
      width,
      height,
      canvasData: canvasJSONs,
    },
    {
      headers: { Authorization: window.localStorage.getItem("accessToken") },
    }
  );
};

export const fetchProjects = (
  title?: string,
  sort_by?: number,
  page?: number,
  limit?: number
): Promise<any> => {
  const titleCheck = title !== "" ? title : null;
  return axios.get(`${projectsUrl}`, {
    headers: { Authorization: window.localStorage.getItem("accessToken") },
    params: {
      title: titleCheck,
      sort_by,
      page,
      limit,
    },
  });
};

export const fetchProjectsWithSort = (sort_by: number): Promise<any> => {
  return axios.get(`${projectsUrl}`, {
    headers: { Authorization: window.localStorage.getItem("accessToken") },
    params: {
      sort_by,
    },
  });
};

export const fetchProjectById = (projectId: string): Promise<any> => {
  return axios.get(`${projectsUrl}/${projectId}`, {
    headers: { Authorization: window.localStorage.getItem("accessToken") },
  });
};

export const previewReportById = (projectId: string): Promise<any> => {
  return axios.get(`${previewUrl}/reports/${projectId}`);
};

export const printReportById = (projectId: string): Promise<any> => {
  return axios.get(`${previewUrl}/reports/${projectId}/download`, {
    responseType: "blob"
  });
};

export const deleteProjects = (projectId: string): Promise<any> => {
  return axios.delete(`${projectsUrl}/${projectId}`, {
    headers: { Authorization: window.localStorage.getItem("accessToken") },
  });
};

export const createNewProject = (projectInfo: any): Promise<any> => {
  const { width, height, userId, canvasData, pageNumber } = projectInfo;
  return axios.post(
    `${projectsUrl}/new`,
    {
      width,
      height,
      userId,
      canvasData,
      pageNumber,
    },
    {
      headers: { Authorization: window.localStorage.getItem("accessToken") },
    }
  );
};

export const createNewProjectPage = (
  projectId: string,
  pageNumber: number
): Promise<any> => {
  return axios.post(
    `${projectsUrl}/new-page/${projectId}`,
    {
      pageNumber,
    },
    {
      headers: { Authorization: window.localStorage.getItem("accessToken") },
    }
  );
};

export const updateProjectCanvases = (
  projectId: string,
  parserId: string,
  title: string,
  width: number,
  height: number,
  canvasJSONs: any[]
): Promise<any> => {
  return axios.put(
    `${projectsUrl}/${projectId}`,
    {
      title,
      parserId,
      width,
      height,
      canvasData: canvasJSONs,
    },
    {
      headers: { Authorization: window.localStorage.getItem("accessToken") },
    }
  );
};

export const autoSaveCanvases = (
  projectId: string,
  canvasData: any,
  pageNumber: number,
  headerFooterObjs?: Array<any>
) => {
  return axios.put(
    `${projectsUrl}/${projectId}`,
    {
      canvasData: { objects: [...canvasData] },
      pageNumber,
      headerFooter: headerFooterObjs,
    },
    {
      headers: { Authorization: window.localStorage.getItem("accessToken") },
    }
  );
};

export const saveReportParser = (
  reportId: string,
  parserId: string
): Promise<any> => {
  return axios.put(
    `${projectsUrl}/save-parser/${reportId}`,
    {
      parserId,
    },
    {
      headers: {
        Authorization: window.localStorage.getItem("accessToken"),
      },
    }
  );
};

export const updateReportTitle = (reportId: any, title: string) => {
  return axios.put(
    `${projectsUrl}/update-title/${reportId}`,
    {
      title,
    },
    {
      headers: {
        Authorization: window.localStorage.getItem("accessToken"),
      },
    }
  );
};
