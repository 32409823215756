import { AuthState } from "../../interfaces/user.interface";
import { FETCH_AUTH, LOGOUT } from "../actions/types";

const initialState: AuthState = {
  auth:
    !!window.localStorage.getItem("accessToken") &&
    !!window.localStorage.getItem("auth")
      ? JSON.parse(window.localStorage.getItem("auth") || "{}")
      : {},
  isAuthenticated: !!window.localStorage.getItem("accessToken"),
};

export default (state: AuthState = initialState, action: any): AuthState => {
  switch (action.type) {
    case FETCH_AUTH:
      return {
        ...state,
        auth: action.payload,
        isAuthenticated: true,
      };
    case LOGOUT:
      return {
        ...state,
        auth: {},
        isAuthenticated: false,
      };
    default:
      return state;
  }
};
