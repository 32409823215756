import React, { FC } from 'react';
import { useDebounce } from 'use-debounce';
import Navbar from '../../components/navbar/Navbar';
import SearchFile from './_components/SearchFile/SearchFile';
import TemplateBar from './_components/TemplateBar/TemplateBar';
import TemplatesTable from './_components/TemplatesTable/TemplatesTable';
import { getParser } from '../../core/services/cms.service';
import {
	fetchProjects,
	deleteProjects,
} from '../../core/services/project.service';
import { buildParser, Parser } from '../../core/interfaces/parser.interface';
import './template.scss';
import Shares from '../Shares/Shares';

const Templates = () => {
	const [templates, setTemplates] = React.useState<any>([]);
	const [parserList, setParserList] = React.useState<Array<Parser>>([]);
	const [search, setSearch] = React.useState('');
	const [text] = useDebounce(search, 1000);
	const [sortOptions, setSortOptions] = React.useState(0);
	const [page, setPage] = React.useState<number>(1);
	const [limit] = React.useState(6);
	const [hasMore, setHasMore] = React.useState<boolean>(true);
	const [paginationLoader, setPaginationLoader] = React.useState(false);
	const [displayComponent, setDispayComponent] = React.useState({
		reports: true,
		reportGroups: false,
		sharedWithMe: false,
	});

	const fetchProjectsonPagination = (
		search: string,
		sortOptionsNumber: number
	) => {
		getParser().then(
			(res) => {
				const parserListRes: Array<Parser> = [];
				const { data } = res.data.data;
				data.forEach((parser: any) => {
					parserListRes.push(buildParser(parser));
				});
				setParserList(parserListRes);
				setPaginationLoader(true);

				// Fetch Projects
				fetchProjects(search, sortOptionsNumber, page, limit)
					.then((res) => {
						setPaginationLoader(false);
						if (res.data.length === 0) {
							setPage(1);

							return setHasMore(false);
						}
						setTemplates([...templates, ...res.data]);
					})
					.catch((err) => {});
			},
			(err) => {
				console.log(err);
			}
		);
	};

	//get list based upon search and sortoptions onChange
	const fetchProjectsonCondition = () => {
		getParser().then(
			(res) => {
				const parserListRes: Array<Parser> = [];
				const { data } = res.data.data;
				data.forEach((parser: any) => {
					parserListRes.push(buildParser(parser));
				});
				setParserList(parserListRes);

				// Fetch Projects
				fetchProjects(text, sortOptions, page, limit)
					.then((res) => {
						setTemplates(res.data);
					})
					.catch((err) => {});
			},
			(err) => {
				console.log(err);
			}
		);
	};

	React.useEffect(() => {
		setHasMore(true);
		setPage(1);
		fetchProjectsonCondition();
	}, [text, sortOptions]);

	React.useEffect(() => {
		if (page > 1) {
			fetchProjectsonPagination(text, sortOptions);
		}
	}, [page]);

	const deleteProject = (projectId: string) => {
		//call api to delete
		deleteProjects(projectId)
			.then((res) => {
				const filteredTemplate = templates.filter(
					(item: any) => item._id !== projectId
				);

				setTemplates(filteredTemplate);
			})
			.catch((err) => {});
	};

	const handleSelectedComponent = (type: string) => {
		if (type === 'reports') {
			setDispayComponent({
				reports: true,
				reportGroups: false,
				sharedWithMe: false,
			});
		} else if (type === 'sharedWithMe') {
			setDispayComponent({
				reports: false,
				reportGroups: false,
				sharedWithMe: true,
			});
		} else {
			setDispayComponent({
				reports: false,
				reportGroups: true,
				sharedWithMe: false,
			});
		}
	};
	return (
		<div className='app-container main__reporting'>
			<div className='custom-navbar sticky-top'>
				<Navbar />
			</div>
			<div className='main_repoting_container d-flex'>
				<TemplateBar handleSelectedComponent={handleSelectedComponent} />
				<div className='main__templates'>
					{displayComponent.reports && (
						<React.Fragment>
							<SearchFile
								search={search}
								setSearch={setSearch}
								setSortOptions={setSortOptions}
								page={page}
								setPage={setPage}
							/>
							<div className='templates-containers'>
								<div className='breadcrumb'>
									<p>My Reports Templates</p>
								</div>
								<TemplatesTable
									hasMore={hasMore}
									templates={templates}
									parserList={parserList}
									deleteProject={deleteProject}
									paginationLoader={paginationLoader}
									page={page}
									setPage={setPage}
								/>
							</div>
						</React.Fragment>
					)}

					{displayComponent.sharedWithMe && <Shares />}
				</div>
			</div>
		</div>
	);
};

export default Templates;
