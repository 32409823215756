export interface ParserApi {
  api: string;
  apiName: string;
  charts: Array<string>;
  dateRange: boolean;
  defaultValue: string | any;
  placeholder: string;
  staticValue: boolean;
}

export interface ParserServer {
  header: any;
  host: string;
  login: any;
  port: string;
}

export interface Parser {
  apis: Array<ParserApi>;
  createdAt: string;
  id: string;
  parserName: string;
  parserSlug: string;
  server: Array<any>;
  updatedAt: string;
}

export interface ChartDate {
  to: string;
  from: string;
}

export const buildParser = (parser: any): Parser => {
  const parserServers: any = [];
  parser["server"].forEach((server: ParserServer) =>
    parserServers.push(server)
  );
  return {
    apis: buildParserApi(parser["api"]),
    createdAt: parser["created_at"],
    id: parser["id"],
    parserName: parser["parser_name"],
    parserSlug: parser["parser_slug"],
    server: parserServers,
    updatedAt: parser["updated_at"],
  };
};

export const buildParserApi = (parserApi: any): Array<ParserApi> => {
  let parserApis: Array<ParserApi> = [];
  for (let key in parserApi) {
    const parserAPI: ParserApi = {
      api: parserApi[key]["api"],
      apiName: parserApi[key]["api_name"],
      charts: parserApi[key]["charts"],
      dateRange: parserApi[key]["date_range"],
      defaultValue: parserApi[key]["default_value"],
      placeholder: parserApi[key]["placeholder"],
      staticValue: parserApi[key]["static_value"],
    };
    parserApis.push(parserAPI);
  }
  return parserApis;
};

export const buildParserServer = (server: any): ParserServer => {
  return {
    header: server["header"],
    host: server["host"],
    login: server["login"],
    port: server["port"],
  };
};
