import React, { FC } from "react";
import { Modal, Button, List, Input, Select, Typography } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import { getUsers, saveShares } from "../../../core/services/share.service";

interface IShare {
  visible: boolean;
  reportId: string;
  hideModal: Function;
}
const ShareModal: FC<IShare> = ({ visible, hideModal, reportId }) => {
  const { Option } = Select;
  const { Search } = Input;
  const [users, setUsers] = React.useState<any>([]);
  const [sendDate, setSendDate] = React.useState<string>("Daily");
  const [email, setEmail] = React.useState("");

  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const user = useSelector((state: any) => state.auth.auth);

  //schedule mail
  const sendMailInfo = () => {
    const shareData = {
      from: user.email,
      text: "preview link comming soon !",
      reportId,
      userId: "", // if  id exits
      to: users.map((item: any) => {
        if (item.checked) {
          return {
            address: item.email,
          };
        }
      }),
      creatorId: user.id, //logged in user id,
      sendDate,
    };

    saveShares(shareData)
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });

    console.log(shareData);
  };
  return (
    <div>
      <Modal
        style={{ paddingTop: 50 }}
        title="Share"
        visible={visible}
        okText="Send Mail"
        onOk={() => {
          hideModal();
          sendMailInfo();
        }}
        onCancel={() => {
          hideModal();
          setUsers([]);
        }}
        confirmLoading={confirmLoading}
      >
        <div>
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <Button
            onClick={() => {
              if (email) {
                setEmail("");
                setUsers([{ checked: true, email }, ...users]);
              }
            }}
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            Add Email
          </Button>
          <br></br>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Select
              defaultValue={sendDate}
              style={{ width: 120, marginBottom: 10 }}
              onChange={(value) => {
                setSendDate(value);
              }}
            >
              <Option value="Daily">Daily</Option>
              <Option value="Weekly">Weekly</Option>
              <Option value="Monthly">Monthly</Option>
            </Select>
            <Search
              onSearch={(value: any) => {
                getUsers(value)
                  .then((res: any) => {
                    let Users: any = res.data.users.map((item: any) => {
                      return { checked: false, email: item.email, id: item.id };
                    });
                    setUsers([...users, ...Users]);
                  })
                  .catch((error: any) => {
                    console.log(error);
                  });
              }}
              enterButton="Search"
              placeholder="Search"
              style={{ marginBottom: 10, marginLeft: 10 }}
            />
          </div>

          <List
            bordered
            dataSource={users}
            renderItem={(item: any) => (
              <List.Item
                style={{ display: "flex", justifyContent: "space-between" }}
                onClick={() => {
                  const updatedUser = users.map((user: any) => {
                    if (user.id === item.id) {
                      return {
                        ...user,
                        checked: !user.checked,
                      };
                    }
                    return user;
                  });

                  setUsers(updatedUser);
                }}
              >
                {item.email}
                {item.checked && (
                  <img
                    src={`../../assets/images/menubar/check.svg`}
                    className="img-fluid h-18"
                    alt={item}
                  />
                )}
              </List.Item>
            )}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ShareModal;
