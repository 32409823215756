import { v4 } from 'uuid';
import { fabric } from 'fabric';
import { FabricCanvas } from '../core/interfaces/fabricCanvas.interface';
import { ChartType, FabricObjectType, TableType } from '../core/enums/enum';
import Chart from './CustomFabricObjects/Chart';
import Table from './CustomFabricObjects/Table';
import { TableOption } from '../core/interfaces/table.interface';

interface ChartOptions {
	canvasId: string;
	id: string;
	width: number;
	height: number;
	editable: boolean;
	angle: number;
}

interface CanvasHandlerOption {
	onCanvasInstancesUpdate: (
		canvasData: FabricCanvas,
		isActiveCanvas: boolean
	) => void;
	onCanvasInstancesUpdateFromJSON: (
		canvasInstances: Array<FabricCanvas>
	) => void;
	setCanvasPageActive: (canvasId: string) => void;
	onChartAdd: (chart: Chart) => void;
	onTableAdd: (table: any) => void;
	onObjectDelete: () => void;
	onObjectSelect: (object: fabric.Object) => void;
	onObjectModified?: (canvasDom: any, event: any) => void;
}

class CanvasHandler {
	public onCanvasInstancesUpdate?: (
		canvasData: FabricCanvas,
		isActiveCanvas: boolean
	) => void;
	public onCanvasInstancesUpdateFromJSON?: (
		canvasInstances: Array<FabricCanvas>
	) => void;
	public setCanvasPageActive?: (canvasId: string) => void;
	public onChartAdd?: (chart: Chart) => void;
	public onTableAdd?: (table: any) => void;
	public onObjectDelete?: () => void;
	public onObjectSelect?: (object: fabric.Object) => void;
	public onObjectModified?: (canvasDom: any, event: any) => void;
	public selectedObject: any;

	private width: number;
	private height: number;
	private canvasId: string;
	private headerFooterObjs: Array<any>;

	constructor(
		width: number,
		height: number,
		headerFooterObjs: Array<any>,
		handlerOptions: CanvasHandlerOption
	) {
		this.width = width;
		this.height = height;
		this.canvasId = '';
		this.headerFooterObjs = headerFooterObjs;
		this.selectedObject = null;
		this.initCallback(handlerOptions);
	}

	public setCanvasId = (canvasId: string) => {
		this.canvasId = canvasId;
	};

	public setHeaderFooterObjs = (headerFooterObjs: Array<any>) => {
		this.headerFooterObjs = headerFooterObjs;
	};

	public setFrameFromCanvasJSON = (canvasData: any) => {
		const width = this.width;
		const height = this.height;
		const headerFooterObjs = this.headerFooterObjs;
		let dynamiCanvasInstances: Array<{ id: string; canvasDom: any }> = [];
		if (!canvasData || canvasData === {} || canvasData.length === 0) {
			const mainCanvas = this.appendNewCanvasInFrame(`${v4()}`);
			const fabricCanvas = new fabric.Canvas(mainCanvas, {
				width,
				height,
				backgroundColor: '#fff',
				preserveObjectStacking: true,
			});

			fabricCanvas.renderAll();

			if (this.onCanvasInstancesUpdate) {
				this.onCanvasInstancesUpdate(
					{
						id: mainCanvas.id,
						canvasDom: fabricCanvas,
					},
					true
				);
			}
		} else {
			//canvasData
			canvasData.forEach((canvasJson: any, index: number) => {
				const canvasId = canvasJson._id;
				const mainCanvas = this.appendNewCanvasInFrame(canvasId);
				const fabricCanvas = new fabric.Canvas(mainCanvas, {
					width,
					height,
					backgroundColor: '#fff',
					preserveObjectStacking: true,
				});
				//load from json

				let chartObjects: any[] = [];
				let tableObjects: any[] = [];
				let otherObjects: any[] = [];

				canvasJson &&
					canvasJson.canvasData &&
					canvasJson.canvasData.objects &&
					canvasJson.canvasData.objects.forEach(
						(object: any, index: number) => {
							if (object.type === 'chart') {
								chartObjects.push(object);
							} else if (object.type == 'table') {
								tableObjects.push(object);
							} else {
								otherObjects.push(object);
							}
						}
					);
				otherObjects.forEach((object) => {
					const fabricObject = this.handleObjectAddFromJSON(object);
					if (object && object.type === 'image') {
						this.addImage(object, fabricCanvas);
					}
					fabricObject && fabricCanvas.add(fabricObject);
				});
				chartObjects.forEach((object: any) => {
					const chartOption = object.chartOption;
					let otherOptions = { ...object, canvasId };
					delete otherOptions.chartOption;
					const chart = this.createChart(chartOption, otherOptions);
					fabricCanvas.add(chart);
				});
				tableObjects.forEach((object: any) => {
					const tableOption = object.tableOption;
					let otherOptions = { ...object, canvasId };
					delete otherOptions.tableOption;
					const table = this.createTable(tableOption, otherOptions);
					fabricCanvas.add(table);
				});
				this.updateHeaderFooter(
					headerFooterObjs,
					fabricCanvas,
					canvasJson.pageNumber
				);
				const newObj = {
					id: canvasId,
					pageNumber: canvasJson.pageNumber,
					canvasDom: fabricCanvas,
				};
				dynamiCanvasInstances.push(newObj);
				fabricCanvas.renderAll();
			});

			if (this.onCanvasInstancesUpdateFromJSON) {
				this.onCanvasInstancesUpdateFromJSON(dynamiCanvasInstances);
			}
		}
		this.assignActivePageOnScroll();
	};

	public addNewCanvasFrame(id?: string, pageNumber?: number) {
		if (!id) {
			id = `${v4()}`;
		}
		const width = this.width;
		const height = this.height;
		const headerFooterObjs = this.headerFooterObjs;
		const isActiveCanvas = false;
		const mainCanvas = this.appendNewCanvasInFrame(id);
		const fabricCanvas = new fabric.Canvas(mainCanvas, {
			width,
			height,
			backgroundColor: '#fff',
			preserveObjectStacking: true,
		});

		this.updateHeaderFooter(headerFooterObjs, fabricCanvas, pageNumber);
		if (this.onCanvasInstancesUpdate) {
			this.onCanvasInstancesUpdate(
				{
					id: mainCanvas.id,
					canvasDom: fabricCanvas,
				},
				isActiveCanvas
			);
		}
		this.assignActivePageOnScroll();
	}

	/**
	 * Add chart in canvas
	 * @param chartType Chart type
	 */
	public addChart(chartType: ChartType, canvasId: string) {
		this.canvasId = canvasId;
		let options = {};
		switch (chartType) {
			case ChartType.PIE_CHART:
				options = {
					series: [
						{
							type: 'pie',
							data: [
								[0, 1],
								[1, 2],
								[2, 3],
								[3, 10],
							],
						},
						,
					],
				};
				break;
			case ChartType.BAR_CHART:
				options = {
					legend: {
						data: ['Value1', 'Value2'],
					},
					xAxis: {
						type: 'category',
						data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
					},
					yAxis: {
						type: 'value',
					},
					series: [
						{
							name: 'Value1',
							data: [320, 302, 301, 334, 390, 330, 320],
							stack: 'total',
							type: 'bar',
						},
						{
							name: 'Value2',
							data: [120, 132, 101, 134, 90, 230, 210],
							stack: 'total',
							type: 'bar',
						},
					],
				};
				break;
			case ChartType.LINE_CHART:
				options = {
					legend: {
						data: [],
					},
					xAxis: {
						type: 'category',
						data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
					},
					yAxis: {
						type: 'value',
					},
					series: [
						{
							name: 'Values',
							data: [820, 932, 901, 934, 1290, 1330, 1320],
							type: 'line',
							symbol: 'none',
							smooth: true,
							lineStyle: {
								width: 1,
							},
							itemStyle: {
								borderWidth: 0,
							},
						},
					],
				};
				break;
			default:
				break;
		}
		const chart = this.createChart(options, null);
		if (this.onChartAdd) {
			this.onChartAdd(chart);
		}
	}

	/**
	 * Add chart in canvas
	 * @param chartType Chart type
	 */
	public addTable(
		canvasId: string,
		contents: Array<Array<{ text: string; type?: string }>>
	) {
		this.canvasId = canvasId;
		let option: TableOption = {
			type: TableType.TABLE_OF_CONTENTS,
			hasTableHeaders: true,
			contents: [[{ text: 'Table Of Contents' }, { text: 'Page No.' }]],
		};
		if (contents.length > 0) {
			option.contents = option.contents.concat(contents);
		}
		const table = this.createTable(option, null);
		if (this.onTableAdd) {
			this.onTableAdd(table);
		}
	}

	public handleEvents = (canvasDom: any) => {
		canvasDom && this.clearPreviousEventsListeners(canvasDom);
		canvasDom &&
			canvasDom.on({
				'object:moving': (event: any) => this.moving(canvasDom, event),
				'object:scaling': (event: any) => this.scaling(canvasDom, event),
				'object:rotating': (event: any) => this.rotating(canvasDom, event),
				'object:added': (event: any) => this.objectModified(canvasDom, event),
				'object:removed': (event: any) => this.objectModified(canvasDom, event),
				'object:modified': (event: any) =>
					this.objectModified(canvasDom, event),
				// "after:render": (event: any) => this.objectModified(canvasDom, event),
				'selection:cleared': this.selection,
				'selection:created': this.selection,
				'selection:updated': this.selection,
			});
	};

	private addImage = (object: any, fabricCanvas: fabric.Canvas) => {
		fabric.Image.fromURL(
			object.src,
			(img: any) => {
				if (img) {
					object.filters.map((item: any) => {
						if (item.type === 'Grayscale') {
							img.filters.push(new fabric.Image.filters.Grayscale());
							img.applyFilters();
						} else if (item.type === 'Invert') {
							img.filters.push(new fabric.Image.filters.Invert());
							img.applyFilters();
						} else if (item.type === 'Sepia') {
							img.filters.push(new fabric.Image.filters.Sepia());
							img.applyFilters();
						} else if (item.type === 'BlendColor') {
							img &&
								img.filters.push(
									new fabric.Image.filters.BlendColor({
										mode: 'add',
										color: 'red',
									})
								);
							img.applyFilters();
						} else if (item.type === 'Brightness') {
							img &&
								img.filters.push(
									new fabric.Image.filters.Brightness({
										brightness: item.brightness,
									})
								);

							img.applyFilters();
						} else {
							img &&
								img.filters.push(
									new fabric.Image.filters.Contrast({
										contrast: item.contrast,
									})
								);

							img.applyFilters();
						}
					});

					img.scale(0.3).set({
						...object,
						filters: img.filters,
					});

					img && fabricCanvas.add(img);
				}
			},
			{ crossOrigin: 'anonymous' }
		);
	};

	private updateHeaderFooter = (
		headerFooterObjs: Array<any>,
		fabricCanvas: fabric.Canvas,
		pageNumber?: number
	) => {
		headerFooterObjs &&
			headerFooterObjs.forEach((object: any) => {
				let otherOption = { ...object };
				delete otherOption.superType;
				delete otherOption._id;
				let fabricObject = null;
				let isImage = false;
				switch (object.type) {
					case FabricObjectType.ITEXT:
						if (object._id === 'footer-page' && pageNumber) {
							otherOption.text = `page ${pageNumber}`;
						}
						fabricObject = new fabric.IText('', {
							...otherOption,
						});
						fabricObject.toObject = function () {
							return {
								superType: object.superType,
								_id: object._id,
								...otherOption,
							};
						};
						isImage = false;
						break;
					case FabricObjectType.LINE:
						const xYCoordinates = {
							x1: otherOption.x1,
							y1: otherOption.y1,
							x2: otherOption.x2,
							y2: otherOption.y2,
						};
						delete otherOption.x1;
						delete otherOption.y1;
						delete otherOption.x2;
						delete otherOption.y2;
						fabricObject = new fabric.Line(
							[
								xYCoordinates.x1,
								xYCoordinates.y1,
								xYCoordinates.x2,
								xYCoordinates.y2,
							],
							otherOption
						);
						fabricObject.toObject = function () {
							return {
								superType: object.superType,
								_id: object._id,
								...xYCoordinates,
								...otherOption,
							};
						};
						isImage = false;
						break;
					case FabricObjectType.IMAGE:
						this.addImage(object, fabricCanvas);
						isImage = true;
						break;
				}
				!isImage && fabricObject && fabricCanvas.add(fabricObject);
			});
	};

	private handleObjectAddFromJSON = (object: any) => {
		let fabricObject = null;
		const fabricObjectType = object.type as FabricObjectType;
		switch (fabricObjectType) {
			case FabricObjectType.ITEXT:
				let textOptions = { ...object };
				const objectTitle = textOptions.title;
				delete textOptions.title;
				fabricObject = new fabric.IText(objectTitle, textOptions);
				break;
			case FabricObjectType.LINE:
				let lineOptions = { ...object };
				const lineCoords = [
					lineOptions.x1,
					lineOptions.y1,
					lineOptions.x2,
					lineOptions.y2,
				];
				delete lineOptions.x1;
				delete lineOptions.y1;
				delete lineOptions.x2;
				delete lineOptions.y2;
				fabricObject = new fabric.Line(lineCoords, lineOptions);
				break;
			case FabricObjectType.TRIANGLE:
				fabricObject = new fabric.Triangle({ ...object });
				break;
			case FabricObjectType.RECT:
				fabricObject = new fabric.Rect({ ...object });
				break;
			case FabricObjectType.CIRCLE:
				fabricObject = new fabric.Circle({ ...object });
				break;
		}
		return fabricObject;
	};

	public handleObjectAdd = (objectType: FabricObjectType, canvasDom: any) => {
		let fabricObject: any = null;
		switch (objectType) {
			case FabricObjectType.TEXT:
				fabricObject = new fabric.IText('Hello World', {
					left: 50,
					top: 150,
					fontSize: 16,
					fontWeight: 'normal',
					fontStyle: 'normal',
					fontFamily: 'Times New Roman',
					fill: 'black',
					underline: false,
				});

				fabricObject['headingType'] = 'normal';
				break;
			case FabricObjectType.LINE:
				fabricObject = new fabric.Line([50, 100, 200, 200], {
					left: 170,
					top: 150,
					stroke: 'black',
				});
				break;
			case FabricObjectType.TRIANGLE:
				fabricObject = new fabric.Triangle({
					top: 300,
					left: 210,
					width: 100,
					height: 100,
					fill: 'rgba(255,255,255,0)',
					strokeWidth: 1,
					stroke: 'black',
				});
				break;
			case FabricObjectType.RECTANGLE:
				fabricObject = new fabric.Rect({
					top: 100,
					left: 0,
					width: 80,
					height: 50,
					fill: 'rgba(255,255,255,0)',
					strokeWidth: 1,
					stroke: 'black',
				});
				break;
			case FabricObjectType.CIRCLE:
				fabricObject = new fabric.Circle({
					top: 140,
					left: 230,
					radius: 75,
					fill: 'rgba(255,255,255,0)',
					strokeWidth: 1,
					stroke: 'black',
				});
				break;
			case FabricObjectType.SQUARE:
				fabricObject = new fabric.Rect({
					top: 140,
					left: 230,
					width: 80,
					height: 80,
					fill: 'rgba(255,255,255,0)',
					strokeWidth: 1,
					stroke: 'black',
				});
				break;
			case FabricObjectType.IMAGE:
				fabric.Image.fromURL(
					`https://salgum1114.github.io/react-design-editor/images/sample/transparentBg.png`,
					(img: any) => {
						img.scale(0.3).set({
							left: 100,
							top: 100,
							angle: 0,
							crossorigin: 'anonymous',
						});
						canvasDom.add(img);
						canvasDom.renderAll();
					}
				);
				break;
		}
		this.handleEvents(canvasDom);
		if (fabricObject) {
			canvasDom.add(fabricObject);
			canvasDom.renderAll();
		}
	};

	///////////////////////////////////
	///////// Delete Object //////////
	//////////////////////////////////
	public handleObjectDelete = () => {
		if (this.selectedObject) {
			const canvasDom = this.selectedObject.canvas;
			if (this.selectedObject.superType === 'element') {
				if (
					this.selectedObject.type === 'chart' ||
					this.selectedObject.type === 'table'
				) {
					this.selectedObject.element.remove();
				}
			}
			if (canvasDom.getActiveObject()) {
				canvasDom.remove(canvasDom.getActiveObject());
			}
			this.selectedObject = null;
			if (this.onObjectDelete) {
				this.onObjectDelete();
			}
		}
	};

	private clearPreviousEventsListeners(canvasDom: any) {
		canvasDom.off({
			'object:moving': null,
			'object:scaling': null,
			'object:rotating': null,
			'object:added': null,
			'object:removed': null,
			'object:modified': null,
			'selection:cleared': null,
			'selection:created': null,
			'selection:updated': null,
		});
	}

	private initCallback(options: CanvasHandlerOption) {
		this.onCanvasInstancesUpdate = options.onCanvasInstancesUpdate;
		this.onCanvasInstancesUpdateFromJSON =
			options.onCanvasInstancesUpdateFromJSON;
		this.setCanvasPageActive = options.setCanvasPageActive;
		this.onChartAdd = options.onChartAdd;
		this.onTableAdd = options.onTableAdd;
		this.onObjectDelete = options.onObjectDelete;
		this.onObjectSelect = options.onObjectSelect;
		this.onObjectModified = options.onObjectModified;
	}

	private assignActivePageOnScroll = () => {
		const canvasContainer = document.getElementById('canvas__container');
		if (canvasContainer) {
			canvasContainer.onscroll = (event: any) => {
				const canvasFrames: Element[] = Array.from(
					canvasContainer.getElementsByClassName('canvas__frame')
				);
				canvasFrames.forEach((frame: Element) => {
					if (this.isElementVisible(frame)) {
						frame.childNodes.forEach((frameCanvasContainerDiv) => {
							if (frameCanvasContainerDiv.childNodes[0].nodeName === 'CANVAS') {
								const lowerCanvas = frameCanvasContainerDiv
									.childNodes[0] as HTMLElement;
								if (this.canvasId !== lowerCanvas.id) {
									this.canvasId = lowerCanvas.id;
									this.setCanvasPageActive &&
										this.setCanvasPageActive(lowerCanvas.id);
								}
							}
						});
					}
				});
			};
		}
	};

	private isElementVisible(element: Element) {
		const headerHeight = 48;
		const textHeaderHeight = 36;
		const iconHeaderHeight = 10;
		const canvasHeightOffset =
			headerHeight + textHeaderHeight + iconHeaderHeight;
		const htmlOverAllHeight =
			document.documentElement.clientHeight - canvasHeightOffset;
		const canvasContainerViewPortHeight =
			window.innerHeight - canvasHeightOffset;
		const viewHeight = Math.max(
			htmlOverAllHeight,
			canvasContainerViewPortHeight
		);
		const canvasFrameRect = element.getBoundingClientRect();
		const pageChangeBreakingPoint = viewHeight / 2;
		return !!(
			canvasFrameRect.bottom >= 0 &&
			canvasFrameRect.top < pageChangeBreakingPoint &&
			canvasFrameRect.bottom > pageChangeBreakingPoint
		);
	}

	// private isElementVisible(element: any) {
	//   var rect = element.getBoundingClientRect();
	//   var viewHeight = Math.max(
	//     document.documentElement.clientHeight,
	//     window.innerHeight
	//   );
	//   return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
	// }

	private appendNewCanvasInFrame = (id: string) => {
		// const id = `canvas_${v4()}`;
		const canvasContainer = document.getElementById('canvas__container');
		const canvasFrame = document.createElement('div');
		canvasFrame.style.position = 'relative';
		canvasFrame.id = `frame_${id}`;
		canvasFrame.className = 'canvas__frame';
		canvasContainer?.appendChild(canvasFrame);
		const mainCanvas = document.createElement('canvas');
		mainCanvas.id = id;
		canvasFrame.appendChild(mainCanvas);
		return mainCanvas;
	};

	///////////////////////////////////
	///////// Canvas Handlers /////////
	//////////////////////////////////

	public deleteOnKeyPress = (keyCode: number) => {};
	public selection = (opt: any) => {
		const target = opt.target;
		if (target) {
			this.selectedObject = target;
		} else {
			this.selectedObject = null;
		}
		if (this.onObjectSelect) {
			this.onObjectSelect(target);
		}
	};

	public objectModified = (canvasDom: any, event: any) => {
		if (this.onObjectModified) {
			this.onObjectModified(canvasDom, event);
		}
	};

	private moving = (canvas: any, event: any) => {
		const el = document.getElementById(`${event.target.id}_container`);
		const obj = event.target;
		this.setPosition(canvas, el, obj);
	};

	private scaling = (canvas: any, event: any) => {
		const { target } = event;
		if (target.superType === 'element') {
			const { id, width, height } = target;
			const el = document.getElementById(`${event.target.id}_container`);
			// update the element
			this.setScaleOrAngle(canvas, el, target);
			this.setSize(el, target);
			this.setPosition(canvas, el, target);
		}
	};

	private rotating = (canvas: any, event: any) => {
		const { target } = event;
		if (target.superType === 'element') {
			const { id } = target;
			const el = document.getElementById(`${event.target.id}_container`);
			// update the element
			this.setScaleOrAngle(canvas, el, target);
		}
	};

	private setScaleOrAngle = (canvas: any, el: any, obj: any) => {
		if (!el) {
			return;
		}
		const zoom = canvas.getZoom();
		const { scaleX, scaleY, angle } = obj;
		el.style.transform = `rotate(${angle}deg) scale(${scaleX * zoom}, ${
			scaleY * zoom
		})`;
	};

	private setSize = (el: any, obj: any) => {
		if (!el) {
			return;
		}
		const { width, height } = obj;
		el.style.width = `${width}px`;
		el.style.height = `${height}px`;
	};

	private setPosition = (canvas: any, el: any, obj: any) => {
		if (obj) {
			let objHeight = obj.getScaledHeight(),
				objWidth = obj.getScaledWidth(),
				top = obj.top,
				left = obj.left,
				rightBound = this.width,
				bottomBound = this.height,
				modified = false;
			// don't move off top
			if (top < 0) {
				top = 0;
				modified = true;
			}
			// don't move off bottom
			if (top + objHeight > bottomBound) {
				top = bottomBound - objHeight;
				modified = true;
			}
			// don't move off left
			if (left < 0) {
				left = 0;
				modified = true;
			}
			// don't move off right
			if (left + objWidth > rightBound) {
				left = rightBound - objWidth;
				modified = true;
			}

			if (modified) {
				obj.set('left', left);
				obj.set('top', top);
			}
		}
		if (!el) {
			return;
		}
		obj.setCoords();
		const zoom = canvas.getZoom();
		const { scaleX, scaleY, width, height } = obj;
		const elContainer = document.getElementById(`frame_${this.canvasId}`);
		if (
			elContainer &&
			obj.getBoundingRect().left >= 0 &&
			obj.getBoundingRect().top >= 0 &&
			obj.getBoundingRect().left + el.offsetWidth <= elContainer.offsetWidth &&
			obj.getBoundingRect().top + el.offsetHeight <= elContainer.offsetHeight
		) {
			const left = obj.getBoundingRect().left + 10;
			const top = obj.getBoundingRect().top + 50;
			const padLeft = (width * scaleX * zoom - width) / 2;
			const padTop = (height * scaleY * zoom - height) / 2;
			el.style.left = `${left + padLeft}px`;
			el.style.top = `${top + padTop}px`;
		}
	};

	private createChart = (chartData: any, otherOptions: ChartOptions | null) => {
		var otherOption = {};
		if (!otherOptions) {
			otherOption = {
				canvasId: this.canvasId,
				id: v4(),
				width: 500,
				height: 500,
				editable: true,
				angle: 0,
			};
		} else {
			otherOption = otherOptions;
		}
		return new Chart(chartData, otherOption);
	};

	private createTable = (tableData: TableOption, otherOptions: any | null) => {
		let otherOption = {};
		const DEFAULT_CELL_HEIGHT = 60;
		if (!otherOptions) {
			otherOption = {
				canvasId: this.canvasId,
				id: v4(),
				width: 750,
				height: tableData.contents.length * DEFAULT_CELL_HEIGHT,
				editable: true,
				angle: 0,
			};
		} else {
			otherOption = otherOptions;
		}
		return new Table(tableData, otherOption);
	};
}

export default CanvasHandler;
