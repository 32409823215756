export enum FabricObjectType {
  TEXT = "text",
  ITEXT = "i-text",
  LINE = "line",
  CIRCLE = "circle",
  RECTANGLE = "rectangle",
  RECT = "rect",
  TRIANGLE = "triangle",
  SQUARE = "square",
  IMAGE = "image",
}

export enum ChartType {
  LINE_CHART = "lineChart",
  BAR_CHART = "barChart",
  PIE_CHART = "pieChart",
  DOUGHNUT_CHART = "doughnutChart",
}

export enum ChartStackType {
  // For Bar Chart
  STACKED = "stacked",
  NON_STACKED = "non-stacked",
  // For Line Chart
  FILL = "fill",
  NON_FILL = "non-fill",
  // For Pie chart
  PIE = "pie",
  DOUGHNUT = "doughnut",
}

export enum ChartUpdate {
  SEVEN_DAYS = "seven_days",
  THIRTY_DAYS = "thirty_days",
  NONE = "none",
}

export enum TableType {
  SIMPLE = "SIMPLE",
  TABLE_OF_CONTENTS = "TABLE OF CONTENTS",
}
