import React from 'react';
import { Layout, Menu } from 'antd';
import {
	FolderOutlined,
	ContainerOutlined,
	ShareAltOutlined,
	FileAddOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { fabric } from 'fabric';
import { createNewProject } from '../../../../core/services/project.service';
import { useSelector } from 'react-redux';

const { Sider } = Layout;
interface ITemplateBar {
	handleSelectedComponent: Function;
}
const TemplateBar: React.FC<ITemplateBar> = ({ handleSelectedComponent }) => {
	let history = useHistory();
	const { id } = useSelector((state: any) => state.auth.auth);

	const handleCreateTemplateClick = () => {
		const canvas = new fabric.Canvas('canvas', {
			width: 800,
			height: 1148,
			backgroundColor: '#fff',
			preserveObjectStacking: true,
		});

		createNewProject({
			width: 800,
			height: 1148,
			userId: id,
			canvasData: canvas.toJSON(),
			pageNumber: 1,
		}).then(
			(res) => {
				history.push(`/canvas/${res.data.reportInfo._id}`);
			},
			(err) => {
				console.log(err);
			}
		);
		// history.push("/canvas/new");
	};

	return (
		<div className='template__bar'>
			<Sider>
				<Menu mode='inline' defaultSelectedKeys={['2']}>
					<Menu.Item key='1' icon={<FolderOutlined />}>
						<p
							onClick={() => {
								handleSelectedComponent('reportGroup');
							}}>
							Report Groups
						</p>
					</Menu.Item>
					<Menu.Item key='2' icon={<ContainerOutlined />}>
						<p
							onClick={() => {
								handleSelectedComponent('reports');
							}}>
							My Report Templates
						</p>
					</Menu.Item>
					<Menu.Item key='3' icon={<ShareAltOutlined />}>
						<p
							onClick={() => {
								handleSelectedComponent('sharedWithMe');
							}}>
							Shared with Me
						</p>
					</Menu.Item>
				</Menu>
				<div className='create__template' onClick={handleCreateTemplateClick}>
					<span>
						<FileAddOutlined />
					</span>
					<p>Create Template</p>
				</div>
			</Sider>
		</div>
	);
};

export default TemplateBar;
