import React, { FC } from "react";
import { Input, Select } from "antd";

const { Search } = Input;
const { Option } = Select;
interface ISearch {
  search: string;
  page: number;
  setPage: Function;
  setSearch: Function;
  setSortOptions: Function;
}
const SearchFile: React.FC<ISearch> = ({
  search,
  setSearch,
  page,
  setPage,
  setSortOptions,
}) => {
  const options = ["Name (A-Z)", "Name (Z-A)", "Date Created", "Date Modified"];

  const [selectedValue, setSelectedValue] = React.useState(options[0]);

  return (
    <div className="searching__files__wraps d-flex">
      <div className="search_list">
        <Search
          placeholder="Search Template"
          value={search}
          onChange={(e: any) => {
            // setPage(1);
            setSearch(e.target.value);
          }}
          allowClear
        />
      </div>
      <div className="filter__list ml-auto d-flex align-center">
        <p className="label_name">Sort By</p>
        <Select
          value={selectedValue}
          onChange={(value: string) => {
            // setPage(1);
            setSelectedValue(value);
            if (value === "Name (A-Z)") {
              setSortOptions(0);
            } else if (value === "Name (Z-A)") {
              setSortOptions(1);
            } else if (value === "Date Created") {
              setSortOptions(2);
            } else if (value === "Date Modified") {
              setSortOptions(3);
            }
          }}
        >
          {options.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default SearchFile;
