import React from "react";
import { Form, Select, Checkbox, Col, Row } from "antd";
import {
  faBold,
  faItalic,
  faUnderline,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface PropTypes {
  canvas: any;
  canvasId: any;
  selectedObject: any;
  textInfo: any;
  textSelectedStyle: Array<any>;
  setTextInfo: Function;
  setTextSelectedStyle: Function;
}
const tailLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const { Option } = Select;

const TextSetting = (props: PropTypes) => {
  const addBold = (): void => {
    props.canvas.map((item: any) => {
      if (item.id === props.canvasId) {
        const text = item.canvasDom.getActiveObject();
        if (text && text.type === "i-text" && text.fontWeight === "normal") {
          props.setTextInfo({
            ...props.textInfo,
            textfontWeight: "bold",
          });
          text.set({
            // dirty: true,
            fontWeight: "bold",
          });
        }
        item.canvasDom.renderAll();
      }
    });
  };

  const removeBold = (): void => {
    props.canvas.map((item: any) => {
      if (item.id === props.canvasId) {
        const text = item.canvasDom.getActiveObject();
        if (text.type === "i-text") {
          props.setTextInfo({
            ...props.textInfo,
            textfontWeight: "normal",
          });

          text.set({
            // dirty: true,
            fontWeight: "normal",
          });
        }
        item.canvasDom.renderAll();
      }
    });
  };
  const addItalic = (): void => {
    props.canvas.map((item: any) => {
      if (item.id === props.canvasId) {
        const text = item.canvasDom.getActiveObject();
        if (text.type === "i-text" && text.fontStyle === "normal") {
          text.set({
            dirty: true,
            fontStyle: "italic",
          });
          props.setTextInfo({
            ...props.textInfo,
            textfontStyle: "italic",
          });
        }
        item.canvasDom.renderAll();
      }
    });
  };

  const removeItalic = (): void => {
    props.canvas.map((item: any) => {
      if (item.id === props.canvasId) {
        const text = item.canvasDom.getActiveObject();
        if (text.type === "i-text") {
          text.set({
            dirty: true,
            fontStyle: "normal",
          });
          props.setTextInfo({
            ...props.textInfo,
            textfontStyle: "normal",
          });
        }
        item.canvasDom.renderAll();
      }
    });
  };

  const addFontFamily = (value: string): void => {
    props.canvas.map((item: any) => {
      if (item.id === props.canvasId) {
        const text = item.canvasDom.getActiveObject();

        if (text.type === "i-text" && value === "Verdana") {
          text.set({
            dirty: true,
            fontFamily: "Verdana",
          });

          props.setTextInfo({
            ...props.textInfo,
            textfontFamily: "Verdana",
          });
        } else if (text.type === "i-text" && value === "Helvetica") {
          text.set({
            dirty: true,
            fontFamily: "Helvetica",
          });
          props.setTextInfo({
            ...props.textInfo,
            textfontFamily: "Helvetica",
          });
        } else if (text.type === "i-text" && value === "Arial") {
          text.set({
            dirty: true,
            fontFamily: "Arial",
          });
          props.setTextInfo({
            ...props.textInfo,
            textfontFamily: "Arial",
          });
        } else {
          text.set({
            dirty: true,
            fontFamily: "Times New Roman",
          });
          props.setTextInfo({
            ...props.textInfo,
            textfontFamily: "Times New Roman",
          });
        }

        item.canvasDom.renderAll();
      }
    });
  };

  const changeFontSize = (value: number): void => {
    props.canvas.map((item: any) => {
      if (item.id === props.canvasId) {
        const text = item.canvasDom.getActiveObject();

        if (text.type === "i-text") {
          text.set({
            dirty: true,
            fontSize: value,
          });

          props.setTextInfo({
            ...props.textInfo,
            textfontSize: +value,
          });
        }

        item.canvasDom.renderAll();
      }
    });
  };

  const changeFontType = (value: string): void => {
    props.canvas.map((item: any) => {
      if (item.id === props.canvasId) {
        const text = item.canvasDom.getActiveObject();

        if (text.type === "i-text") {
          text.set({
            dirty: true,
            fontWeight: "bold",
          });

          props.setTextInfo({
            ...props.textInfo,

            textfontWeight: "bold",
          });
          if (value === "heading1") {
            text.set({
              dirty: true,
              fontSize: 30,
              headingType: "heading1",
            });
            props.setTextInfo({
              ...props.textInfo,
              textFontType: "heading1",
              textfontSize: 30,
            });
          } else if (value === "heading2") {
            text.set({
              dirty: true,
              fontSize: 24,
              headingType: "heading2",
            });
            props.setTextInfo({
              ...props.textInfo,
              textFontType: "heading2",
              textfontSize: 24,
            });
          } else if (value === "heading3") {
            text.set({
              dirty: true,
              fontSize: 18,
              headingType: "heading3",
            });
            props.setTextInfo({
              ...props.textInfo,
              textFontType: "heading3",
              textfontSize: 18,
            });
          } else {
            text.set({
              dirty: true,
              fontSize: 16,
              fontWeight: "normal",
              headingType: "normal",
            });
            props.setTextInfo({
              ...props.textInfo,
              textFontType: "normal",
              textfontSize: 16,
              textfontWeight: "normal",
            });
          }
        }

        item.canvasDom.renderAll();
      }
    });
  };

  const addUderline = (): void => {
    props.canvas.map((item: any) => {
      if (item.id === props.canvasId) {
        const text = item.canvasDom.getActiveObject();
        if (text.type === "i-text" || text.type === "textbox") {
          text.set({
            dirty: true,
            underline: true,
          });
          props.setTextInfo({
            ...props.textInfo,
            textUnderline: true,
          });
        }
        item.canvasDom.renderAll();
      }
    });
  };
  const removeUderline = (): void => {
    props.canvas.map((item: any) => {
      if (item.id === props.canvasId) {
        const text = item.canvasDom.getActiveObject();
        if (text.type === "i-text" || text.type === "textbox") {
          text.set({
            dirty: true,
            underline: false,
          });
          props.setTextInfo({
            ...props.textInfo,
            textUnderline: false,
          });
        }
        item.canvasDom.renderAll();
      }
    });
  };

  const alignTextItems = (align: string): void => {
    props.canvas.map((item: any) => {
      if (item.id === props.canvasId) {
        const text = item.canvasDom.getActiveObject();
        if (align === "left") {
          text.set({
            dirty: true,
            textAlign: "left",
          });
          props.setTextInfo({
            ...props.textInfo,
            alignText: "left",
          });
        } else if (align === "right") {
          text.set({
            dirty: true,
            textAlign: "right",
          });
          props.setTextInfo({
            ...props.textInfo,
            alignText: "right",
          });
        } else {
          text.set({
            dirty: true,
            textAlign: "center",
          });
          props.setTextInfo({
            ...props.textInfo,
            alignText: "center",
          });
        }
        item.canvasDom.renderAll();
      }
    });
  };

  return (
    <div className="text_setting__wrapper">
      <Form name="basic" {...tailLayout}>
        <Row>
          <Col span={16}>
            <Form.Item label="Font Family">
              <Select
                value={props.textInfo.textfontFamily}
                onChange={(value: any) => addFontFamily(value)}
              >
                <Option value="Times New Roman">Times New Roman</Option>
                <Option value="Verdana">Verdana</Option>
                <Option value="Helvetica">Helvetica</Option>
                <Option value="Arial">Arial</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Font Size">
              <Select
                value={+props.textInfo.textfontSize}
                onChange={(value) => changeFontSize(value)}
              >
                <Option value="12">12</Option>
                <Option value="15">15</Option>
                <Option value="16">16</Option>
                <Option value="24">24</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="Font Type">
              <Select
                value={props.textInfo.textFontType}
                onChange={(value) => changeFontType(value)}
              >
                <Option value="normal">Normal Text</Option>
                <Option value="heading1">Heading 1</Option>
                <Option value="heading2">Heading 2</Option>
                <Option value="heading3">Heading 3</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Checkbox.Group
          value={props.textSelectedStyle.map((item) => item)}
          style={{ width: "100%" }}
          onChange={(value: any) => {
            props.setTextSelectedStyle(value);
            if (value.includes("bold")) {
              addBold();
            } else {
              removeBold();
            }

            if (value.includes("italic")) {
              addItalic();
            } else {
              removeItalic();
            }

            if (value.includes("underline")) {
              addUderline();
            } else {
              removeUderline();
            }

            if (value.includes("left")) {
              alignTextItems("left");
            }
            if (value.includes("right")) {
              alignTextItems("right");
            }
            if (value.includes("center")) {
              alignTextItems("center");
            }
          }}
        >
          <Row>
            <Col span={6}>
              <Checkbox
                // className={`${
                //   props.textInfo.textfontWeight === "bold" &&
                //   "ant-checkbox-wrapper-checked ant-checkbox-checked"
                // }`}
                value="bold"
              >
                <div className="text_style_wrap">
                  <FontAwesomeIcon icon={faBold} />
                </div>
              </Checkbox>
            </Col>
            <Col span={6}>
              <Checkbox
                value="italic"
                // className={`${
                //   props.textInfo.textfontStyle === "italic" &&
                //   "ant-checkbox-wrapper-checked ant-checkbox-checked"
                // }`}
              >
                <div className="text_style_wrap">
                  <FontAwesomeIcon icon={faItalic} />
                </div>
              </Checkbox>
            </Col>

            <Col span={6}>
              <Checkbox
                value="underline"
                // className={`${
                //   props.textInfo.textUnderline &&
                //   "ant-checkbox-wrapper-checked ant-checkbox-checked"
                // }`}
              >
                <div className="text_style_wrap">
                  <FontAwesomeIcon icon={faUnderline} />
                </div>
              </Checkbox>
            </Col>
            <Col span={6}></Col>
            <Col span={6}>
              <Checkbox
                value="left"
                // className={`${
                //   props.textInfo.alignText === "left" &&
                //   "ant-checkbox-wrapper-checked ant-checkbox-checked"
                // }`}
              >
                <div className="text_style_wrap">
                  <FontAwesomeIcon icon={faAlignLeft} />
                </div>
              </Checkbox>
            </Col>
            <Col span={6}>
              <Checkbox
                value="center"
                // className={`${
                //   props.textInfo.alignText === "center" &&
                //   "ant-checkbox-wrapper-checked ant-checkbox-checked"
                // }`}
              >
                <div className="text_style_wrap">
                  <FontAwesomeIcon icon={faAlignCenter} />
                </div>
              </Checkbox>
            </Col>
            <Col span={6}>
              <Checkbox
                value="right"
                // className={`${
                //   props.textInfo.alignText === "right" &&
                //   "ant-checkbox-wrapper-checked ant-checkbox-checked"
                // }`}
              >
                <div className="text_style_wrap">
                  <FontAwesomeIcon icon={faAlignRight} />
                </div>
              </Checkbox>
            </Col>
            <Col span={6}></Col>
          </Row>
        </Checkbox.Group>
      </Form>
    </div>
  );
};

export default TextSetting;
