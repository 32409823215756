import React from "react";
import axios from "axios";
import { fabric } from "fabric";
import { Row, Upload, Col, Button, Typography, Input } from "antd";
import { UploadOutlined } from "@ant-design/icons";
interface ImageTypes {
  canvas: any;
  canvasId: any;
  type: string;
  selectedObject: any;
}
const ImageSettings = (props: ImageTypes) => {
  const [show, setShow] = React.useState({
    showFileupload: true,
    showUrl: false,
  });

  const [imageUrl, setImageUrl] = React.useState("");
  const [responseImageUrl, setResponseImageUrl] = React.useState("");

  function getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const imageProps = {
    name: "imageFile",
    action: `${process.env.REACT_APP_API_URL}/upload`,

    onChange(info: any) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        getBase64(info.file.originFileObj, (imageUrl: any) => {
          setResponseImageUrl(imageUrl);
          props.canvas.map((item: any) => {
            if (item.canvasDom.getActiveObject()) {
              item.canvasDom
                .getActiveObject()
                .set({
                  width: 500,
                  height: 500,
                  crossorigin: "anonymous",
                })
                .setSrc(
                  `${process.env.REACT_APP_BASE_URL}/${info.file.response.file}`,
                  (img: any) => {
                    item.canvasDom.getActiveObject().toObject();
                    item.canvasDom.renderAll();
                  }
                );
            }
          });
        });
      } else if (info.file.status === "error") {
      }
    },
  };

  return (
    <div>
      <Row gutter={8}>
        <Button
          type="default"
          style={{
            backgroundColor: show.showFileupload ? "#4ca1af " : "white",
            color: show.showFileupload ? "white" : "black",
          }}
          onClick={() => {
            setShow({
              showUrl: false,
              showFileupload: true,
            });
          }}
        >
          File Upload
        </Button>
        <Button
          type="default"
          style={{
            backgroundColor: show.showUrl ? "#4ca1af " : "white",
            color: show.showUrl ? "white" : "black",
          }}
          onClick={() => {
            setShow({
              showFileupload: false,
              showUrl: true,
            });
          }}
        >
          Image Url
        </Button>
      </Row>

      <Row>
        <Col
          span={24}
          style={{
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {show.showFileupload && (
            <div>
              <Upload {...imageProps}>
                <Button icon={<UploadOutlined />}>Select a Image</Button>
              </Upload>
            </div>
          )}

          {show.showUrl && (
            <React.Fragment>
              <Typography>Image Url</Typography>
              <Input
                placeholder="Url"
                value={imageUrl}
                onChange={(e: any) => {
                  setImageUrl(e.target.value);
                  props.canvas.map((item: any) => {
                    if (item.canvasDom.getActiveObject()) {
                      item.canvasDom
                        .getActiveObject()
                        .set({
                          width: 500,
                          height: 500,
                        })
                        .setSrc(
                          e.target.value,
                          (img: any) => {
                            item.canvasDom.renderAll();
                          },
                          item.canvasDom.getActiveObject().toObject()
                        );
                    }
                  });

                  setTimeout(() => {
                    axios
                      .post(`${process.env.REACT_APP_API_URL}/upload/url`, {
                        url: e.target.value,
                      })
                      .then((response) => {})
                      .catch((error) => {});
                  }, 1000);
                }}
                style={{ marginTop: 8 }}
              />
            </React.Fragment>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ImageSettings;
