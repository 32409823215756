export const FETCH_AUTH = "FETCH_AUTH";

export const LOGOUT = "LOGOUT";

// GRAPH SETTINGS
export const GRAPH_SETTINGS_INITIALIZE = "GRAPH_SETTINGS_INITIALIZE";
export const GRAPH_SETTINGS_CHANGE = "GRAPH_SETTINGS_CHANGE";
export const GRAPH_SETTINGS_RESET_UPDATE_CHART_ON_CANVAS =
  "GRAPH_SETTINGS_RESET_UPDATE_CHART_ON_CANVAS";
export const GRAPH_SETTINGS_PARSER_API_CHANGE =
  "GRAPH_SETTINGS_PARSER_API_CHANGE";
export const GRAPH_SETTINGS_UPDATE_CHART_ON_CANVAS =
  "GRAPH_SETTINGS_UPDATE_CHART_ON_CANVAS";
