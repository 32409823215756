import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { AuthState } from "../core/interfaces/user.interface";

interface IProps {
  isAuthenticated?: boolean;
  path: string;
  component: any;
}

const GuestRoute: React.FC<IProps> = ({ isAuthenticated, path, component }) => {
  {
    return !isAuthenticated ? (
      <Route path={path} component={component} exact></Route>
    ) : (
      <Redirect to="/templates" /> // When authenticated, navigate to /templates as default
    );
  }
};

const mapStateToProps = (state: { auth: AuthState }) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps, null)(GuestRoute);
