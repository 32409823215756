import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import Navbar from '../../components/navbar/Navbar';

import IconNavbar from './_components/IconNav/IconNavbar';
import TextNavbar from '../../components/navbar/_components/TextNavbar';
import PageNavbar from './_components/PageNavbar/PageNavbar';
import ZoomPanel from './_components/ZoomPanel/ZoomPanel';

import Sidebar from './_components/Sidebar/Sidebar';
import './canvas.scss';
import {
	autoSaveCanvases,
	createNewProjectPage,
	fetchProjectById,
	saveReportParser,
	updateProjectCanvases,
} from '../../core/services/project.service';
import { getParser } from '../../core/services/cms.service';
import {
	buildParser,
	Parser,
	ParserApi,
} from '../../core/interfaces/parser.interface';
import { Modal, Form, Select, notification, Button } from 'antd';
import { ChartType, FabricObjectType } from '../../core/enums/enum';
import CanvasHandler from '../../utils/CanvasHandler';
import { FabricCanvas } from '../../core/interfaces/fabricCanvas.interface';
import { debounce } from 'throttle-debounce';
import ShareModal from '../../components/navbar/_components/ShareModal';

import * as _html2canvas from 'html2canvas';
import { initCenteringGuidelines } from '../../utils/CenteringGuidelines';
import { initAligningGuidelines } from '../../utils/AlignmentGuidlines';
import Item from 'antd/lib/list/Item';

const { Option } = Select;

interface IState {
	projectId: string;
	projectTitle: string;
	parserApi: ParserApi | null;
	selectedParser: Parser | null;
	selectedParserId: string;
	selectedChart: ChartType | null;
	selectedCanvasInstance: FabricCanvas | null;
	selectedObject: any;
	parserList: Array<Parser>;
	parserModalVisible: boolean;
	width: number;
	height: number;
	saving: boolean;
	canvasId: string;
	canvasInstances: Array<FabricCanvas>;
	showShareModel: boolean;
	isFetching: boolean;
	headerFooterObject: Array<Object>;
	initGuidelines: boolean;
	objectAdded: boolean;
}
const AUTO_SAVE_DEBOUNCE_TIME = 500;
interface MatchParams {
	projectId: string;
}

interface IProps {}
class Canvas extends Component<
	IProps & RouteComponentProps<MatchParams>,
	IState
> {
	canvasHandler: CanvasHandler | null = null;

	state: IState = {
		projectId: '',
		projectTitle: '',
		parserApi: null,
		parserList: [],
		parserModalVisible: false,
		selectedParser: null,
		selectedParserId: '',
		selectedChart: null,
		selectedCanvasInstance: null,
		width: 800,
		height: 1148,
		saving: false,
		canvasId: '',
		canvasInstances: [],
		selectedObject: null,
		showShareModel: false,
		isFetching: false,
		headerFooterObject: [],
		initGuidelines: false,
		objectAdded: false,
	};

	constructor(props: IProps & RouteComponentProps<MatchParams>) {
		super(props);
		this.autoSave = debounce(AUTO_SAVE_DEBOUNCE_TIME, this.autoSave);
	}

	componentDidMount() {
		const { projectId } = this.props.match.params;
		this.setState({ ...this.state, projectId }, () => {
			this.getProjectDataFromId(this.state.projectId);
		});
	}

	componentDidUpdate(prevProps: any, prevState: IState) {
		if (
			this.state.canvasInstances.length > 0 &&
			(!this.state.initGuidelines || this.state.objectAdded)
		) {
			this.state.canvasInstances.forEach((item) => {
				initAligningGuidelines(item.canvasDom);
				initCenteringGuidelines(item.canvasDom);
			});

			this.setState({
				...this.state,
				objectAdded: false,
				initGuidelines: true,
			});
		}

		if (prevState.canvasId !== this.state.canvasId) {
			this.state.canvasInstances.forEach((item: any) => {
				const frame: any = document.getElementById(`frame_${item.id}`);
				if (item.id === this.state.canvasId) {
					frame.style.border = '0.5px solid #c9c9c9';
				}
			});
		}
	}

	getProjectDataFromId = (projectId: string) => {
		fetchProjectById(projectId)
			.then((res: any) => {
				const project = res.data;
				const {
					reportInfo: { title, parserId, headerFooter },
					canvases,
				} = project;
				headerFooter.forEach((obj: any) => {
					obj.evented = false;
					obj.selectable = false;
				});
				this.setState({ ...this.state, projectTitle: title });
				// Get Data from parser API

				this.getParserData(parserId);

				const { width, height } = project;
				this.setState(
					{ ...this.state, width: width || 800, height: height || 1148 },
					() => {
						this.initCanvasHandler(canvases, headerFooter);
						this.handleObjectDeleteOnKeyPress();
					}
				);
			})
			.catch((err) => {});
	};

	handleObjectDeleteOnKeyPress = () => {
		const BACKSPACE_KEY = 'Backspace',
			DELETE_KEY = 'Delete';
		window.addEventListener('keydown', (event: KeyboardEvent) => {
			if (this.state.selectedObject && event.key) {
				if (event.key === BACKSPACE_KEY || event.key === DELETE_KEY) {
					// Check for input fields if active
					const activeElement = document.activeElement;
					var inputs = ['input', 'select', 'button', 'textarea'];
					if (
						activeElement &&
						inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1
					) {
						return;
					}
					// Check for fabric i-text if editing
					if (
						this.state.selectedObject.type === FabricObjectType.ITEXT &&
						this.state.selectedObject.isEditing
					) {
						return;
					}
					this.handleObjectDelete();
				}
			}
		});
	};

	initCanvasHandler = (canvasData: any, headerFooterObjs: Array<any>) => {
		this.canvasHandler = new CanvasHandler(
			this.state.width,
			this.state.height,
			headerFooterObjs,
			{
				onCanvasInstancesUpdate: this.onCanvasInstancesUpdate,
				onCanvasInstancesUpdateFromJSON: this.onCanvasInstancesUpdateFromJSON,
				setCanvasPageActive: this.setCanvasPageActive,
				onChartAdd: this.onChartAdd,
				onTableAdd: this.onTableAdd,
				onObjectDelete: this.onObjectDelete,
				onObjectSelect: this.onObjectSelect,
				onObjectModified: this.onObjectModified,
			}
		);

		this.canvasHandler.setFrameFromCanvasJSON(canvasData);
	};

	onCanvasInstancesUpdate = (
		mainCanvas: FabricCanvas,
		isActiveCanvas: boolean
	) => {
		this.setState(
			{
				...this.state,
				canvasInstances: this.state.canvasInstances.concat([mainCanvas]),
			},
			() => {
				if (isActiveCanvas) {
					this.setState({
						...this.state,
						canvasId: mainCanvas.id,
					});
				}
			}
		);
	};

	onCanvasInstancesUpdateFromJSON = (
		canvasInstancesFromJSON: Array<FabricCanvas>
	) => {
		this.setState(
			{
				...this.state,
				canvasInstances: this.state.canvasInstances.concat(
					canvasInstancesFromJSON
				),
			},
			() => {
				if (this.state.canvasInstances.length > 0) {
					this.state.canvasInstances.map((item: any) => {
						this.canvasHandler?.handleEvents(item.canvasDom);
					});
					this.setState({
						...this.state,
						canvasId: this.state.canvasInstances[0].id,
					});
					this.canvasHandler?.setCanvasId(this.state.canvasInstances[0].id);
				}
			}
		);
	};

	setCanvasPageActive = (canvasId: string) => {
		this.setState({ ...this.state, canvasId });
	};

	onChartAdd = (chart: Chart) => {
		let canvasDom: any = null;
		this.state.canvasInstances.map((item: any) => {
			if (item.id === this.state.canvasId) {
				canvasDom = item.canvasDom;
			}
		});
		if (canvasDom) {
			if (this.canvasHandler) {
				this.canvasHandler.handleEvents(canvasDom);
				canvasDom.add(chart);
			}

			this.setState({ ...this.state, objectAdded: true });
		}
	};

	onTableAdd = (table: any) => {
		let canvasDom: any = null;
		this.state.canvasInstances.map((item: any) => {
			if (item.id === this.state.canvasId) {
				canvasDom = item.canvasDom;
			}
		});
		if (canvasDom) {
			if (this.canvasHandler) {
				this.canvasHandler.handleEvents(canvasDom);
				canvasDom.add(table);
			}
		}
	};

	onObjectDelete = () => {
		this.setState({
			...this.state,
			selectedObject: null,
		});
		console.log('Object deleted', this.canvasHandler?.selectedObject);
	};

	onObjectSelect = (object: any) => {
		console.log('Selected Object:', object ? object : 'Noting selected');
		this.setState(
			{
				...this.state,
				selectedObject: object,
			},
			() => {
				if (object && object.canvas && object.canvas.lowerCanvasEl.id) {
					this.setState({
						...this.state,
						canvasId: object.canvas.lowerCanvasEl.id,
					});
				}
			}
		);
	};

	onObjectModified = (canvasDom: any, event: any) => {
		this.setState({
			canvasId: canvasDom && canvasDom.lowerCanvasEl.id,
		});
		this.autoSave(canvasDom);
	};

	getParserData = (parserId: string) => {
		getParser().then(
			(res) => {
				const parserListRes: Array<Parser> = [];
				const { data } = res.data.data;
				data.forEach((parser: any) => {
					parserListRes.push(buildParser(parser));
				});
				this.setState({ ...this.state, parserList: parserListRes });
				if (!parserId) {
					this.setState({ ...this.state, parserModalVisible: true });
				} else {
					this.setState({ ...this.state, selectedParserId: parserId });
					const parser = parserListRes.filter(
						(parser) => parser.id === parserId
					)[0];
					this.setState({ ...this.state, selectedParser: parser });
				}
			},
			(err) => {
				console.log(err);
			}
		);
	};

	addPages = () => {
		const pageNumber = this.state.canvasInstances.length + 1;
		createNewProjectPage(this.state.projectId, pageNumber).then((res) => {
			if (this.canvasHandler) {
				const { canvas } = res.data;
				this.canvasHandler.addNewCanvasFrame(canvas._id, pageNumber);
			}
		});
	};

	handleUpdateProjectTitle = (projectTitle: string) => {
		this.setState({ ...this.state, projectTitle });
	};

	handleParserModalOkClick = () => {
		if (this.state.selectedParserId) {
			const parser = this.state.parserList.filter(
				(parser) => parser.id === this.state.selectedParserId
			)[0];

			this.setState({
				...this.state,
				selectedParser: parser,
				parserModalVisible: false,
			});
			//save parser to api
			saveReportParser(this.state.projectId, parser.id)
				.then((res) => {})
				.catch((error) => {});
		}
	};

	onPraserChange = (parserId: string) => {
		this.setState({ ...this.state, selectedParserId: parserId });
	};

	handleSave = (canvasId: string, canvasDom: any, pageNumber: number) => {
		const canvasObj = canvasDom.toObject([
			'id',
			'name',
			'evented',
			'selectable',
			'locked',
			'editable',
			'headingType',
			'superType',
			'_id',
		]).objects;
		this.setState({
			isFetching: true,
		});
		const headerFooterObjects = this.getHeaderFooterObjs(canvasObj);
		this.setState({
			headerFooterObject: headerFooterObjects,
		});
		this.canvasHandler?.setHeaderFooterObjs(headerFooterObjects);
		autoSaveCanvases(canvasId, canvasObj, pageNumber, headerFooterObjects)
			.then((res) => {
				this.setState({
					isFetching: false,
				});
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					isFetching: false,
				});
			});
	};

	getHeaderFooterObjs = (canvasObjs: Array<any>) => {
		const headerFooterObjIds: Array<string> = [];
		const headerFooterObj = canvasObjs.filter((obj: any) => {
			if (
				'superType' in obj &&
				(obj.superType === 'header' || obj.superType === 'footer')
			) {
				headerFooterObjIds.push(obj._id);
				return true;
			}
			return false;
		});
		headerFooterObjIds.forEach((_id: string) => {
			canvasObjs.forEach((obj: any, index: number) => {
				if (obj._id === _id) {
					canvasObjs.splice(index, 1);
				}
			});
		});
		return headerFooterObj;
	};

	autoSave = (canvasDom: any) => {
		let pageNumber = 0;
		this.state.canvasInstances.map((item: any, index: number) => {
			if (item.id === this.state.canvasId) {
				pageNumber = index + 1;
			}
		});
		if (pageNumber) {
			this.handleSave(this.state.canvasId, canvasDom, pageNumber);
		}
	};

	saveCanvasToServer = (canvasJSONs: any) => {
		const { projectId, selectedParserId, projectTitle, width, height } =
			this.state;
		this.setState({ ...this.state, saving: true });
		selectedParserId &&
			updateProjectCanvases(
				projectId,
				selectedParserId,
				projectTitle,
				width,
				height,
				canvasJSONs
			)
				.then((res) => {
					this.setState({ ...this.state, saving: false });
					if (res.status === 200) {
						this.saveNotification();
					}
				})
				.catch((err) => {
					this.setState({ ...this.state, saving: false });
				});
	};

	saveNotification = () => {
		notification.success({
			message: `Report Saved`,
			description: 'Your report is saved successfully',
			placement: 'topRight',
			duration: 2.5,
		});
	};

	handleChartAdd = (type: ChartType) => {
		if (this.canvasHandler?.addChart) {
			this.canvasHandler.addChart(type, this.state.canvasId);
		}
	};

	handleObjectDelete = () => {
		let canvasDom: any = null;
		let item: any = null;
		this.state.canvasInstances.map((item: any) => {
			if (item.id === this.state.canvasId) {
				canvasDom = item.canvasDom;
			}
		});
		item = canvasDom ? canvasDom.getActiveObject() : null;
		if (item && canvasDom) {
			if (this.canvasHandler) {
				this.canvasHandler.handleObjectDelete();
			}
		}
	};
	handleObjectAdd = (objectType: FabricObjectType) => {
		if (this.canvasHandler) {
			let canvasDom = null;
			this.state.canvasInstances.map((item: any) => {
				if (item.id === this.state.canvasId) {
					canvasDom = item.canvasDom;
				}
			});
			this.canvasHandler.handleObjectAdd(objectType, canvasDom);

			this.setState({ ...this.state, objectAdded: true });
		}
	};

	showModal = () => {
		this.setState({
			showShareModel: true,
		});
	};

	hideModal = () => {
		this.setState({
			showShareModel: false,
		});
	};

	handleTableAdd = () => {
		if (this.canvasHandler?.addTable) {
			this.canvasHandler.addTable(this.state.canvasId, this.buildTableData());
		}
	};

	buildTableData = () => {
		let tableOfContentHeadings: Array<Array<{ text: string; type?: string }>> =
			[];
		this.state.canvasInstances.forEach(
			(canvasInstance: any, canvasIndex: number) => {
				const pageNumber = canvasIndex + 1;
				const canvasObjs = canvasInstance.canvasDom.toObject([
					'id',
					'name',
					'locked',
					'editable',
					'headingType',
				]).objects;
				const iTextHeadingObjs = canvasObjs.filter(
					(obj: { type: string; headingType: string }) => {
						return (
							obj.type === 'i-text' &&
							(obj.headingType === 'heading1' ||
								obj.headingType === 'heading2' ||
								obj.headingType === 'heading3')
						);
					}
				);
				iTextHeadingObjs.forEach(
					(obj: { headingType: string; text: string }) => {
						tableOfContentHeadings.push([
							{
								text: obj.text,
								type: obj.headingType,
							},
							{ text: `${pageNumber}` },
						]);
					}
				);
			}
		);
		return tableOfContentHeadings;
	};

	setCanvasInstances = (id: string) => {
		this.setState({
			...this.state,
			canvasInstances: this.state.canvasInstances.filter(
				(item: any) => item.id !== id
			),
		});
	};

	render() {
		const {
			projectId,
			projectTitle,
			saving,
			selectedParser,
			parserModalVisible,
			parserList,
			canvasInstances,
			canvasId,
			width,
			height,
			selectedChart,
			selectedObject,
		} = this.state;

		return (
			<>
				<div className='app-container'>
					<ShareModal
						visible={this.state.showShareModel}
						hideModal={this.hideModal}
						reportId={projectId}
					/>
					<div className='custom-navbar  sticky-top sticky-fixed '>
						<Navbar
							projectTitle={projectTitle}
							reportId={projectId}
							updateProjectTitle={(projectTitle) =>
								this.handleUpdateProjectTitle(projectTitle)
							}
						/>
						<TextNavbar
							isFetching={this.state.isFetching}
							showModal={this.showModal}
							selectedParser={selectedParser}
						/>
						<IconNavbar
							canvas={canvasInstances}
							canvasId={canvasId}
							onSave={() => {}}
							projectId={projectId}
							projectTitle={projectTitle}
							onObjectDelete={this.handleObjectDelete}
							onChartClick={this.handleChartAdd}
							onObjectAdd={this.handleObjectAdd}
							onTableClick={this.handleTableAdd}
						/>
					</div>
					<div className='main-container'>
						{/* <button
          onClick={() => {
            const preview = document.getElementById("img");
            preview?.setAttribute("crossOrigin", "anonymous");
            preview?.setAttribute("src", canvas.toDataURL());
            // preview?.addEventListener("onload", function () {
            // });
          }}
        >
          Preview
        </button>
        <div>
          <img src="" id="img" alt="" />
        </div> */}
						{/* <input
          type="color"
          onChange={(event) => {
            canvas.backgroundColor = event.target.value;
            canvas.renderAll();
          }}
          id="color"
        />

        <input
          type="color"
          onChange={(event) => {
            if (canvas.getActiveObject()) {
              canvas.getActiveObject().set("fill", event.target.value);
              canvas.renderAll();
            }
          }}
          id="color"
        /> */}
						<div className='main__containers__inner d-flex'>
							<PageNavbar
								canvas={canvasInstances}
								canvasId={canvasId}
								addPages={this.addPages}
								// selectMultiplePages={selectMultiplePages}
								setCanvasInstances={this.setCanvasInstances}
								// setCanvasId={setCanvasId}
								selectMultiplePages={() => null}
								setCanvasId={() => null}
							/>
							<div id='canvas__container'>
								<ZoomPanel
									canvas={canvasInstances}
									canvasId={canvasId}
									canvasWidth={width}
									canvasHeight={height}
								/>
							</div>
							<Sidebar
								canvasWidth={width}
								canvasHeight={height}
								setWidth={(width: any) => this.setState({ width })}
								setHeight={(height: any) => this.setState({ height })}
								canvasId={canvasId}
								canvas={canvasInstances}
								selectedParser={selectedParser}
								selectedChartType={selectedChart}
								selectedObject={selectedObject}
								headerFooterObject={this.state.headerFooterObject}
								setFetching={(isFetching: any) => {
									this.setState({
										isFetching,
									});
								}}
							/>
						</div>
					</div>
				</div>
				{/* Parser Select Modal */}
				<Modal
					className='parser__select'
					maskStyle={{ zIndex: 10000 }}
					zIndex={10000}
					title='Select a Parser'
					visible={parserModalVisible}
					onOk={this.handleParserModalOkClick}
					closable={false}
					footer={[
						<Button
							key='submit'
							type='primary'
							onClick={this.handleParserModalOkClick}>
							Done
						</Button>,
					]}>
					<Form name='parser-form'>
						<Form.Item
							name='parser'
							label='Parser'
							rules={[{ required: true }]}>
							<Select
								dropdownStyle={{ zIndex: 10000 }}
								placeholder='Select a parser'
								onChange={this.onPraserChange}
								allowClear>
								{parserList.map((parser: any) => (
									<Option key={parser.id} value={parser.id}>
										{parser.parserName}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Form>
				</Modal>
			</>
		);
	}
}

export default withRouter(Canvas);
