import React, { FC } from "react";
import { Row, Col, InputNumber, Typography, Slider, Switch } from "antd";
interface IElementTypes {
  canvas: any;
  canvasId: any;
  type: string;
  selectedObject: any;
  dimensions: any;
  position: any;
  rotateAngle: any;
  lock: any;
  setDimensions: any;
  setPosition: any;
  setRotateAngle: any;
  setLock: Function;
}
const ElementGeneralSettings: FC<IElementTypes> = ({
  selectedObject,
  canvas,
  canvasId,
  type,
  lock,
  dimensions,
  position,
  rotateAngle,
  setDimensions,
  setPosition,
  setRotateAngle,
  setLock,
}) => {
  // let isObjectMoving;
  // canvas.map((item: any) => {
  //   item.canvasDom.on("object:moving", function (e: any) {
  //     isObjectMoving = true;

  //     item.canvasDom.on("mouse:up", function (e: any) {
  //       isObjectMoving = false;
  //       if (e.target && item.canvasDom.getActiveObject()) {
  //         setDimensions({
  //           width: item.canvasDom.getActiveObject().width,
  //           height: item.canvasDom.getActiveObject().height,
  //         });
  //         setPosition({
  //           left: item.canvasDom.getActiveObject().left,
  //           top: item.canvasDom.getActiveObject().top,
  //         });
  //         // setRotateAngle(item.canvasDom.getActiveObject().angle);
  //       }
  //     });
  //   });
  // });

  // canvas.map((item: any) => {
  //   item.canvasDom.on("object:modified", function (options: any) {
  //     console.log(options.target.getWidth());
  //   });
  // });

  return (
    <div>
      <Row>
        <Col span={12} style={{ marginBottom: 14 }}>
          <p>Lock</p>
          <Switch
            size="small"
            checked={lock}
            onChange={(checked) => {
              setLock(checked);
              canvas.map((item: any) => {
                if (item) {
                  // item.canvasDom.getActiveObject()["hasControl"] = checked;
                  // item.canvasDom.getActiveObject()["lockMovementX"] = checked;
                  // item.canvasDom.getActiveObject()["lockMovementY"] = checked;
                  // item.canvasDom.renderAll();

                  console.log("canvas-dom", item.canvasDom.getActiveObject());
                }
              });
            }}
          />
        </Col>
      </Row>
      <Row gutter={8}>
        {/**Width/Height */}

        <Col span={12}>
          <Typography>Width</Typography>
          <InputNumber
            value={Math.trunc(dimensions.width)}
            onChange={(value) => {
              setDimensions({
                width: value,
                height: dimensions.height,
              });
              canvas.map((item: any) => {
                if (item.canvasDom.getActiveObject()) {
                  item.canvasDom
                    .getActiveObject()
                    .set({
                      scaleX: 1,
                      width: value,
                    })
                    .setCoords();
                  item.canvasDom.renderAll();
                }
              });
            }}
            placeholder="Width"
          />
        </Col>
        <Col span={12}>
          <Typography>Height</Typography>
          <InputNumber
            value={Math.trunc(dimensions.height)}
            onChange={(value) => {
              setDimensions({
                width: dimensions.width,
                height: value,
              });
              canvas.map((item: any) => {
                if (item.canvasDom.getActiveObject()) {
                  item.canvasDom
                    .getActiveObject()
                    .set({
                      scaleY: 1,
                      height: value,
                    })
                    .setCoords();
                  item.canvasDom.renderAll();
                }
              });
            }}
            placeholder="Height"
          />
        </Col>

        {/** LEFT/Right */}
      </Row>

      <Row gutter={8} style={{ marginTop: 20, marginBottom: 20 }}>
        <Col span={12}>
          <Typography>Left</Typography>
          <InputNumber
            value={Math.trunc(position.left)}
            onChange={(value) => {
              setPosition({
                ...position,
                left: value,
              });
              canvas.map((item: any) => {
                if (item.canvasDom.getActiveObject()) {
                  item.canvasDom
                    .getActiveObject()
                    .set({
                      left: value,
                    })
                    .setCoords();
                  item.canvasDom.renderAll();
                }
              });
            }}
            placeholder="Left"
          />
        </Col>
        <Col span={12}>
          <Typography>Top</Typography>
          <InputNumber
            value={Math.trunc(position.top)}
            onChange={(value) => {
              setPosition({
                ...position,
                top: value,
              });
              canvas.map((item: any) => {
                if (item.canvasDom.getActiveObject()) {
                  item.canvasDom
                    .getActiveObject()
                    .set({
                      top: value,
                    })
                    .setCoords();
                  item.canvasDom.renderAll();
                }
              });
            }}
            placeholder="Top"
          />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Typography>Angle</Typography>
          <Slider
            min={0}
            max={360}
            value={rotateAngle}
            onChange={(value: any) => {
              canvas.map((item: any) => {
                if (item.canvasDom.getActiveObject()) {
                  item.canvasDom.getActiveObject().set({
                    angle: value,
                  });
                  item.canvasDom.renderAll();
                }
              });
              setRotateAngle(value);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ElementGeneralSettings;
