import React from "react";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import { fabric } from "fabric";
import { previewReportById } from "../../core/services/project.service";
import { IProject } from "../../core/interfaces/project.interface";
import { FabricObjectType } from "../../core/enums/enum";
import Chart from "../../utils/CustomFabricObjects/Chart";
import "./preview.scss";
import { TableOption } from "../../core/interfaces/table.interface";
import Table from "../../utils/CustomFabricObjects/Table";
import { initAligningGuidelines } from "../../utils/AlignmentGuidlines";
import { initCenteringGuidelines } from "../../utils/CenteringGuidelines";
interface ChartOptions {
  canvasId: string;
  id: string;
  width: number;
  height: number;
  editable: boolean;
  angle: number;
}
const Preview = () => {
  const previewContainerClassName = "preview__container";
  const { projectId } = useParams<{ projectId: string }>();

  React.useEffect(() => {
    const getDataUrl = () => {
      previewReportById(projectId)
        .then((res: { data: IProject }) => {
          const project: any = res.data;
          const {
            reportInfo: { title, parserId, width, height, headerFooter },
            canvases,
          } = project;

          canvases.forEach((canvasJson: any) => {
            const canvasContainer = document.getElementById(
              previewContainerClassName
            );
            const canvasFrame = document.createElement("div");
            canvasFrame.style.position = "relative";
            canvasFrame.id = `frame_${canvasJson._id}`;
            canvasFrame.className = "canvas__frame";
            canvasContainer?.appendChild(canvasFrame);
            const mainCanvas = document.createElement("canvas");

            mainCanvas.id = canvasJson._id;
            canvasFrame.appendChild(mainCanvas);

            const fabricCanvas = new fabric.Canvas(mainCanvas, {
              width,
              height,
              backgroundColor: "#fff",
              preserveObjectStacking: true,
            });
            initAligningGuidelines(fabricCanvas)
            initCenteringGuidelines(fabricCanvas)

            let chartObjects: any[] = [];
            let tableObjects: any[] = [];
            let otherObjects: any[] = [];
            canvasJson &&
              canvasJson.canvasData.objects.forEach((object: any) => {
                if (object.type === "chart") {
                  chartObjects.push(object);
                } else if (object.type === "table") {
                  tableObjects.push(object);
                } else {
                  otherObjects.push(object);
                }
              });

            otherObjects.forEach((object) => {
              const fabricObject = handleObjectAddFromJSON(object);
              fabricObject && fabricCanvas.add(fabricObject);
            });
            chartObjects.forEach((object: any) => {
              const chartOption = object.chartOption;
              let otherOptions = { ...object, canvasId: canvasJson._id };
              delete otherOptions.chartOption;
              const chart = createChart(
                chartOption,
                otherOptions,
                canvasJson._id
              );

              fabricCanvas && fabricCanvas.add(chart);
            });

            tableObjects.forEach((object: any) => {
              const tableOption = object.tableOption;
              let otherOptions = { ...object, canvasId: canvasJson._id };
              delete otherOptions.tableOption;
              const table = createTable(
                tableOption,
                otherOptions,
                canvasJson._id
              );

              fabricCanvas && fabricCanvas.add(table);
            });

            updateHeaderFooter(headerFooter, fabricCanvas, canvasJson.pageNumber);
            fabricCanvas.renderAll();
          });
        })
        .catch((err: any) => { });
    };
    getDataUrl();
  }, []);

  const handleObjectAddFromJSON = (object: any) => {
    let fabricObject = null;
    const fabricObjectType = object.type as FabricObjectType;
    switch (fabricObjectType) {
      case FabricObjectType.ITEXT:
        let textOptions = { ...object };
        const objectTitle = textOptions.title;
        delete textOptions.title;
        fabricObject = new fabric.IText(objectTitle, textOptions);
        break;
      case FabricObjectType.LINE:
        let lineOptions = { ...object };
        const lineCoords = [
          lineOptions.x1,
          lineOptions.y1,
          lineOptions.x2,
          lineOptions.y2,
        ];
        delete lineOptions.x1;
        delete lineOptions.y1;
        delete lineOptions.x2;
        delete lineOptions.y2;
        fabricObject = new fabric.Line(lineCoords, lineOptions);
        break;
      case FabricObjectType.TRIANGLE:
        fabricObject = new fabric.Triangle({ ...object });
        break;
      case FabricObjectType.RECT:
        fabricObject = new fabric.Rect({ ...object });
        break;
      case FabricObjectType.CIRCLE:
        fabricObject = new fabric.Circle({ ...object });
        break;
    }
    return fabricObject;
  };

  const updateHeaderFooter = (
    headerFooterObjs: Array<any>,
    fabricCanvas: fabric.Canvas,
    pageNumber?: number
  ) => {
    headerFooterObjs &&
      headerFooterObjs.forEach((object: any) => {
        let otherOption = { ...object };
        delete otherOption.superType;
        delete otherOption._id;
        let fabricObject = null;
        let isImage = false;
        switch (object.type) {
          case FabricObjectType.ITEXT:
            if (object._id === "footer-page" && pageNumber) {
              otherOption.text = `page ${pageNumber}`;
            }
            fabricObject = new fabric.IText("", {
              ...otherOption,
            });
            fabricObject.toObject = function () {
              return {
                superType: object.superType,
                _id: object._id,
                ...otherOption,
              };
            };
            isImage = false;
            break;
          case FabricObjectType.LINE:
            const xYCoordinates = {
              x1: otherOption.x1,
              y1: otherOption.y1,
              x2: otherOption.x2,
              y2: otherOption.y2,
            };
            delete otherOption.x1;
            delete otherOption.y1;
            delete otherOption.x2;
            delete otherOption.y2;
            fabricObject = new fabric.Line(
              [
                xYCoordinates.x1,
                xYCoordinates.y1,
                xYCoordinates.x2,
                xYCoordinates.y2,
              ],
              otherOption
            );
            fabricObject.toObject = function () {
              return {
                superType: object.superType,
                _id: object._id,
                ...xYCoordinates,
                ...otherOption,
              };
            };
            isImage = false;
            break;
          case FabricObjectType.IMAGE:
            addImage(object, fabricCanvas);
            isImage = true;
            break;
        }
        !isImage && fabricObject && fabricCanvas.add(fabricObject);
      });
  };

  const addImage = (object: any, fabricCanvas: fabric.Canvas) => {
    fabric.Image.fromURL(
      object.src,
      (img: any) => {
        if (img) {
          object.filters.map((item: any) => {
            if (item.type === "Grayscale") {
              img.filters.push(new fabric.Image.filters.Grayscale());
              img.applyFilters();
            } else if (item.type === "Invert") {
              img.filters.push(new fabric.Image.filters.Invert());
              img.applyFilters();
            } else if (item.type === "Sepia") {
              img.filters.push(new fabric.Image.filters.Sepia());
              img.applyFilters();
            } else if (item.type === "BlendColor") {
              img &&
                img.filters.push(
                  new fabric.Image.filters.BlendColor({
                    mode: "add",
                    color: "red",
                  })
                );
              img.applyFilters();
            } else if (item.type === "Brightness") {
              img &&
                img.filters.push(
                  new fabric.Image.filters.Brightness({
                    brightness: item.brightness,
                  })
                );

              img.applyFilters();
            } else {
              img &&
                img.filters.push(
                  new fabric.Image.filters.Contrast({
                    contrast: item.contrast,
                  })
                );

              img.applyFilters();
            }
          });

          img.scale(0.3).set({
            ...object,
            filters: img.filters,
          });

          img && fabricCanvas.add(img);
        }
      },
      { crossOrigin: "anonymous" }
    );
  };

  const createChart = (
    chartData: any,
    otherOptions: ChartOptions | null,
    canvasId: string
  ) => {
    var otherOption = {};
    if (!otherOptions) {
      otherOption = {
        canvasId,
        id: v4(),
        width: 500,
        height: 500,
        editable: true,
        angle: 0,
      };
    } else {
      otherOption = otherOptions;
    }
    return new Chart(chartData, otherOption);
  };

  const createTable = (
    tableData: TableOption,
    otherOptions: any | null,
    canvasId: string
  ) => {
    let otherOption = {};
    const DEFAULT_CELL_HEIGHT = 60;
    if (!otherOptions) {
      otherOption = {
        canvasId,
        id: v4(),
        width: 750,
        height: tableData.contents.length * DEFAULT_CELL_HEIGHT,
        editable: true,
        angle: 0,
      };
    } else {
      otherOption = otherOptions;
    }
    return new Table(tableData, otherOption);
  };

  return <div id={previewContainerClassName}></div>;
};

export default Preview;
