import React from "react";
import { Redirect, Route, RouteProps } from "react-router";

export interface IProps extends RouteProps {
  isAuthenticated: boolean;
  path: string;
  component: any;
}

export const ProtectedRoute: React.FC<IProps> = ({
  isAuthenticated,
  path,
  component,
}: {
  isAuthenticated: boolean;
  path: string;
  component: any;
}) => {
  return isAuthenticated ? (
    <Route path={path} component={component} exact></Route>
  ) : (
    <Redirect to="/login" />
  );
};
