import React, { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Canvas from "../pages/Canvas/Canvas";
import Templates from "../pages/Templates/Templates";
import Login from "../pages/Login/Login";
import { ProtectedRoute } from "./ProtectedRoute";
import GuestRoute from "./GuestRoute";
import Preview from "../pages/Preview/Preview";
import Shares from "../pages/Shares/Shares";

const Router = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/"
          exact
          render={() => <Redirect to="/templates" />}
        ></Route>
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          component={Templates}
          path="/templates"
        />
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          component={Canvas}
          path="/canvas/:projectId"
        />
        <Route path="/preview/:projectId" component={Preview}></Route>
        <GuestRoute path="/login" component={Login}></GuestRoute>
      </Switch>
    </BrowserRouter>
  );
};

const mapStateToProps = (state: any): { isAuthenticated: boolean } => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps, null)(Router);
