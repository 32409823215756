import React, { ChangeEvent, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../core/store/actions/authActions';
import { Layout } from 'antd';
import './navbar.scss';
import { Menu, Dropdown } from 'antd';
import { useHistory } from 'react-router-dom';
import { useStore } from 'react-redux';
import { updateReportTitle } from '../../core/services/project.service';

const { Header } = Layout;

interface IProps {
	projectTitle?: string;
	updateProjectTitle?: (projectTitle: string) => void;
	logout: () => void;
	reportId?: string;
}

const Navbar: React.FC<IProps> = ({
	projectTitle,
	updateProjectTitle,
	logout,
	reportId,
}) => {
	let history = useHistory();
	const store: any = useStore();

	const [headerTitle, setHeaderTitle] = useState<string>(
		projectTitle ? projectTitle : ''
	);
	const [enableProjectTitleEdit, setEnableProjectTitleEdit] =
		useState<boolean>(false);
	const [nameInitial, setNameInitial] = useState<string>('');

	useEffect(() => {
		const { auth } = store.getState();
		const getNameInitial = (name: string) => {
			setNameInitial(name.charAt(0).toUpperCase());
		};
		getNameInitial(auth.auth.name);
	}, []);

	useEffect(() => {
		projectTitle && setHeaderTitle(projectTitle);
	}, [projectTitle]);

	const handleLogoutClick = () => {
		logout();
	};

	const handleLogoClick = () => {
		history.push('/');
	};

	const handleEditIconClick = () => {
		setEnableProjectTitleEdit(true);
	};

	const handleProjectTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const updatedProjectTitle = event.target.value;
		setHeaderTitle(updatedProjectTitle);
		updateProjectTitle && updateProjectTitle(updatedProjectTitle);
	};

	const menu = (
		<Menu>
			<Menu.Item key='0' onClick={() => handleLogoutClick()}>
				Logout
			</Menu.Item>
		</Menu>
	);

	return (
		<Layout>
			<Header className='header'>
				<div className='logo d-flex align-center' onClick={handleLogoClick}>
					<div className='logoImg__wrap'>
						<img
							src='../../assets/images/logo.svg'
							className='img-fluid'
							alt='logo'
						/>
					</div>
					<div className='logoText__wrap'>
						<p>reporting tool</p>
					</div>
				</div>
				{projectTitle && (
					<>
						{!enableProjectTitleEdit ? (
							<>
								<div className='canvas__title' onClick={handleEditIconClick}>
									<div className='change__title d-flex align-center'>
										<p>{headerTitle}</p>
										<span>
											<img
												src='../../assets/images/menubar/edit.png'
												className='img-fluid'
												alt='Edit'
											/>
										</span>
									</div>
								</div>
							</>
						) : (
							<input
								type='text'
								style={{ height: '30px' }}
								value={headerTitle}
								onBlur={() => {
									setEnableProjectTitleEdit(false);
									updateReportTitle(reportId, headerTitle);
								}}
								onChange={handleProjectTitleChange}
								autoFocus
							/>
						)}
					</>
				)}

				<div className='report__drop'>
					<Dropdown overlay={menu} trigger={['click']}>
						<div className='d-flex align-center'>
							<span className='user__name'>{nameInitial}</span>
							<img
								src='../../assets/images/menubar/arrow-down.png'
								className='img-fluid'
								alt='Down Arrow'
							/>
						</div>
					</Dropdown>
				</div>
			</Header>
		</Layout>
	);
};

const mapStateToProps = (state: { auth: any }) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps, { logout })(Navbar);
