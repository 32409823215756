import React, { useState, ChangeEvent } from "react";
import { History } from "history";
import { connect } from "react-redux";
import { fetchLogin } from "../../core/store/actions/authActions";
import { Form, Input, Button, Checkbox } from "antd";
import "./login.scss";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

interface IProps {
  history: History;
  auth: any;
  fetchLogin: (username: string, password: string) => void;
}

const Login: React.FC<IProps> = ({
  history,
  auth,
  fetchLogin,
}): JSX.Element => {
  const [username, setUsername] = useState<string>("saroj.khadka@ekbana.info");
  const [password, setPassword] = useState<string>("admin123");

  const loginClick = () => {
    fetchLogin(username, password);
  };

  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  return (
    <div className="login__wrapper">
      <Form
        {...layout}
        name="basic"
        initialValues={{
          ["username"]: username,
          ["password"]: password,
        }}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input
            type="text"
            placeholder="Username"
            onChange={handleUsernameChange}
          />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password
            type="password"
            placeholder="Password"
            onChange={handlePasswordChange}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="btn__sumbit"
            onClick={loginClick}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const mapStateToProps = (state: { auth: any }) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { fetchLogin })(Login);
