import React from 'react';
import {
	Button,
	Dropdown,
	Typography,
	Form,
	Menu,
	Popover,
	Slider,
	Row,
	Col,
	Radio,
	Input,
} from 'antd';
import { SketchPicker } from 'react-color';
interface PropTypes {
	canvas: any;
	canvasId: any;
	type: string;
	selectedObject: any;
	color: string;
	strokeColor: string;
	strokeWidth: number;
	opacity: number;
	fill: string;
	setFill: Function;
	setColor: Function;
	setStrokeColor: Function;
	setOpacity: Function;
	setStrokeWidth: Function;
}

const tailLayout = {
	labelCol: { span: 24 },
	wrapperCol: { span: 24 },
};

const Styleobject = (props: PropTypes) => {
	const fillColor = (value: any): void => {
		if (props.type === 'element') {
			props.canvas.map((item: any) => {
				if (item.id === props.canvasId) {
					if (item.canvasDom.getActiveObject()) {
						item.canvasDom.getActiveObject().set({
							dirty: true,
							fill: value.hex,
						});

						item.canvasDom.renderAll();
					}
				}
			});
		}
	};

	const StrokeColor = (value: any): void => {
		if (props.type === 'element') {
			props.canvas.map((item: any) => {
				if (item.id === props.canvasId) {
					if (item.canvasDom.getActiveObject()) {
						item.canvasDom.getActiveObject().set({
							dirty: true,
							stroke: value,
							storkeWidth: 10,
						});

						item.canvasDom.renderAll();
					}
				}
			});
		}
	};

	return (
		<div className='text__style__wrapper'>
			<Form {...tailLayout} name='basic'>
				{props.selectedObject && props.selectedObject.type !== 'line' && (
					<Row>
						<Col span={6}>
							<Form.Item label='Color' name='color'>
								<Popover
									placement='bottom'
									content={
										<SketchPicker
											color={props.color}
											onChangeComplete={(value: any) => {
												props.setColor(value.hex);
												fillColor(value);
											}}
										/>
									}
									trigger='click'
									overlayClassName='colorpop'>
									<Button
										className='colorPickers'
										style={{ backgroundColor: props.color }}>
										C
									</Button>
								</Popover>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Radio.Group
								onChange={(e) => {
									if (e.target.value === 'fill') {
										props.setFill('fill');
										// Was returning undefined
										props.canvas.forEach((item: any) => {
											// matched with canvas ID
											if (item.id === props.canvasId) {
												if (
													item.canvasDom.getActiveObject().type ===
														'triangle' ||
													item.canvasDom.getActiveObject().type === 'circle' ||
													item.canvasDom.getActiveObject().type === 'rect' ||
													item.canvasDom.getActiveObject().type === 'i-text'
												) {
													item.canvasDom.getActiveObject().set({
														fill: 'black',
													});

													item.canvasDom.renderAll();
												}
											}
										});
									} else {
										props.setFill('nofill');
										props.setColor('rgba(255,255,255,0)');
										props.canvas.forEach((item: any) => {
											if (item.id === props.canvasId) {
												if (
													item.canvasDom.getActiveObject().type ===
														'triangle' ||
													item.canvasDom.getActiveObject().type === 'circle' ||
													item.canvasDom.getActiveObject().type === 'rect' ||
													item.canvasDom.getActiveObject().type === 'i-text'
												) {
													item.canvasDom.getActiveObject().set({
														fill: 'rgba(255,255,255,0)',
													});

													item.canvasDom.renderAll();
												}
											}
										});
									}
								}}
								value={props.fill}>
								<Radio value='fill'>Fill</Radio>
								<Radio value='nofill'>No Fill</Radio>
							</Radio.Group>
						</Col>
					</Row>
				)}

				<Form.Item label='Opacity' name='Opacity'>
					{/* {console.log(props.opacity)} */}
					<Slider
						value={props.opacity}
						min={0}
						max={1}
						step={0.01}
						onChange={(value: number) => {
							props.setOpacity(value);
							props.canvas.map((item: any) => {
								if (item.canvasDom.getActiveObject()) {
									item.canvasDom.getActiveObject().set({
										opacity: +value,
									});
									item.canvasDom.renderAll();
								}
							});
						}}
					/>
				</Form.Item>
				{props.type === 'element' && (
					<div>
						<Form.Item label='Stroke Color' name='strokecolor'>
							<Popover
								placement='bottom'
								content={
									<SketchPicker
										color={props.strokeColor ? props.strokeColor : '#fff'}
										onChangeComplete={(value: any) => {
											props.setStrokeColor(value.hex);
											StrokeColor(value.hex);
										}}
									/>
								}
								trigger='click'
								overlayClassName='colorpop'>
								<Button
									className='colorPickers'
									style={{ backgroundColor: props.strokeColor }}>
									S
								</Button>
							</Popover>
						</Form.Item>

						<Typography>Stroke Width</Typography>

						<Input
							type='number'
							defaultValue={1}
							onChange={(e) => {
								props.canvas.map((item: any) => {
									if (item.canvasDom.getActiveObject()) {
										item.canvasDom.getActiveObject().set({
											strokeWidth: +e.target.value,
										});
										item.canvasDom.renderAll();
									}
								});
								props.setStrokeWidth(+e.target.value);
							}}
						/>
					</div>
				)}
			</Form>
		</div>
	);
};

export default Styleobject;
