import axios from "axios";
import { config } from "../../config/config";

const shareUrl = `${config.apiURL}/shares`;
const usersUrl = `${config.apiURL}/users`;

//get shares
export const getShares = (creatorId: string) => {
  return axios.get(`${shareUrl}/${creatorId}`, {
    headers: {
      Authorization: window.localStorage.getItem("accessToken"),
    },
  });
};

//get shares users
export const getUsers = (value: string) => {
  return axios.get(`${usersUrl}`, {
    params: {
      s: value,
    },
    headers: {
      Authorization: window.localStorage.getItem("accessToken"),
    },
  });
};

//save shares and send mail

export const saveShares = (shareData: any) => {
  return axios.post(
    `${shareUrl}`,
    { ...shareData },
    {
      headers: {
        Authorization: window.localStorage.getItem("accessToken"),
      },
    }
  );
};
