import React, { useEffect, useState } from 'react';
import './pagenavbar.scss';
import { initAligningGuidelines } from '../../../../utils/AlignmentGuidlines';
import { Layout, Menu, Popover, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { NavHashLink } from 'react-router-hash-link';
const { Sider } = Layout;

const content = (props: any, setCanvasInstances: () => void) => {
	return (
		<div>
			<Button
				onClick={(e) => {
					setCanvasInstances();
					const canvasContainer = document.getElementById(`${props.canvasId}`)
						?.parentNode?.parentNode?.parentNode;

					const canvasFrame: any = document.getElementById(`${props.canvasId}`)
						?.parentNode?.parentNode;

					canvasContainer?.removeChild(canvasFrame);
				}}
				icon={<DeleteOutlined />}></Button>
		</div>
	);
};
interface PropsTypes {
	canvas: any;
	addPages: () => void;
	canvasId: any;
	selectMultiplePages: Function;
	setCanvasInstances: (id: string) => void;
	setCanvasId: Function;
}

const PageNavbar = (props: PropsTypes) => {
	return (
		<div className='sidenav__wrapper'>
			<Sider>
				<div className='inner-sidebar'>
					<div className='top__sidebar'>
						<div className='groups-list-scroll'>
							<div className='names'>
								<p className='title'>Pages</p>
							</div>
							<div>
								{props.canvas.map((item: any, index: number) => (
									<a
										href={`#${item.id}`}
										className={
											props.canvasId && props.canvasId === item.id
												? 'page_item active'
												: 'page_item'
										}
										key={item.id}
										onClick={() => {
											props.selectMultiplePages(item.id);
										}}>
										<Popover
											key={item.id}
											content={() =>
												content(props, () => props.setCanvasInstances(item.id))
											}
											placement='right'>
											<img
												src='../../assets/images/sidebar/file-active.svg'
												alt='file'
												className='img-fluid'
											/>
											<p>Page {index + 1}</p>
										</Popover>
									</a>
								))}
							</div>
						</div>
					</div>
					<div className='add__pages'>
						<button
							onClick={() => {
								props.addPages();
							}}>
							<span className='adding_page'>
								<img
									src='../../assets/images/sidebar/add-file.svg'
									alt='file add'
									className='img-fluid'
								/>
							</span>
							<p>Add Page</p>
						</button>
					</div>
				</div>
			</Sider>
		</div>
	);
};

export default PageNavbar;
