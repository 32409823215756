import React, { Component, LegacyRef } from 'react';
import {
	Form,
	Select,
	Col,
	Row,
	Alert,
	Input,
	Radio,
	Dropdown,
	Menu,
	Button,
	Tag,
	Tooltip,
	Collapse,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import { DownOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
	ChartDate,
	Parser,
	ParserApi,
} from '../../../../../core/interfaces/parser.interface';
import {
	ChartType,
	ChartStackType,
	ChartUpdate,
} from '../../../../../core/enums/enum';
import { fetchDataFromParserApi } from '../../../../../core/services/cms.service';
import {
	getChartStackTypeFromEchartOption,
	getParserChartTypeFromEchartOption,
	parseChartDataFromResponse,
	updateChartOnStackType,
	updateChartTitle,
	getChartTitleFromEchartOption,
	updateXAxisName,
	updateYAxisName,
	getAxisNamesFromEchartOption,
	updateChartColorPalette,
	getColorsFromEchartOption,
	getLegendsFromEchartOption,
	updateLegends,
} from '../../../../../utils/EChartParser.util';
import {
	COLOR_SET,
	COLOR_SET_KEYS,
	ECHARTS_DEFAULT_COLOR_SET,
} from '../../../../../core/constants/Constants';
import { debounce } from 'throttle-debounce';
import { autoSaveCanvases } from '../../../../../core/services/project.service';
import moment from 'moment';
const { Option } = Select;
const { Panel } = Collapse;

const tailLayout = {
	labelCol: { span: 24 },
	wrapperCol: { span: 24 },
};
interface IProps {
	selectedParser: Parser | null;
	selectedChart: any | null;
	canvas: any;
	canvasId: string;
	setFetching: Function;
}

interface IState {
	chartTitle: string;
	xAxisName: string;
	yAxisName: string;
	legends: Array<string>;
	colorPalette: Array<string> | null;
	chartType: ChartType | null;
	chartUpdate: ChartUpdate | null;
	chartStackType: ChartStackType | null;
	parserApis: Array<ParserApi> | null;
	selectedParserApi: any;
	inputVisible: boolean;
	inputValue: string;
	editInputIndex: number;
	editInputValue: string;
	placeholderInput: string;
	colorIndex: number;
}

enum FormItemName {
	CHART_TITLE = 'chartTitle',
	X_AXIS_NAME = 'xAxisName',
	Y_AXIS_NAME = 'yAxisName',
	LEGENDS = 'legend',
}

class GraphSetting extends Component<IProps, IState> {
	formRef = React.createRef<FormInstance>();
	colorRef = React.createRef<HTMLDivElement>();

	state: IState = {
		chartTitle: '',
		xAxisName: '',
		yAxisName: '',
		legends: [''],
		colorPalette: null,
		chartType: null,
		chartUpdate: ChartUpdate.NONE,
		chartStackType: null,
		selectedParserApi: null,
		parserApis: null,
		inputVisible: false,
		inputValue: '',
		editInputIndex: -1,
		editInputValue: '',
		placeholderInput: '',
		colorIndex: 0,
	};

	componentDidMount() {
		this.setState({
			...this.state,
			parserApis: this.props.selectedParser
				? this.props.selectedParser.apis
				: null,
		});
		this.initGraphSettings();
	}

	debounceFunc = debounce(1000, false, () => {
		let pageNumber = 0;
		this.props.canvas.forEach((item: any, index: any) => {
			if (item.id === this.props.canvasId) {
				pageNumber = index + 1;
				if (pageNumber) {
					const canvasObj = item.canvasDom.toObject([
						'id',
						'name',
						'locked',
						'editable',
						'chartUpdateDuration',
					]).objects;
					this.props.setFetching(true);
					autoSaveCanvases(this.props.canvasId, canvasObj, pageNumber)
						.then((res: any) => {
							this.props.setFetching(false);
						})
						.catch((error: any) => {
							this.props.setFetching(false);
							console.log(error);
						});
				}
			}
		});
	});
	componentDidUpdate(prevProps: IProps, prevState: IState) {
		if (this.props.selectedChart?.id !== prevProps.selectedChart?.id) {
			this.initGraphSettings();
		}
		if (this.state.chartTitle !== prevState.chartTitle) {
			this.formRef.current?.setFields([
				{ name: FormItemName.CHART_TITLE, value: this.state.chartTitle },
			]);
			this.debounceFunc();
		}
		if (this.state.xAxisName !== prevState.xAxisName) {
			this.formRef.current?.setFields([
				{ name: FormItemName.X_AXIS_NAME, value: this.state.xAxisName },
			]);
			this.debounceFunc();
		}
		if (this.state.yAxisName !== prevState.yAxisName) {
			this.formRef.current?.setFields([
				{ name: FormItemName.Y_AXIS_NAME, value: this.state.yAxisName },
			]);
			this.debounceFunc();
		}

		if (this.state.colorPalette !== prevState.colorPalette) {
			this.debounceFunc();
		}

		if (this.state.chartStackType !== prevState.chartStackType) {
			this.debounceFunc();
		}

		if (
			this.state.selectedParserApi &&
			this.state.selectedParserApi !== prevState.selectedParserApi
		) {
			this.debounceFunc();
		}
		if (
			this.state.chartUpdate &&
			this.state.chartUpdate !== prevState.chartUpdate
		) {
			this.debounceFunc();
		}

		if (this.state.legends !== prevState.legends) {
			this.state.legends.forEach((legend: string, index: number) => {
				this.formRef.current?.setFields([
					{ name: `${FormItemName.LEGENDS}${index}`, value: legend },
				]);
			});
			this.debounceFunc();
		}
	}

	initGraphSettings = () => {
		if (this.props.selectedChart) {
			const chartType = getParserChartTypeFromEchartOption(
				this.props.selectedChart.chartOption
			);
			const chartUpdate = this.props.selectedChart.chartUpdateDuration
				? this.props.selectedChart.chartUpdateDuration
				: ChartUpdate.NONE;
			if (chartType && this.props.selectedParser) {
				const chartStackType = getChartStackTypeFromEchartOption(
					chartType,
					this.props.selectedChart.chartOption
				);
				const chartTitle = getChartTitleFromEchartOption(
					this.props.selectedChart.chartOption
				);
				const { xAxisName, yAxisName } = getAxisNamesFromEchartOption(
					this.props.selectedChart.chartOption
				);
				const colorPalette = getColorsFromEchartOption(
					this.props.selectedChart.chartOption
				);
				const legends = getLegendsFromEchartOption(
					this.props.selectedChart.chartOption
				);
				this.setState({
					...this.state,
					chartTitle,
					xAxisName,
					yAxisName,
					legends,
					colorPalette,
					chartType,
					chartUpdate,
					chartStackType,
					parserApis: this.props.selectedParser.apis.filter((api) =>
						api.charts.includes(chartType)
					),
					selectedParserApi: this.props.selectedChart.parserApi,
				});
			} else {
				this.setState({
					...this.state,
					selectedParserApi: null,
				});
			}
		}
	};

	// let saveEditInputRef = useRef(null);
	// let saveInputRef = useRef(null);

	// const handleClose = (removedTag: any) => {
	//   const tags = settings.tags.filter((tag: any) => tag !== removedTag);

	//   setSettings({ ...settings, tags });
	// };

	// const showInput = () => {
	//   setSettings({ ...settings, inputVisible: true });
	// };

	// const handleInputChange = (e: any) => {
	//   setSettings({ ...settings, inputValue: e.target.value });
	// };

	// const handleInputConfirm = () => {
	//   const { inputValue } = settings;
	//   let { tags } = settings;
	//   if (inputValue && tags.indexOf(inputValue) === -1) {
	//     tags = [...tags, inputValue];
	//   }
	//   setSettings({
	//     ...settings,
	//     tags,
	//     inputVisible: false,
	//     inputValue: "",
	//   });
	// };

	// const handleEditInputChange = (e: any) => {
	//   setSettings({ ...settings, editInputValue: e.target.value });
	// };

	handleEditInputConfirm = () => {
		// setState((tags: any, editInputIndex: any, editInputValue: any) => {
		//   const newTags = [...tags];
		//   newTags[editInputIndex] = editInputValue;
		//   return {
		//     ...settings,
		//     tags: newTags,
		//     editInputIndex: -1,
		//     editInputValue: "",
		//   };
		// });
	};

	// REDUX REDUCERS CALLS
	// const stackType = useSelector((state: any) => state.chartReducer.stackType);
	// const parserApi = useSelector((state: any) => state.chartReducer.parserApi);
	// const chartData = useSelector((state: any) => state.chartReducer.chartData);
	// const chart = useSelector((state: any) => state.chartReducer.chart);

	// const dispatch = useDispatch();

	handleStackTypeChange = (e: any) => {
		const chartStackType = e.target.value as ChartStackType;

		const chartOption = updateChartOnStackType(
			chartStackType,
			this.props.selectedChart.chartOption
		);
		this.props.selectedChart.setChartOption(chartOption);
		this.setState({ ...this.state, chartStackType: chartStackType });
	};

	handleChartUpdateChange = (e: any) => {
		const chartUpdateChange = e.target.value as ChartUpdate;

		this.setState({ ...this.state, chartUpdate: chartUpdateChange }, () => {
			this.onDataSelect(this.state.selectedParserApi?.apiName);
		});
	};

	onDataSelect = (value: string, type?: any) => {
		let selectedParserApi =
			type === 'serverId'
				? this.state.selectedParserApi
				: this.props.selectedParser?.apis.filter(
						(api) => api.apiName === value
				  )[0];
		if (selectedParserApi && this.props.selectedParser) {
			// was changing state
			if (type !== 'serverId' && !this.state.selectedParserApi) {
				this.setState({
					...this.state,
					selectedParserApi,
				});
			}

			let newChartDate: ChartDate | null = null;
			if (this.state.chartUpdate === ChartUpdate.SEVEN_DAYS) {
				newChartDate = {
					to: moment(new Date()).format('YYYY-MM-DD'),
					from: moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'),
				};
			} else if (this.state.chartUpdate === ChartUpdate.THIRTY_DAYS) {
				newChartDate = {
					to: moment(new Date()).format('YYYY-MM-DD'),
					from: moment(new Date()).subtract(30, 'd').format('YYYY-MM-DD'),
				};
			}
			if (this.state.chartType) {
				fetchDataFromParserApi(
					this.props.selectedParser,
					selectedParserApi,
					this.state.chartType,
					newChartDate
				)
					.then((res) => {
						const responseData = res.data;
						const { data, labels } = responseData;
						console.log(data);
						this.props.selectedChart.setParserApi(selectedParserApi);
						this.props.selectedChart.setChartDate(this.state.chartUpdate);
						let chartOption: any;
						if (data && labels) {
							chartOption = parseChartDataFromResponse(
								this.props.selectedChart.chartOption,
								labels,
								data
							);

							//?NOTE: the following code snippet is to enforce legends update
							this.setState((prevState) => ({
								...prevState,
								legends: chartOption?.legend?.data || [],
							}));
						}
						if (this.state.chartStackType) {
							chartOption = updateChartOnStackType(
								this.state.chartStackType,
								chartOption
							);
						}
						if (this.state.chartTitle) {
							chartOption = updateChartTitle(
								this.state.chartTitle,
								chartOption
							);
						}
						if (this.state.xAxisName) {
							chartOption = updateXAxisName(this.state.xAxisName, chartOption);
						}
						if (this.state.yAxisName) {
							chartOption = updateYAxisName(this.state.yAxisName, chartOption);
						}
						if (this.state.colorPalette) {
							chartOption = updateChartColorPalette(
								this.state.colorPalette,
								chartOption
							);
						}
						if (Array.isArray(this.state.legends) && this.state.legends[0]) {
							chartOption = updateLegends(this.state.legends, chartOption);
						}

						this.props.selectedChart.setChartOption(chartOption);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}
	};

	handlePlaceholderChange = (e: any) => {
		console.log(e.target.value);
		this.setState(
			{
				selectedParserApi: {
					...this.state.selectedParserApi,
					defaultValue: e.target.value,
				},
			},
			() => {
				this.onDataSelect(this.state.selectedParserApi?.apiName, 'serverId');
			}
		);
	};

	handleChartTitleChange = (e: any) => {
		const chartTitle = e.target.value;
		const chartOption = updateChartTitle(
			chartTitle,
			this.props.selectedChart.chartOption
		);
		this.props.selectedChart.setChartOption(chartOption);
		this.setState({ chartTitle });
	};

	handleXAxisNameChange = (e: any) => {
		const xAxisName = e.target.value;
		const chartOption = updateXAxisName(
			xAxisName,
			this.props.selectedChart.chartOption
		);
		this.props.selectedChart.setChartOption(chartOption);
		this.setState({ xAxisName });
	};

	handleYAxisNameChange = (e: any) => {
		const yAxisName = e.target.value;
		const chartOption = updateYAxisName(
			yAxisName,
			this.props.selectedChart.chartOption
		);
		this.props.selectedChart.setChartOption(chartOption);
		this.setState({ yAxisName });
	};

	handleColorMenuClick = (e: any) => {
		const { key } = e;
		const colorPalette = COLOR_SET[key];
		let newColorPalette = [...colorPalette, ...colorPalette];

		const chartOption = updateChartColorPalette(
			newColorPalette.slice(this.state.colorIndex),
			this.props.selectedChart.chartOption
		);

		this.props.selectedChart.setChartOption(chartOption);
		this.setState({ colorPalette });
	};

	handleLegendChange = (legendName: string, index: number) => {
		let legends = [...this.state.legends];
		legends[index] = legendName;
		let chartOption = updateLegends(
			legends,
			this.props.selectedChart.chartOption
		);
		this.props.selectedChart.setChartOption(chartOption);
		this.setState({ legends });
	};

	render() {
		const {
			chartTitle,
			xAxisName,
			yAxisName,
			chartType,
			chartUpdate,
			chartStackType,
			parserApis,
			legends,
			inputVisible,
			inputValue,
			editInputIndex,
			editInputValue,
			placeholderInput,
		} = this.state;

		const { selectedChart } = this.props;
		return (
			<div className='graphs_wrapper'>
				<Form ref={this.formRef} name='graphSettings' {...tailLayout}>
					<Row>
						<Col span={24}>
							<Form.Item
								label='Title'
								name={FormItemName.CHART_TITLE}
								initialValue={chartTitle}>
								<Input
									placeholder='Title'
									onChange={this.handleChartTitleChange}
								/>
							</Form.Item>
							<Form.Item>
								<Radio.Group
									onChange={this.handleStackTypeChange}
									value={chartStackType}>
									{chartType === ChartType.BAR_CHART && (
										<>
											<Radio value={ChartStackType.STACKED}>Stacked</Radio>
											<Radio value={ChartStackType.NON_STACKED}>
												Not stacked
											</Radio>
										</>
									)}

									{chartType === ChartType.LINE_CHART && (
										<>
											<Radio value={ChartStackType.FILL}>Fill</Radio>
											<Radio value={ChartStackType.NON_FILL}>Not Fill</Radio>
										</>
									)}

									{(chartType === ChartType.PIE_CHART ||
										chartType === ChartType.DOUGHNUT_CHART) && (
										<>
											<Radio value={ChartStackType.PIE}>Pie</Radio>
											<Radio value={ChartStackType.DOUGHNUT}>Doughnut</Radio>
										</>
									)}
								</Radio.Group>
							</Form.Item>

							<Form.Item label='Select Data'>
								<Select
									placeholder='Select data'
									onSelect={this.onDataSelect}
									value={this.state.selectedParserApi?.apiName}>
									{parserApis &&
										parserApis.map((api) => (
											<Option key={api.apiName} value={api.apiName}>
												{api.apiName}
											</Option>
										))}
								</Select>
							</Form.Item>

							{parserApis &&
								parserApis.map((api: any, index: number) => {
									if (api.apiName === this.state.selectedParserApi?.apiName) {
										if (api.dateRange) {
											return (
												<Form.Item
													label='Update Chart Duration'
													key={index.toString()}>
													<Radio.Group
														onChange={this.handleChartUpdateChange}
														value={chartUpdate}>
														<Radio value={ChartUpdate.SEVEN_DAYS}>7 Days</Radio>
														<Radio value={ChartUpdate.THIRTY_DAYS}>
															30 Days
														</Radio>
														<Radio value={ChartUpdate.NONE}>None</Radio>
													</Radio.Group>
												</Form.Item>
											);
										}
									}
								})}

							{parserApis &&
								parserApis.map((api, index) => {
									if (api.apiName === this.state.selectedParserApi?.apiName) {
										if (api.staticValue) {
											return (
												<Form.Item key={index} label='Value'>
													<Input
														value={this.state.selectedParserApi?.defaultValue}
														placeholder={
															this.state.selectedParserApi?.placeholder
														}
														onChange={this.handlePlaceholderChange}
													/>
												</Form.Item>
											);
										}
									}
								})}

							{chartType !== ChartType.PIE_CHART && (
								<>
									<Form.Item
										label='X-Axis Name'
										name={FormItemName.X_AXIS_NAME}
										initialValue={xAxisName}>
										<Input
											placeholder='X-Axis Name'
											onChange={this.handleXAxisNameChange}
										/>
									</Form.Item>
									<Form.Item
										label='Y-Axis Name'
										name={FormItemName.Y_AXIS_NAME}
										initialValue={yAxisName}>
										<Input
											placeholder='Y-Axis Name'
											onChange={this.handleYAxisNameChange}
										/>
									</Form.Item>
								</>
							)}
							{/* <Form.Item label="Labels" name="labels" className="label_tags">
                  {["X-Label", "Y-Label"].map((label: any, index: Number) => {
                    if (editInputIndex === index) {
                      return (
                        <></>
                        // <Input
                        //   ref={saveEditInputRef}
                        //   key={label}
                        //   size="small"
                        //   className="tag-input"
                        //   value={editInputValue}
                        //   onChange={handleEditInputChange}
                        //   onBlur={handleEditInputConfirm}
                        //   onPressEnter={handleEditInputConfirm}
                        // />
                      );
                    }

                    const isLongTag = label.length > 20;

                    const tagElem = (
                      <Tag
                        className="edit-tag"
                        key={label}
                        closable
                        // onClose={() => handleClose(label)}
                      >
                        <span
                          onDoubleClick={(e) => {
                            // if (index !== 0) {
                            //   setState({
                            //     ...state,
                            //     editInputIndex: index,
                            //     editInputValue: label,
                            //   });
                            //   e.preventDefault();
                            // }
                          }}
                        >
                          {isLongTag ? `${label.slice(0, 20)}...` : label}
                        </span>
                      </Tag>
                    );
                    return isLongTag ? (
                      <Tooltip title={label} key={label}>
                        {tagElem}
                      </Tooltip>
                    ) : (
                      tagElem
                    );
                  })}
                  {/* {inputVisible && (
                    <Input
                      ref={saveInputRef}
                      type="text"
                      size="small"
                      className="tag-input"
                      value={inputValue}
                      onChange={handleInputChange}
                      onBlur={handleInputConfirm}
                      onPressEnter={handleInputConfirm}
                    />
                  )}
                  {!inputVisible && (
                    <Tag className="site-tag-plus" onClick={showInput}>
                      <PlusOutlined /> New Tag
                    </Tag>
                  )}
              </Form.Item> */}
							<Collapse defaultActiveKey={['1']} bordered={false}>
								<Panel header='Legends' key='1'>
									{selectedChart?.chartOption?.series?.map(
										(series: any, index: number) => {
											return (
												<Form.Item
													key={index.toString()}
													name={`${FormItemName.LEGENDS}${index}`}
													initialValue={legends[index]}>
													<Input
														addonBefore={
															<div
																style={{
																	background: selectedChart?.chartOption?.color
																		? selectedChart?.chartOption?.color[index]
																		: ECHARTS_DEFAULT_COLOR_SET[index],
																}}
																className='color__style'></div>
														}
														placeholder='Legend Name'
														onChange={(e: any) => {
															console.log(e.target.value);
															this.handleLegendChange(e.target.value, index);
														}}
													/>
												</Form.Item>
											);
										}
									)}
								</Panel>
							</Collapse>

							<Form.Item className='dropdown__color'>
								<Dropdown
									overlay={
										<Menu onClick={this.handleColorMenuClick}>
											{COLOR_SET_KEYS.map((key: string) => {
												return (
													<Menu.Item key={key} className='d-flex'>
														{COLOR_SET[key].map(
															(colors: string, index: number) => {
																return (
																	<div key={index}>
																		<div
																			onClick={() => {
																				this.state.colorIndex = index;
																			}}
																			style={{ background: colors }}
																			className='color__style'></div>
																	</div>
																);
															}
														)}
													</Menu.Item>
												);
											})}
										</Menu>
									}
									overlayClassName='color__drops'
									trigger={['click']}>
									<a
										className='ant-dropdown-link'
										onClick={(e) => e.preventDefault()}>
										Color
										<DownOutlined />
									</a>
								</Dropdown>
							</Form.Item>
							{/* <Form.Item
                label="Current Dataset"
                name="currentDataset"
                className="dataset__wraps"
              >
                <Input />
                <Dropdown
                  overlay={menu}
                  overlayClassName="color__drops"
                  trigger={["click"]}
                >
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    Color
                    <DownOutlined />
                  </a>
                </Dropdown>
                <Button>
                  <DeleteOutlined />
                </Button>
              </Form.Item>
              <Button> + Add Dataset </Button> */}
						</Col>
					</Row>
				</Form>
			</div>
		);
	}
}

export default GraphSetting;
