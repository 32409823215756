import React from "react";
import { Button } from "antd";
import { ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";

interface PropTypes {
  canvas: any;
  canvasId: any;
  canvasWidth: any;
  canvasHeight: any;
}
// window.addEventListener("wheel", function (event) {
//   const delta = event.deltaY;
//   let zoom = canvas.getZoom();
//   let width = canvas.getWidth();
//   let height = canvas.getHeight();

//   zoom *= 0.999 ** delta;
//   width = width * 0.999 ** delta;
//   height = height * 0.999 ** delta;
//   if (zoom > 20) {
//     width = 800;
//     height = 800;
//     zoom = 20;
//   }
//   if (zoom < 0.01) {
//     width = 800;
//     height = 800;
//     zoom = 0.01;
//   }
//   canvas?.setZoom(zoom);
//   canvas?.setWidth(width);
//   canvas?.setHeight(height);
//   event.stopPropagation();
// });

const SCALE_FACTOR = 1.1;
const ZoomPanel = (props: PropTypes) => {
  const handleZoom = (type: string) => {
    if (type === "zoomIn") {
      props.canvas.map((item: any) => {
        let zoomValue = item.canvasDom.getZoom();

        if (zoomValue > 1.2) {
          zoomValue = 1.2;
        }
        item.canvasDom.setZoom(zoomValue * 1.1);
        item.canvasDom.renderAll();
      });
    } else {
      props.canvas.map((item: any) => {
        let zoomValue = item.canvasDom.getZoom();
        if (zoomValue < 1) {
          zoomValue = 1;
        }
        item.canvasDom.setZoom(zoomValue / 1.1);
        item.canvasDom.renderAll();
      });
    }
  };
  return (
    <>
      <div className="zoom__panel">
        <Button
          onClick={() => {
            handleZoom("zoomIn");
          }}
        >
          <ZoomInOutlined />
        </Button>
        <Button
          onClick={() => {
            handleZoom("zoomOut");
          }}
        >
          <ZoomOutOutlined />
        </Button>
      </div>
    </>
  );
};

export default ZoomPanel;
