import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Popover, Table } from 'antd';
import {
	MoreOutlined,
	DeleteOutlined,
	CopyOutlined,
	BoxPlotOutlined,
	EditOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Parser } from '../../../../core/interfaces/parser.interface';

const content = (id: any, deleteProject: any) => {
	return (
		<div>
			<Button icon={<EditOutlined />}>Edit</Button>
			<Button icon={<CopyOutlined />}>Duplicate</Button>
			<Button icon={<BoxPlotOutlined />}>Archrive</Button>
			<Button
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					deleteProject(id);
				}}
				icon={<DeleteOutlined />}>
				Delete
			</Button>
		</div>
	);
};
interface ITemplatesTable {
	templates: any;
	parserList: Array<Parser>;
	// fetchMoreData: Function;
	hasMore: boolean;
	paginationLoader: boolean;
	page: number;
	setPage: Function;
	deleteProject: Function;
}
const TemplatesTable: React.FC<ITemplatesTable> = ({
	templates,
	parserList,
	deleteProject,
	hasMore,
	paginationLoader,
	page,
	setPage,
}) => {
	let history = useHistory();
	const columns = [
		{
			title: 'Recent Documents',
			dataIndex: 'title',
			key: 'title',
			render: (projectTitle: any) => (
				<>
					<div className='d-flex align-items-center'>
						<img
							src='../../assets/images/paper.svg'
							className='img-fluid'
							style={{ marginRight: 10 }}
							alt='file'
						/>
						<span className='project__name pl-2'>{projectTitle}</span>
					</div>
				</>
			),
		},
		{
			title: 'Parser Type',
			dataIndex: 'parserId',
			key: 'parserId',
			render: (parserId: string) => <span>{getParserName(parserId)}</span>,
		},
		{
			title: 'Date Modified',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			render: (updatedAt: string) => (
				<span>{moment(updatedAt).format('DD MMMM YYYY hh:mm A')}</span>
			),
		},
		{
			title: '',
			key: '_id',
			dataIndex: '_id',
			render: (_id: string) => (
				<span className='action'>
					<Popover placement='top' content={() => content(_id, deleteProject)}>
						<Button>
							<MoreOutlined />
						</Button>
					</Popover>
				</span>
			),
		},
	];

	const getParserName = (parserId: string) => {
		return parserId && parserList
			? parserList[parserList.findIndex((parser) => parser.id === parserId)]
					.parserName
			: 'N/A';
	};

	return (
		<div
			id='scrollableDiv'
			className='templates__table'
			style={{
				height: 300,
				overflow: 'auto',
				// display: "flex",
				// flexDirection: "column-reverse",
			}}>
			<InfiniteScroll
				dataLength={templates.length}
				next={() => {
					setPage(page + 1);
				}}
				hasMore={hasMore}
				loader={paginationLoader && <h4>Loading...</h4>}
				scrollableTarget='scrollableDiv'
				endMessage={
					<p style={{ textAlign: 'center' }}>
						<b>Yay! You have seen it all</b>
					</p>
				}>
				<Table
					columns={columns}
					dataSource={templates}
					pagination={false}
					style={{
						cursor: 'pointer',
					}}
					rowKey='_id'
					onRow={(record: any, rowIndex) => {
						return {
							onClick: (event) => {
								history.push(`/canvas/${record._id}`);
							},
						};
					}}
				/>
			</InfiniteScroll>
		</div>
	);
};

export default TemplatesTable;
