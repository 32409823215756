import React from "react";
import { Tooltip, Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";

interface PropTypes {
  canvas: any;
  canvasId: any;
}
//   const IconFont = createFromIconfontCN({
//     scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
//   });
const Clone = (props: PropTypes) => {
  return (
    <div className="delte__show br">
      <Tooltip placement="bottomLeft" title="Clone">
        <Button
          className="menu-btn"
          onClick={() => {
            props.canvas.map((item: any) => {
              if (item.canvasDom.getActiveObject()) {
                item.canvasDom.getActiveObject().clone(function (obj: any) {
                  if (obj) {
                    obj.set({
                      left: obj.left + 10,
                      top: obj.top + 10,
                    });
                    item.canvasDom.add(obj);
                    item.canvasDom.renderAll();
                  }
                });
              }
            });
          }}
        >
          <CopyOutlined />
        </Button>
      </Tooltip>
    </div>
  );
};

export default Clone;
