import React from 'react';
import './App.less';
import { Provider } from 'react-redux';

import Router from './router/Router';
import store from './core/store/store';

function App() {
	return (
		<Provider store={store}>
			<Router isAuthenticated={false} />
		</Provider>
	);
}

export default App;
