import React from "react";
import { Radio } from "antd";
interface PropTypes {
  canvas: any;
  canvasId: any;

  selectedObject: any;
  lineType: string;
  setLineType: Function;
}

const ShapesSettings = (props: PropTypes) => {
  return (
    <div className="text__style__wrapper">
      <Radio.Group
        value={props.lineType}
        onChange={(e) => {
          if (e.target.value === "normalLine") {
            props.canvas.map((item: any) => {
              if (
                item.canvasDom.getActiveObject() &&
                item.canvasDom.getActiveObject().type === "line"
              ) {
                item.canvasDom.getActiveObject().set({
                  strokeDashArray: [0, 0],
                });
                item.canvasDom.renderAll();
              }
            });
            props.setLineType("normalLine");
          }

          if (e.target.value === "dashedLine") {
            props.canvas.map((item: any) => {
              if (
                item.canvasDom.getActiveObject() &&
                item.canvasDom.getActiveObject().type === "line"
              ) {
                item.canvasDom.getActiveObject().set({
                  strokeDashArray: [5, 5],
                });
                item.canvasDom.renderAll();
              }
            });
            props.setLineType("dashedLine");
          }
        }}
      >
        <Radio value="normalLine">Line</Radio>
        <Radio value="dashedLine">Dashed Line</Radio>
      </Radio.Group>
    </div>
  );
};

export default ShapesSettings;
