import React, { FC } from "react";
import { Row, Col, Checkbox, Slider, Typography } from "antd";
import { fabric } from "fabric";

interface IFilterSettings {
  canvas: any;
  canvasId: any;
  selectedObject: any;
  imageFilterChecked: any;
  brightnessSlider: any;
  contrastSlider: any;
  setCheckedImageFilter: Function;
  setBrightnessSlider: Function;
  setContrastSlider: Function;
}
const FilterSettings: FC<IFilterSettings> = ({
  canvas,
  canvasId,
  selectedObject,
  imageFilterChecked,
  brightnessSlider,
  contrastSlider,
  setCheckedImageFilter,
  setBrightnessSlider,
  setContrastSlider,
}) => {
  return (
    <div>
      <Checkbox.Group
        value={imageFilterChecked}
        onChange={(checkedValue: any) => {
          setCheckedImageFilter(checkedValue);

          // if (!checkedValue.includes("Grayscale")) {
          //   canvas.map((item: any) => {
          //     item.canvasDom
          //       .getActiveObject()
          //       .filters.push(new fabric.Image.filters.Grayscale());
          //     item.canvasDom.getActiveObject().applyFilters();
          //     item.canvasDom.renderAll();
          //   });
          // } else {
          //   canvas.map((item: any) => {
          //     item.canvasDom.getActiveObject().filters = [];
          //     item.canvasDom.getActiveObject().applyFilters();
          //     item.canvasDom.renderAll();
          //   });
          // }

          // if (!checkedValue.includes("Invert")) {
          //   canvas.map((item: any) => {
          //     item.canvasDom
          //       .getActiveObject()
          //       .filters.push(new fabric.Image.filters.Invert());
          //     item.canvasDom.getActiveObject().applyFilters();
          //     item.canvasDom.renderAll();
          //   });
          // } else {
          //   canvas.map((item: any) => {
          //     item.canvasDom.getActiveObject().filters = [];
          //     item.canvasDom.getActiveObject().applyFilters();
          //     item.canvasDom.renderAll();
          //   });
          // }
          // if (!checkedValue.includes("Sepia")) {
          //   canvas.map((item: any) => {
          //     item.canvasDom
          //       .getActiveObject()
          //       .filters.push(new fabric.Image.filters.Sepia());
          //     item.canvasDom.getActiveObject().applyFilters();
          //     item.canvasDom.renderAll();
          //   });
          // } else {
          //   canvas.map((item: any) => {
          //     item.canvasDom.getActiveObject().filters = [];
          //     item.canvasDom.getActiveObject().applyFilters();
          //     item.canvasDom.renderAll();
          //   });
          // }
        }}
        style={{ width: "100%" }}
      >
        <Row gutter={[16, 16]}>
          <Col flex={1} span={8}>
            <Checkbox
              value="Grayscale"
              onClick={() => {
                canvas.map((item: any) => {
                  if (
                    item.canvasDom.getActiveObject() &&
                    item.canvasDom.getActiveObject().type === "image"
                  ) {
                    if (!imageFilterChecked.includes("Grayscale")) {
                      item.canvasDom
                        .getActiveObject()
                        .filters.push(new fabric.Image.filters.Grayscale());
                      item.canvasDom.getActiveObject().applyFilters();
                    } else {
                      const newFilter = item.canvasDom
                        .getActiveObject()
                        .filters.filter((item: any) => {
                          return item.__proto__.type !== "Grayscale";
                        });
                      item.canvasDom.getActiveObject().filters = newFilter;
                      item.canvasDom.getActiveObject().applyFilters();
                    }

                    item.canvasDom.renderAll();
                  }
                });
              }}
            >
              Grayscale
            </Checkbox>
          </Col>
          <Col flex={1} span={8}>
            <Checkbox
              value="Invert"
              onClick={() => {
                canvas.map((item: any) => {
                  if (
                    item.canvasDom.getActiveObject() &&
                    item.canvasDom.getActiveObject().type === "image"
                  ) {
                    if (!imageFilterChecked.includes("Invert")) {
                      item.canvasDom
                        .getActiveObject()
                        .filters.push(new fabric.Image.filters.Invert());
                      item.canvasDom.getActiveObject().applyFilters();
                    } else {
                      const newFilter = item.canvasDom
                        .getActiveObject()
                        .filters.filter((item: any) => {
                          return item.__proto__.type !== "Invert";
                        });
                      item.canvasDom.getActiveObject().filters = newFilter;
                      item.canvasDom.getActiveObject().applyFilters();
                    }
                    item.canvasDom.renderAll();
                  }
                });
              }}
            >
              Invert
            </Checkbox>
          </Col>
          <Col flex={1} span={8}>
            <Checkbox
              value="Sepia"
              onClick={() => {
                canvas.map((item: any) => {
                  if (
                    item.canvasDom.getActiveObject() &&
                    item.canvasDom.getActiveObject().type === "image"
                  ) {
                    if (!imageFilterChecked.includes("Sepia")) {
                      item.canvasDom
                        .getActiveObject()
                        .filters.push(new fabric.Image.filters.Sepia());
                      item.canvasDom.getActiveObject().applyFilters();
                    } else {
                      const newFilter = item.canvasDom
                        .getActiveObject()
                        .filters.filter((item: any) => {
                          return item.__proto__.type !== "Sepia";
                        });
                      item.canvasDom.getActiveObject().filters = newFilter;
                      item.canvasDom.getActiveObject().applyFilters();
                    }
                    item.canvasDom.renderAll();
                  }
                });
              }}
            >
              Sepia
            </Checkbox>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Checkbox
              value="BlendColor"
              onClick={() => {
                canvas.map((item: any) => {
                  if (
                    item.canvasDom.getActiveObject() &&
                    item.canvasDom.getActiveObject().type === "image"
                  ) {
                    if (!imageFilterChecked.includes("BlendColor")) {
                      item.canvasDom.getActiveObject().filters.push(
                        new fabric.Image.filters.BlendColor({
                          mode: "add",
                          color: "red",
                        })
                      );
                      item.canvasDom.getActiveObject().applyFilters();
                    } else {
                      const newFilter = item.canvasDom
                        .getActiveObject()
                        .filters.filter((item: any) => {
                          return item.__proto__.type !== "BlendColor";
                        });
                      item.canvasDom.getActiveObject().filters = newFilter;
                      item.canvasDom.getActiveObject().applyFilters();
                    }
                    item.canvasDom.renderAll();
                  }
                });
              }}
            >
              Blend Color
            </Checkbox>
          </Col>
        </Row>
        <Row gutter={[12, 12]} style={{ marginTop: 10 }}>
          <Col span={8}>
            <Checkbox
              value="Brightness"
              onClick={() => {
                setBrightnessSlider({
                  ...brightnessSlider,
                  disabled: !brightnessSlider.disabled,
                });

                canvas.map((item: any) => {
                  if (
                    item.canvasDom.getActiveObject() &&
                    item.canvasDom.getActiveObject().type === "image"
                  ) {
                    if (!imageFilterChecked.includes("Brightness")) {
                      item.canvasDom.getActiveObject().filters.push(
                        new fabric.Image.filters.Brightness({
                          brightness: brightnessSlider.value,
                        })
                      );

                      item.canvasDom.getActiveObject().applyFilters();
                    } else {
                      const newFilter = item.canvasDom
                        .getActiveObject()
                        .filters.filter((item: any) => {
                          return item.__proto__.type !== "Brightness";
                        });
                      item.canvasDom.getActiveObject().filters = newFilter;
                      item.canvasDom.getActiveObject().applyFilters();
                      setBrightnessSlider({
                        ...brightnessSlider,
                        value: 0,
                      });
                    }
                    item.canvasDom.renderAll();
                  }
                });
              }}
            >
              <Typography>Brightness</Typography>
            </Checkbox>
          </Col>
          <Col span={8}>
            <Slider
              min={0}
              max={1}
              value={brightnessSlider.value}
              step={0.01}
              disabled={brightnessSlider.disabled}
              onChange={(value: number) => {
                setBrightnessSlider({
                  ...brightnessSlider,
                  value,
                });
                canvas.map((item: any) => {
                  item.canvasDom.getActiveObject() &&
                    item.canvasDom
                      .getActiveObject()
                      .filters.map((item: any, index: number) => {
                        item.brightness = +value;
                      });
                  item.canvasDom.getActiveObject() &&
                    item.canvasDom.getActiveObject().applyFilters();
                  item.canvasDom.renderAll();
                });
              }}
            />
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={8}>
            <Checkbox
              value="Contrast"
              onClick={() => {
                setContrastSlider({
                  ...contrastSlider,
                  disabled: !contrastSlider.disabled,
                });
                canvas.map((item: any) => {
                  if (
                    item.canvasDom.getActiveObject() &&
                    item.canvasDom.getActiveObject().type === "image"
                  ) {
                    if (!imageFilterChecked.includes("Contrast")) {
                      item.canvasDom.getActiveObject().filters.push(
                        new fabric.Image.filters.Contrast({
                          contrast: contrastSlider.value,
                        })
                      );
                      item.canvasDom.getActiveObject() &&
                        item.canvasDom.getActiveObject().applyFilters();
                    } else {
                      const newFilter = item.canvasDom
                        .getActiveObject()
                        .filters.filter((item: any) => {
                          return item.__proto__.type !== "Contrast";
                        });
                      item.canvasDom.getActiveObject().filters = newFilter;
                      item.canvasDom.getActiveObject().applyFilters();
                      setContrastSlider({
                        ...contrastSlider,
                        value: 0,
                      });
                    }
                    item.canvasDom.renderAll();
                  }
                });
              }}
            >
              <div className="text_style_wrap">
                <Typography>Contrast</Typography>
              </div>
            </Checkbox>
          </Col>
          <Col span={8}>
            <Slider
              min={0}
              max={1}
              value={contrastSlider.value}
              step={0.01}
              disabled={contrastSlider.disabled}
              onChange={(value: number) => {
                setContrastSlider({
                  ...contrastSlider,
                  value,
                });
                canvas.map((item: any) => {
                  item.canvasDom.getActiveObject() &&
                    item.canvasDom
                      .getActiveObject()
                      .filters.map((item: any, index: number) => {
                        item.contrast = +value;
                      });
                  item.canvasDom.getActiveObject() &&
                    item.canvasDom.getActiveObject().applyFilters();
                  item.canvasDom.renderAll();
                });
              }}
            />
          </Col>
        </Row>
      </Checkbox.Group>
    </div>
  );
};

export default FilterSettings;
