import { fabric } from "fabric";
import * as echarts from "echarts";

const Chart = fabric.util.createClass(fabric.Rect, {
  type: "chart",
  superType: "element",
  hasRotatingPoint: false,
  initialize(chartOption: any, options: any) {
    options = options || {};
    this.callSuper("initialize", options);
    this.set({
      chartOption,
      fill: "rgba(255, 255, 255, 0)",
      stroke: "rgba(255, 255, 255, 0)",
    });
    this.setControlsVisibility({ mtr: false });
  },
  setSource(source: any) {
    if (typeof source === "string") {
      this.setChartOptionStr(source);
    } else {
      this.setChartOption(source);
    }
  },
  setChartOptionStr(chartOptionStr: any) {
    this.set({
      chartOptionStr,
    });
  },
  setParserApi(parserApi: any) {
    this.set({
      parserApi,
    });
  },
  setChartDate(chartUpdateDuration: any) {
    this.set({
      chartUpdateDuration,
    });
  },
  setChartOption(chartOption: any) {
    this.set({
      chartOption,
    });
    this.distroyChart();
    this.createChart(chartOption);
  },
  createChart(chartOption: any) {
    // Create runs from here
    this.instance = echarts.init(this.element, "", { renderer: "svg" });
    if (!chartOption) {
      this.instance.setOption({
        xAxis: {},
        yAxis: {},
        series: [
          {
            type: "line",
            data: [
              [0, 1],
              [1, 2],
              [2, 3],
              [3, 10],
            ],
          },
        ],
      });
    } else {
      this.instance.setOption(chartOption);
    }
  },
  distroyChart() {
    if (this.instance) {
      this.instance.dispose();
    }
  },
  toObject(propertiesToInclude: any) {
    return toObject(this, propertiesToInclude, {
      chartDate: this.get("chartUpdateDuration"),
      parserApi: this.get("parserApi"),
      chartOption: this.get("chartOption"),
      container: this.get("container"),
      editable: this.get("editable"),
    });
  },
  _render(ctx: any) {
    this.callSuper("_render", ctx);
    if (!this.instance) {
      const {
        canvasId,
        id,
        scaleX,
        scaleY,
        width,
        height,
        angle,
        editable,
        chartOption,
      } = this;
      const zoom = this.canvas.getZoom();
      const left = this.calcCoords().tl.x + 10;
      const top = this.calcCoords().tl.y + 50;
      const padLeft = (width * scaleX * zoom - width) / 2;
      const padTop = (height * scaleY * zoom - height) / 2;
      this.element = fabric.util.makeElement("div", {
        id: `${id}_container`,
        style: `transform: rotate(${angle}deg) scale(${scaleX * zoom}, 
          ${scaleY * zoom});
          width: ${width}px;
          height: ${height}px;
          left: ${left + padLeft}px;
          top: ${top + padTop}px;
          position: absolute;
          user-select: ${editable ? "none" : "auto"};
          pointer-events: ${editable ? "none" : "auto"};`,
      });
      this.createChart(chartOption);
      const container = document.getElementById(`frame_${canvasId}`);
      container && container.appendChild(this.element);
    }
  },
});

const toObject = (obj: any, propertiesToInclude: any, properties: any) =>
  fabric.util.object.extend(
    obj.callSuper("toObject"),
    propertiesToInclude.reduce(
      (prev: any, property: any) =>
        Object.assign(prev, {
          [property]: obj.get(property),
        }),
      Object.assign({}, properties)
    )
  );

export default Chart;
