import React, { useState, useEffect } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { Button, Dropdown, Menu } from "antd";
import { Parser } from "../../../core/interfaces/parser.interface";
import { CloudSyncOutlined, CloudOutlined } from "@ant-design/icons";

interface IProps {
  selectedParser: Parser | null;
  isFetching: boolean;
  showModal: Function;
}

const TextNavbar: React.FC<IProps> = ({
  selectedParser,
  isFetching,
  showModal,
}) => {
  const [parser, setParser] = useState<Parser>();

  useEffect(() => {
    if (selectedParser) {
      setParser(selectedParser);
    }
  }, [selectedParser]);

  const insertItems = (
    <Menu>
      <Menu.Item key="0">
        <a onClick={(e) => e.preventDefault()}>Text</a>
      </Menu.Item>
      <Menu.Item key="1">
        <a onClick={(e) => e.preventDefault()}>Gallery</a>
      </Menu.Item>
      <Menu.Item key="2">
        <a onClick={(e) => e.preventDefault()}>Piechart</a>
      </Menu.Item>
      <Menu.Item key="3">
        <a onClick={(e) => e.preventDefault()}>Barchart</a>
      </Menu.Item>
      <Menu.Item key="4">
        <a onClick={(e) => e.preventDefault()}>Linechart</a>
      </Menu.Item>
      <Menu.Item key="5">
        <a onClick={(e) => e.preventDefault()}>Horizontal Line</a>
      </Menu.Item>
    </Menu>
  );

  const helpItems = (
    <Menu>
      <Menu.Item key="0">
        <a onClick={(e) => e.preventDefault()}>About Us</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="text__menubar d-flex align-center">
      <div className="menubar__right">
        <Nav className="d-flex flex-row" navbar>
          <NavItem>
            <NavLink href="/">
              <img
                src="../../assets/images/menubar/home.svg"
                className="img-fluid"
                alt="home"
              />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#">File</NavLink>
          </NavItem>
          <NavItem>
            <Dropdown overlay={insertItems} trigger={["click"]}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Insert
              </a>
            </Dropdown>
          </NavItem>

          <NavItem>
            <NavLink href="#">View</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#">Template</NavLink>
          </NavItem>
          <NavItem>
            <Dropdown overlay={helpItems} trigger={["click"]}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Help
              </a>
            </Dropdown>
          </NavItem>
          <NavItem>
            <div style={{ display: "flex", alignItems: "center" }}>
              {isFetching ? (
                <>
                  <CloudSyncOutlined />
                  <span className="ant-dropdown-link txt-sm">Saving...</span>
                </>
              ) : (
                <>
                  <CloudOutlined />
                  <span className="ant-dropdown-link txt-sm">
                    Saved to Cloud
                  </span>
                </>
              )}
            </div>
            {/* <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
             
            </a> */}
          </NavItem>
        </Nav>
      </div>
      <div className="menubar__left ml-auto d-flex align-center">
        {parser && (
          <div className="project__type d-flex align-center">
            <p>Parser:</p>
            <span>{parser.parserName}</span>
          </div>
        )}
        <div className="share__reports">
          <Button
            className="btn-custom btn-share"
            onClick={() => {
              showModal();
            }}
          >
            <span>
              <img
                src="../../assets/images/menubar/share.svg"
                className="img-fluid"
                alt="Share"
              />
            </span>
            <p>Share</p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TextNavbar;
