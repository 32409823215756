import axios from 'axios';
import moment from 'moment';
import { config } from '../../config/config';
import { ChartType, ChartUpdate } from '../enums/enum';
import { ChartDate, Parser, ParserApi } from '../interfaces/parser.interface';

const parserUrl = `${config.cmsURL}/parser`;

export const getParser = (): Promise<any> => {
	return axios.get(parserUrl);
};

export const fetchDataFromParserApi = (
	selectedParser: Parser,
	parserApi: ParserApi,
	chartType: ChartType,
	chartDate?: ChartDate | null
): Promise<any> => {
	console.log('fetch data');
	if (chartType === ChartType.PIE_CHART) {
		chartType = ChartType.DOUGHNUT_CHART;
	}
	return axios.post(
		`${
			config.parserURL
		}/${selectedParser.parserName.toLowerCase()}/${chartType}`,
		buildData(selectedParser, parserApi, chartDate ? chartDate : null)
	);
};

const buildData = (
	parser: Parser,
	parserApi: ParserApi,
	chartDate: ChartDate | null
) => {
	let data = {};
	console.log(data, 'hello');
	switch (parser.parserName.toLowerCase()) {
		case 'kuo':
			data = {
				api: parserApi.defaultValue
					? `${parserApi.api}?ip=${parserApi.defaultValue}`
					: `${parserApi.api}`,
				endPoint: parserApi.apiName,
			};
			console.log(data);

			break;
		case 'sales_reports':
			data = {
				api: parserApi.api,
				token: parser.server[0].header,
				endPoint: parserApi.apiName,
			};
			break;
		case 'eklogs':
			data = {
				api: parser.server[0].host,
				token: parser.server[0].header,
				endPoint: parserApi.api,
				staticData: parserApi.defaultValue,
			};
			break;
		case 'bloom':
			data = {
				api: parser.server[0].host,
				token: parser.server[0].header,
				endPoint: parserApi.api,
				name: parserApi.apiName,
			};
			break;
	}

	if (parserApi.dateRange && chartDate) {
		data = {
			...data,
			...chartDate,
		};
	}

	return data;
};

export const fetchDataFromParserApiForChart = (
	selectedParser: Parser,
	parserApi: ParserApi,
	chartType: ChartType,
	placeholder: any
): Promise<any> => {
	if (chartType === ChartType.PIE_CHART) {
		chartType = ChartType.DOUGHNUT_CHART;
		console.log('i am here');
	}
	return axios.post(
		`${
			config.parserURL
		}/${selectedParser.parserName.toLowerCase()}/${chartType}`,
		buildDataGraph(selectedParser, parserApi, placeholder)
	);
};

const buildDataGraph = (
	parser: Parser,
	parserApi: ParserApi,
	placeholder?: any
) => {
	let data = {};
	switch (parser.parserName.toLowerCase()) {
		case 'kuo':
			data = {
				api: placeholder
					? `${parserApi.api}?ip=${placeholder}`
					: `${parserApi.api}`,
				endPoint: parserApi.apiName,
			};
			break;
		case 'sales_reports':
			data = {
				api: parserApi.api,
				token: parser.server[0].header,
				endPoint: parserApi.apiName,
			};
			break;
		case 'eklogs':
			data = {
				api: parser.server[0].host,
				token: parser.server[0].header,
				endPoint: parserApi.api,
				staticData: parserApi.defaultValue,
			};
			break;
		case 'bloom':
			data = {
				api: parser.server[0].host,
				token: parser.server[0].header,
				endPoint: parserApi.api,
				name: parserApi.apiName,
			};
			break;
	}

	if (parserApi.dateRange) {
		data = {
			...data,
			// to: "2020-09-01",
			// from: "2020-08-30",
		};
	}

	return data;
};
