import React from "react";
import { Table, Tag } from "antd";
import { getShares } from "../../core/services/share.service";
import { useSelector } from "react-redux";
import moment from "moment";
const Shares = () => {
  const [shares, setShares] = React.useState([]);
  const { id } = useSelector((state: any) => state.auth.auth);

  React.useEffect(() => {
    getShares(id)
      .then((res) => {
        const shares = res.data.shares.map((item: any) => {
          const { _id, sendDate, updatedAt, email } = item;
          return {
            // key: _id,

            email: email.map((item: any) => item.address),
            sendDate,
            updatedAt: moment(updatedAt).format("Do MMMM YYYY"),
          };
        });
        setShares(shares);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email: string[]) => (
        <>
          {email.map((item: any, index: number) => {
            return (
              <>
                <Tag color="geekblue" key={index}>
                  {item}
                </Tag>
              </>
            );
          })}
        </>
      ),
    },
    {
      title: "Send Date",
      dataIndex: "sendDate",
      key: "sendDate",
    },
    {
      title: "Send At",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
  ];

  return (
    <div
      id="scrollableDiv"
      className="templates__table"
      style={{
        height: 300,
        overflow: "auto",
      }}
    >
      <h4>Shared Files</h4>
      <Table pagination={false} dataSource={shares} columns={columns} />;
    </div>
  );
};

export default Shares;
