import axios from "axios";
import { config } from "../../config/config";

const loginUrl = `${config.cmsURL}/auth/user/login`;

export const login = (username: string, password: string): Promise<any> => {
  return axios.post(loginUrl, {
    username,
    password,
  });
};
