import { IGraphSettings } from '../../interfaces/graphSettings.interface';
import { ParserApi } from '../../interfaces/parser.interface';
import {
	GRAPH_SETTINGS_PARSER_API_CHANGE,
	GRAPH_SETTINGS_UPDATE_CHART_ON_CANVAS,
	GRAPH_SETTINGS_INITIALIZE,
	GRAPH_SETTINGS_RESET_UPDATE_CHART_ON_CANVAS,
} from '../actions/types';

const initialState: IGraphSettings = {
	parserApi: null,
	chart: null,
	updateChartOnCanvas: false,
};

export default (
	state: IGraphSettings = initialState,
	action: any
): IGraphSettings => {
	switch (action.type) {
		//
		case GRAPH_SETTINGS_INITIALIZE:
			console.log(action.payload, 'graph');
			return {
				...state,
				chart: action.payload,
				updateChartOnCanvas: false,
			};
		case GRAPH_SETTINGS_UPDATE_CHART_ON_CANVAS:
			return {
				...state,
				chart: action.payload,
				updateChartOnCanvas: true,
			};
		case GRAPH_SETTINGS_PARSER_API_CHANGE:
			return {
				...state,
				parserApi: action.payload,
			};
		case GRAPH_SETTINGS_RESET_UPDATE_CHART_ON_CANVAS:
			return {
				...state,
				updateChartOnCanvas: false,
			};
		default:
			return state;
	}
};
