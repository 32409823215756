import { ChartStackType, ChartType } from '../core/enums/enum';

export const getChartStackTypeFromEchartOption = (
	chartType: ChartType,
	chartOption: any
): ChartStackType | null => {
	let chartStackType = null;
	switch (chartType) {
		case ChartType.LINE_CHART:
			if ('areaStyle' in chartOption.series[0]) {
				chartStackType = ChartStackType.FILL;
			} else {
				chartStackType = ChartStackType.NON_FILL;
			}
			break;
		case ChartType.BAR_CHART:
			if (
				chartOption.series[0]?.stack &&
				chartOption.series[0]?.stack === 'total'
			) {
				chartStackType = ChartStackType.STACKED;
			} else {
				chartStackType = ChartStackType.NON_STACKED;
			}
			break;
		case ChartType.PIE_CHART:
			if (chartOption.series[0]?.radius) {
				chartStackType = ChartStackType.DOUGHNUT;
			} else {
				chartStackType = ChartStackType.PIE;
			}
			break;
	}
	return chartStackType;
};
export const getParserChartTypeFromEchartOption = (
	chartOption: any
): ChartType | null => {
	let chartType = null;
	if (chartOption) {
		const echartType = chartOption.series[0].type;
		switch (echartType) {
			case 'bar':
				chartType = ChartType.BAR_CHART;
				break;
			case 'line':
				chartType = ChartType.LINE_CHART;
				break;
			case 'pie':
				chartType = ChartType.PIE_CHART;
				break;
		}
	}
	return chartType;
};

export const updateChartOnStackType = (
	chartStackType: ChartStackType,
	chartOption: any
): any => {
	switch (chartStackType) {
		case ChartStackType.STACKED:
			chartOption.series.forEach((series: any) => {
				series.stack = 'total';
			});
			break;
		case ChartStackType.NON_STACKED:
			chartOption.series.forEach((series: any) => {
				delete series.stack;
			});
			break;
		case ChartStackType.FILL:
			chartOption.series.forEach((series: any) => {
				series.areaStyle = {};
			});
			break;
		case ChartStackType.NON_FILL:
			chartOption.series.forEach((series: any) => {
				delete series.areaStyle;
			});
			break;
		case ChartStackType.PIE:
			chartOption.series.forEach((series: any) => {
				delete series?.radius;
			});
			break;
		case ChartStackType.DOUGHNUT:
			chartOption.series.forEach((series: any, index: number) => {
				switch (index) {
					case 0:
						series.radius = ['40%', '70%'];
						break;
					case 1:
						series.radius = ['10%', '30%'];
						break;
				}
			});
			break;
	}
	return chartOption;
};

export const parseChartDataFromResponse = (
	chartOption: any,
	labels: any,
	data: any
) => {
	const chartType = chartOption.series[0].type;
	switch (chartType) {
		case 'line':
			return {
				xAxis: {
					type: 'category',
					data: labels,
				},
				yAxis: {
					type: 'value',
				},
				series: data.map((item: any) => ({
					data: item.data,
					type: chartOption.series[0].type,
					symbol: 'none',
					smooth: true,
					lineStyle: {
						width: 1,
					},
					itemStyle: {
						borderWidth: 0,
					},
				})),
				legend: { data: data.map((item: any) => item.label) },
			};
		case 'bar':
			return {
				xAxis: {
					type: 'category',
					data: labels,
				},
				yAxis: {
					type: 'value',
				},
				series: data.map((item: any) => ({
					data: data[0].data,
					type: chartOption.series[0].type,
				})),
				legend: { data: data.map((item: any) => item.label) },
			};
		case 'pie':
			return {
				series: [
					{
						type: 'pie',
						data: [
							{ value: data[0], name: labels[0] },
							{ value: data[1], name: labels[1] },
						],
					},
				],
			};
	}
};

export const getChartTitleFromEchartOption = (chartOption: any) => {
	if (chartOption?.title && chartOption?.title?.text) {
		return chartOption?.title?.text;
	}
	return '';
};

export const getAxisNamesFromEchartOption = (
	chartOption: any
): { xAxisName: string; yAxisName: string } => {
	let axisNames = { xAxisName: '', yAxisName: '' };
	if (chartOption?.xAxis) {
		axisNames.xAxisName = chartOption.xAxis.name ? chartOption.xAxis.name : '';
	}
	if (chartOption?.yAxis) {
		axisNames.yAxisName = chartOption.yAxis.name ? chartOption.yAxis.name : '';
	}
	return axisNames;
};

export const getColorsFromEchartOption = (chartOption: any) => {
	if (chartOption?.color) {
		return chartOption?.color;
	}
	return null;
};

export const getLegendsFromEchartOption = (chartOption: any) => {
	if (chartOption?.legend?.data) {
		return chartOption?.legend?.data;
	}
	return [];
};

export const updateChartTitle = (chartTitle: string, chartOption: any) => {
	return {
		...chartOption,
		title: { text: chartTitle, left: '', textSize: { fontSize: 25 } },
	};
};

export const updateXAxisName = (xAxisName: string, chartOption: any) => {
	return {
		...chartOption,
		xAxis: {
			...chartOption.xAxis,
			name: xAxisName,
			nameLocation: 'middle',
			nameGap: 40,
			nameTextStyle: {
				fontSize: 16,
				fontWeight: 600,
			},
		},
	};
};

export const updateYAxisName = (yAxisName: string, chartOption: any) => {
	return {
		...chartOption,
		yAxis: {
			...chartOption.yAxis,
			name: yAxisName,
			nameLocation: 'middle',
			nameGap: 35,
			nameTextStyle: {
				fontSize: 16,
				fontWeight: 600,
			},
		},
	};
};

export const updateLegends = (legends: Array<string>, chartOption: any) => {
	chartOption?.series?.forEach((series: any, index: number) => {
		series.name = legends[index] ? legends[index] : '';
	});
	return {
		...chartOption,
		legend: {
			data: legends,
		},
	};
};

export const updateChartColorPalette = (
	colors: Array<string>,
	chartOption: any
) => {
	return {
		...chartOption,
		color: colors,
	};
};

export const parserChartData = (
	chartType: string | undefined,
	parserResponseData: {
		data: Array<any>;
		labels: Array<string>;
		options: any;
	}
) => {
	return {
		type: chartType,
		data: {
			labels: parserResponseData.labels,
			datasets: parserResponseData.data,
		},
	};
};
