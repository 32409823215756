export const COLOR_SET_KEYS = [
	'Color 1',
	'Color 2',
	'Color 3',
	'Color 4',
	'Color 5',
	'Color 6',
];
export const COLOR_SET: any = {
	'Color 1': [
		'#F4845A',
		'#F5C315',
		'#A5BB87',
		'#53C3C7',
		'#B0ACD1',
		'#B5DBB1',
		'#89BFE4',
		'#F8DE9F',
		'#6399CD',
		'#EE737D',
		'#E9B591',
		'#F0A79E',
		'#F3E2BB',
		'#8ED2C9',
		'#F8E5A6',
		'#E7F5BC',
	],
	'Color 2': [
		'#53C3C7',
		'#B0ACD1',
		'#B5DBB1',
		'#7E8E9B',
		'#BDAB9D',
		'#B3BEB3',
		'#89BFE4',
		'#DBD7CE',
		'#C1D3DD',
		'#EAEAD2',
		'#8ED2C9',
		'#F1A94E',
	],
	'Color 3': [
		'#6399CD',
		'#43AEA9',
		'#E9B591',
		'#F1C50A',
		'#F4845A',
		'#568DA2',
		'#F08A98',
		'#F4D6B2',
		'#FF7A5A',
		'#00AAA0',
		'#F75176',
	],
	'Color 4': [
		'#A5BB87',
		'#EE737D',
		'#5FC265',
		'#C32997',
		'#3598DB',
		'#27AA86',
		'#EB4B3D',
		'#F5C14F',
		'#BFAE94',
		'#F0A79E',
		'#BF5859',
		'#FFB85F',
		'#E45641',
		'#FF796A',
	],
	'Color 5': [
		'#BDAB9D',
		'#6399CD',
		'#F03921',
		'#ECA823',
		'#C32997',
		'#E48022',
		'#287FBA',
		'#914AB0',
		'#43AEA9',
		'#568DA2',
		'#BF5859',
		'#462066',
		'#00AAA0',
		'#5D4C46',
		'#7B8D8E',
	],
	'Color 6': ['#A07759', '#2A586F', '#E45641', '#7B8D8E', '#F75176', '#FF796A'],
	'Color 7': ['#A07759', '#2A586F', '#E45641', '#7B8D8E', '#F75176', '#FF796A'],
	'Color 8': ['#462066', '#FF7A5A', '#00AAA0', '#FFB85F', '#8ED2C9', '#F8E5A6'],
	'Color 9': ['#44B3C2', '#F1A94E', '#5D4C46', '#E45641', '#7B8D8E', '#F8EBB7'],
	'Color 10': ['#414656', '#F75176', '#FF796A', '#FF796A', '#E7F5BC'],
	'Color 11': ['#009DBB', '#79D2CB', '#C3E6D5', '#F0F8C2', '#FF5A7C'],
	'Color 12': ['#FFB8B0', '#F8D0BC', '#F8E2C9', '#B5C8CE', '#3BA3BE'],
	'Color 13': ['#00E9FE', '#6BEB98', '#EAFC3E', '#F4B92F', '#FF7128'],
	'Color 14': ['#373C53', '#9CA9BB', '#F0F5F6', '#FF424F', '#EE2A3A'],
	'Color 15': ['#6465A5', '#6975A7', '#F3EA6B', '#F28A31', '#F15838'],
	'Color 16': ['#80ADD7', '#09BDA0', '#EBF2EA', '#D4DCA9', '#BF9D7A'],
	'Color 17': ['#B04824', '#D6A457', '#86583E', '#161A23', '#533939'],
};

export const ECHARTS_DEFAULT_COLOR_SET = [
	'#5470c6',
	'#91cc75',
	'#fac858',
	'#ee6666',
	'#73c0de',
	'#3ba272',
	'#fc8452',
	'#9a60b4',
	'#ea7ccc',
];
