import { IConfig } from '../core/interfaces/config.interface';

export const config: IConfig = {
	clientId: process.env.REACT_APP_CLIENT_ID || '',
	clientSecret: process.env.REACT_APP_CLIENT_SECRET || '',
	grantType: process.env.REACT_APP_GRANT_TYPE || '',
	callbackURL: process.env.REACT_APP_CALLBACK_URL || '',
	logoutURL: process.env.REACT_APP_LOGOUT_URL || '',
	apiURL: process.env.REACT_APP_API_URL || '',
	baseURL: process.env.REACT_APP_BASE_URL || '',
	dataApi: process.env.REACT_APP_DATA_API || '',
	cmsURL: process.env.REACT_APP_CMS_URL || '',
	appURL: process.env.REACT_APP_URL || '',
	parserURL: process.env.REACT_APP_PARSER_URL || '',
	domainName: process.env.REACT_APP_DOMAIN_NAME || '',
	meta: {
		copyright: process.env.REACT_APP_META_COPY_RIGHT || '',
		email: process.env.REACT_APP_META_EMAIL || '',
		api: {
			version: process.env.REACT_APP_META_API_VERSION || '0.1',
		},
	},
};
