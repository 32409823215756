import React, { FC, useEffect, useRef } from 'react';
import {
	Button,
	Collapse,
	Menu,
	Typography,
	Dropdown,
	Select,
	Row,
	Col,
	InputNumber,
	Empty,
	Switch,
	Form,
} from 'antd';
import moment, { Moment } from 'moment';
import { debounce } from 'throttle-debounce';
import { DoubleLeftOutlined, FileOutlined } from '@ant-design/icons';
import { fabric } from 'fabric';
import './sidebar.scss';
import Sidermenu from './components/Sidermenu';
import TextSetting from './components/TextSetting';
import Styleobject from './components/Styleobject';
import ImageSettings from './components/ImageSettings';
import ElementGeneralSettings from './components/ElementGeneralSettings';
import GraphSetting from './components/GraphSetting';
import FilterSettings from './components/FilterSettings';
import {
	Parser,
	ParserApi,
} from '../../../../core/interfaces/parser.interface';
import { ChartType } from '../../../../core/enums/enum';
import CanvasStyleObject from './components/CanvasStyleObject';
import ShapesSettings from './components/ShapesSettings';
import { autoSaveCanvases } from '../../../../core/services/project.service';
import HeaderFooterSettings from './components/HeaderFooterSettings';
import { ILineOptions, ITextOptions } from 'fabric/fabric-impl';

const { Option } = Select;

interface IProps {
	canvas: any;
	shape?: string;
	canvasId: any;
	canvasWidth: number;
	canvasHeight: number;
	setWidth: Function;
	setHeight: Function;
	selectedParser: Parser | null;
	selectedChartType: ChartType | null;
	selectedObject: any;
	setFetching: Function;
	headerFooterObject: Array<Object>;
}
const { Panel } = Collapse;

const Sidebar: FC<IProps> = (props) => {
	const SIZES = {
		CUSTOM_SIZES: {
			WIDTH: 800,
			HEIGHT: 1148,
		},

		A3: {
			WIDTH: 1122.52,
			HEIGHT: 1587.4,
		},

		A4: {
			WIDTH: 793.7,
			HEIGHT: 1122.52,
		},
		A5: {
			WIDTH: 559.37,
			HEIGHT: 793.7,
		},
	};
	const [active, setActive] = React.useState(false);
	const [sideMenuActive, setSideMenuActive] = React.useState({
		firstMenu: true,
		secondMenu: false,
	});
	const [showText, setShowText] = React.useState(false);
	const [showSidebar, setShowSidebar] = React.useState(null);

	const [showImage, setShowImage] = React.useState(false);

	const [showShapes, setShowShapes] = React.useState(false);

	const [showCharts, setShowCharts] = React.useState(false);
	const [showLines, setShowLine] = React.useState(false);

	const [paperSize, setPaperSize] = React.useState('customsize');
	const [modes, setModes] = React.useState({
		portrait: true,
		Landscape: false,
	});

	const [type, setTypes] = React.useState('');
	const [selectedObject, setSelectedObject] = React.useState<any>(null);

	//all object styles
	const [color, setColor] = React.useState<string>('');
	const [fill, setFill] = React.useState<string>('');
	const [strokeColor, setStrokeColor] = React.useState<string>('');

	const [strokeWidth, setStrokeWidth] = React.useState(0);
	const [opacity, setOpacity] = React.useState(1);

	//image filter states
	const [imageFilterChecked, setCheckedImageFilter] = React.useState<any>([]);

	const [brightnessSlider, setBrightnessSlider] = React.useState({
		disabled: true,
		value: 0,
	});

	const [contrastSlider, setContrastSlider] = React.useState({
		disabled: true,
		value: 0,
	});

	//text state

	const [textInfo, setTextInfo] = React.useState({
		textfontFamily: '',
		textfontWeight: '',
		alignText: '',
		textfontStyle: '',
		textfontSize: null,
		textUnderline: '',
		textFontType: '',
	});

	const [textSelectedStyle, setTextSelectedStyle] = React.useState<any>([]);

	// General Object Settings
	const [dimensions, setDimensions] = React.useState({
		width: null,
		height: null,
	});
	const [position, setPosition] = React.useState({
		left: null,
		top: null,
	});
	const [rotateAngle, setRotateAngle] = React.useState(0);

	//shapes state

	const [lineType, setLineType] = React.useState('');
	const [headerLineType, setHeaderLineType] = React.useState('normalLine');
	const [headerLineStroke, setHeaderLineStroke] = React.useState(2);
	const [lock, setLock] = React.useState<any>(null);

	// canvas color,opacity
	const [canvasColor, setCanvasColor] = React.useState<string>('');
	// const [opacity, setOpacity] = React.useState(1);

	//header/footer btn
	const [hasHeaderFooter, setHasHeaderFooter] = React.useState(false);
	const [initialLoadJSON, setInitialLoadJSON] = React.useState(false);
	const [hasHeaderFooterJSON, setHasHeaderFooterJSON] = React.useState(false);
	const [headerFooterForms] = Form.useForm();

	//get previous state value
	function usePrevious(value: any) {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	}
	const prevState: any = usePrevious({
		color,
		fill,
		strokeWidth,
		strokeColor,
		opacity,
		lock,
		rotateAngle,
		position,
		lineType,
		headerLineStroke,
		headerLineType,
		dimensions,
		textInfo,
		// hasHeaderFooter,
		canvasColor,
		imageFilterChecked,
		brightnessSlider,
		contrastSlider,
	});

	useEffect(() => {
		if (props.selectedObject) {
			if (
				props.selectedObject.type === 'i-text' ||
				props.selectedObject.type === 'textbox'
			) {
				setShowSidebar(props.selectedObject);
				setShowText(true);
				setShowImage(false);
				setShowShapes(false);
				setShowCharts(false);
				setShowLine(false);
				setTypes('element');
			} else if (props.selectedObject.type === 'image') {
				setShowSidebar(props.selectedObject);
				setShowText(false);
				setShowShapes(false);
				setShowCharts(false);
				setShowImage(true);
				setShowLine(false);
				setTypes('element');
			} else if (
				props.selectedObject.type === 'rect' ||
				props.selectedObject.type === 'triangle' ||
				props.selectedObject.type === 'circle'
			) {
				setShowSidebar(props.selectedObject);
				setShowText(false);
				setShowImage(false);
				setShowCharts(false);
				setShowShapes(true);
				setShowLine(false);
				setTypes('element');
			} else if (props.selectedObject.type === 'line') {
				setShowSidebar(props.selectedObject);
				setShowText(false);
				setShowImage(false);
				setShowCharts(false);
				setShowShapes(false);
				setShowLine(true);
				setTypes('element');
			} else if (props.selectedObject.type === 'chart') {
				setShowSidebar(props.selectedObject);
				setShowShapes(false);
				setShowText(false);
				setShowImage(false);
				setShowCharts(true);
				setShowLine(false);
				setTypes('element');
			}
			setSideMenuActive({
				firstMenu: false,
				secondMenu: true,
			});
			setSelectedObject(props.selectedObject);
		} else {
			setShowSidebar(null);
			setSelectedObject(null);
			setShowText(false);
			setShowImage(false);
			setShowShapes(false);
			setShowCharts(false);
			setShowLine(false);
			setActive(false);
			setTypes('notelement');

			setSideMenuActive({
				firstMenu: true,
				secondMenu: false,
			});
		}
	}, [props.selectedObject]);

	const modeHeader = (width: number) => {
		props.canvas.map((item: any) => {
			item.canvasDom.getObjects().map((obj: any) => {
				if (obj.superType === 'header-date') {
					obj.set({
						...obj,
						left: width - 150,
						top: 25,
					});
				}
				if (obj.superType === 'header-line') {
					obj.set({
						x1: 80,
						y1: 80,
						x2: width + 80,
						y2: 80,
						left: 0,
						top: 50,
						stroke: 'black',
						lockMovementX: true,
						lockMovementY: true,
					});
					obj.setCoords();
				}
			});
		});
	};

	const modeFooter = (width: number, height: number) => {
		props.canvas.map((item: any) => {
			item.canvasDom.getObjects().map((obj: any) => {
				if (obj.superType === 'footer-preparedBy') {
					obj.set({
						...obj,
						left: 50,
						top: height - 30,
						// dirty: true,
					});
				}
				if (obj.superType === 'footer-preparedForImage') {
					obj.set({
						...obj,
						left: 200,
						top: props.canvasHeight - 28,
						// dirty: true,
					});
				}

				if (obj.superType === 'footer-preparedByImage') {
					obj.set({
						...obj,
						left: 300,
						top: props.canvasHeight - 28,
						// dirty: true,
					});
				}

				if (obj.superType === 'footer-preparedFor') {
					obj.set({
						...obj,
						left: 250,
						top: height - 30,
						// dirty: true,
					});
				}

				if (obj.superType === 'footer-page') {
					obj.set({
						...obj,
						left: width - 150,
						top: height - 30,
						// dirty: true,
					});
				}

				if (obj.superType === 'footer-line') {
					obj.set({
						x1: 80,
						y1: 80,
						x2: width + 80,
						y2: 80,
						left: 0,
						top: height - 50,
						stroke: 'black',
						lockMovementX: true,
						lockMovementY: true,
					});
					obj.setCoords();
				}
			});
		});
	};

	useEffect(() => {
		if (modes.portrait) {
			if (paperSize === 'customsize') {
				props.canvas.map((item: any) => {
					item.canvasDom.setWidth(SIZES.CUSTOM_SIZES.WIDTH);
					item.canvasDom.setHeight(SIZES.CUSTOM_SIZES.HEIGHT);
					props.setWidth(SIZES.CUSTOM_SIZES.WIDTH);
					props.setHeight(SIZES.CUSTOM_SIZES.HEIGHT);
					setPaperSize('customsize');
					if (hasHeaderFooter) {
						modeHeader(SIZES.CUSTOM_SIZES.WIDTH);
						modeFooter(SIZES.CUSTOM_SIZES.WIDTH, SIZES.CUSTOM_SIZES.HEIGHT);
					}

					item.canvasDom.renderAll();
				});
			} else if (paperSize === 'A3') {
				props.canvas.map((item: any) => {
					item.canvasDom.setWidth(SIZES.A3.WIDTH);
					item.canvasDom.setHeight(SIZES.A3.HEIGHT);
					props.setWidth(SIZES.A3.WIDTH);
					props.setHeight(SIZES.A3.HEIGHT);
					setPaperSize('A3');
					modeHeader(SIZES.A3.WIDTH);
					modeFooter(SIZES.A3.WIDTH, SIZES.A3.HEIGHT);
					item.canvasDom.renderAll();
				});
			} else if (paperSize === 'A4') {
				props.canvas.map((item: any) => {
					item.canvasDom.setWidth(SIZES.A4.WIDTH);
					item.canvasDom.setHeight(SIZES.A4.HEIGHT);
					props.setWidth(SIZES.A4.WIDTH);
					props.setHeight(SIZES.A4.HEIGHT);
					setPaperSize('A4');
					modeHeader(SIZES.A4.WIDTH);
					modeFooter(SIZES.A4.WIDTH, SIZES.A4.HEIGHT);
					item.canvasDom.renderAll();
				});
			} else {
				props.canvas.map((item: any) => {
					item.canvasDom.setWidth(SIZES.A5.WIDTH);
					item.canvasDom.setHeight(SIZES.A5.HEIGHT);
					props.setWidth(SIZES.A5.WIDTH);
					props.setHeight(SIZES.A5.HEIGHT);
					setPaperSize('A5');
					modeHeader(SIZES.A5.WIDTH);
					modeFooter(SIZES.A5.WIDTH, SIZES.A5.HEIGHT);
					item.canvasDom.renderAll();
				});
			}
		} else {
			if (paperSize === 'customsize') {
				props.canvas.map((item: any) => {
					item.canvasDom.setWidth(SIZES.CUSTOM_SIZES.HEIGHT);
					item.canvasDom.setHeight(SIZES.CUSTOM_SIZES.WIDTH);
					props.setWidth(SIZES.CUSTOM_SIZES.HEIGHT);
					props.setHeight(SIZES.CUSTOM_SIZES.WIDTH);

					setPaperSize('customsize');
					if (hasHeaderFooter) {
						modeHeader(SIZES.CUSTOM_SIZES.HEIGHT);
						modeFooter(SIZES.CUSTOM_SIZES.HEIGHT, SIZES.CUSTOM_SIZES.WIDTH);
					}

					item.canvasDom.renderAll();
				});
				//}
			} else if (paperSize === 'A3') {
				props.canvas.map((item: any) => {
					item.canvasDom.setWidth(SIZES.A3.HEIGHT);
					item.canvasDom.setHeight(SIZES.A3.WIDTH);
					props.setWidth(SIZES.A3.HEIGHT);
					props.setHeight(SIZES.A3.WIDTH);
					setPaperSize('A3');
					modeHeader(SIZES.A3.HEIGHT);
					modeFooter(SIZES.A3.HEIGHT, SIZES.A3.WIDTH);
					item.canvasDom.renderAll();
				});
			} else if (paperSize === 'A4') {
				props.canvas.map((item: any) => {
					item.canvasDom.setWidth(SIZES.A4.HEIGHT);
					item.canvasDom.setHeight(SIZES.A4.WIDTH);
					props.setWidth(SIZES.A4.HEIGHT);
					props.setHeight(SIZES.A4.WIDTH);
					setPaperSize('A4');
					modeHeader(SIZES.A4.HEIGHT);
					modeFooter(SIZES.A4.HEIGHT, SIZES.A4.WIDTH);
					item.canvasDom.renderAll();
				});
			} else {
				props.canvas.map((item: any) => {
					item.canvasDom.setWidth(SIZES.A5.HEIGHT);
					item.canvasDom.setHeight(SIZES.A5.WIDTH);
					props.setWidth(SIZES.A5.HEIGHT);
					props.setHeight(SIZES.A5.WIDTH);
					setPaperSize('A5');
					modeHeader(SIZES.A5.HEIGHT);
					modeFooter(SIZES.A5.HEIGHT, SIZES.A5.WIDTH);
					item.canvasDom.renderAll();
				});
			}
		}
	}, [modes, paperSize]);

	//call when color,fill,shapes changes

	React.useEffect(() => {
		if (
			prevState &&
			(prevState.color !== color ||
				prevState.fill !== fill ||
				prevState.strokeColor !== strokeColor ||
				prevState.strokeWidth !== strokeWidth ||
				prevState.opacity !== opacity ||
				prevState.lock !== lock ||
				prevState.rotateAngle !== rotateAngle ||
				prevState.position !== position ||
				prevState.lineType !== lineType ||
				prevState.dimensions !== dimensions ||
				prevState.canvasColor !== canvasColor ||
				prevState.headerLineStroke !== headerLineStroke,
			prevState.headerLineType !== headerLineType ||
				// prevState.hasHeaderFooter !== hasHeaderFooter ||
				JSON.stringify(prevState.textInfo) !== JSON.stringify(textInfo) ||
				JSON.stringify(prevState.textInfo) === JSON.stringify(textInfo) ||
				prevState.imageFilterChecked !== imageFilterChecked ||
				JSON.stringify(prevState.brightnessSlider) !==
					JSON.stringify(brightnessSlider) ||
				JSON.stringify(prevState.contrastSlider) !==
					JSON.stringify(contrastSlider))
		) {
			const autoSaveCanvasDebounce = debounce(1000, false, () => {
				// call auto save api
				let pageNumber = 0;
				const canvasInstanceIndex = props.canvas.findIndex(
					(canvas: any) => canvas.id === props.canvasId
				);
				if (canvasInstanceIndex > -1) {
					pageNumber = canvasInstanceIndex + 1;
					if (pageNumber) {
						const canvasObj = props.canvas[
							canvasInstanceIndex
						].canvasDom.toObject([
							'id',
							'name',
							'locked',
							'evented',
							'editable',
							'headingType',
							'superType',
							'_id',
						]).objects;

						props.setFetching(true);
						const headerFooterObjects = getHeaderFooterObjs(canvasObj);

						autoSaveCanvases(
							props.canvasId,
							canvasObj,
							pageNumber,
							headerFooterObjects
						)
							.then((res: any) => {
								props.setFetching(false);
							})
							.catch((error: any) => {
								props.setFetching(false);
								console.log(error);
							});
					}
				}
			});
			autoSaveCanvasDebounce();
		}
	}, [
		color,
		fill,
		strokeColor,
		strokeWidth,
		lock,
		lineType,
		opacity,
		rotateAngle,
		position,
		dimensions,
		textInfo,
		canvasColor,
		imageFilterChecked,
		brightnessSlider,
		contrastSlider,
		lineType,
		headerLineStroke,
		headerLineType,
	]);

	const getHeaderFooterObjs = (canvasObjs: Array<any>) => {
		const headerFooterObjIds: Array<string> = [];
		const headerFooterObj = canvasObjs.filter((obj: any) => {
			if (
				'superType' in obj &&
				(obj.superType === 'header' || obj.superType === 'footer')
			) {
				headerFooterObjIds.push(obj._id);
				return true;
			}
			return false;
		});
		headerFooterObjIds.forEach((_id: string) => {
			canvasObjs.forEach((newObj: any, index: number) => {
				if (newObj._id === _id) {
					canvasObjs.splice(index, 1);
				}
			});
		});
		return headerFooterObj;
	};

	//general object style
	React.useEffect(() => {
		if (selectedObject) {
			setColor(selectedObject.fill);
			setStrokeColor(selectedObject.stroke);
			setStrokeWidth(
				selectedObject.strokeWidth ? selectedObject.strokeWidth : 1
			);
			setOpacity(selectedObject.opacity ? selectedObject.opacity : 1);
			setDimensions({
				width: selectedObject.width,
				height: selectedObject.height,
			});
			setPosition({
				left: selectedObject.left,
				top: selectedObject.top,
			});

			if (selectedObject.lockMovementY) {
				setLock(true);
			} else {
				setLock(false);
			}

			setRotateAngle(selectedObject.angle);
			if (selectedObject && selectedObject.type === 'image') {
				let newFilters: Array<any> = [];

				if (selectedObject.filters.length > 0) {
					selectedObject.filters.map((item: any) => {
						newFilters.push(item.__proto__.type);

						if (item.__proto__.type === 'Brightness' && item.brightness) {
							setBrightnessSlider({
								disabled: false,
								value: item.brightness,
							});
						}

						if (item.__proto__.type === 'Contrast' && item.contrast) {
							setContrastSlider({
								disabled: false,
								value: item.contrast,
							});
						}
					});
					setCheckedImageFilter(newFilters);
				} else {
					setCheckedImageFilter([]);
					setContrastSlider({
						disabled: true,
						value: 0,
					});
					setBrightnessSlider({
						disabled: true,
						value: 0,
					});
				}
			}

			if (selectedObject && selectedObject.type === 'i-text') {
				if (selectedObject.headingType === 'normal') {
					setTextInfo({
						textfontFamily: selectedObject.fontFamily,
						textfontSize: selectedObject.fontSize,
						textfontWeight: selectedObject.fontWeight,
						alignText: selectedObject.textAlign,
						textfontStyle: selectedObject.fontStyle,
						textUnderline: selectedObject.underline,
						textFontType: 'normal',
					});
				} else if (selectedObject.headingType === 'heading1') {
					setTextInfo({
						textfontFamily: selectedObject.fontFamily,
						textfontSize: selectedObject.fontSize,
						textfontWeight: selectedObject.fontWeight,
						alignText: selectedObject.textAlign,
						textfontStyle: selectedObject.fontStyle,
						textUnderline: selectedObject.underline,
						textFontType: 'heading1',
					});
				} else if (selectedObject.headingType === 'heading2') {
					setTextInfo({
						textfontFamily: selectedObject.fontFamily,
						textfontSize: selectedObject.fontSize,
						textfontWeight: selectedObject.fontWeight,
						alignText: selectedObject.textAlign,
						textfontStyle: selectedObject.fontStyle,
						textUnderline: selectedObject.underline,
						textFontType: 'heading2',
					});
				} else if (selectedObject.headingType === 'heading3') {
					setTextInfo({
						textfontFamily: selectedObject.fontFamily,
						textfontSize: selectedObject.fontSize,
						textfontWeight: selectedObject.fontWeight,
						alignText: selectedObject.textAlign,
						textfontStyle: selectedObject.fontStyle,
						textUnderline: selectedObject.underline,
						textFontType: 'heading3',
					});
				}

				setTextSelectedStyle([
					selectedObject.fontWeight,
					selectedObject.textAlign,
					selectedObject.fontStyle,
					selectedObject.underline && 'underline',
				]);
			}

			if (selectedObject && selectedObject.type === 'line') {
				if (
					selectedObject.strokeDashArray &&
					selectedObject.strokeDashArray[0] === 5 &&
					selectedObject.strokeDashArray[0] === 5
				) {
					setLineType('dashedLine');
				} else {
					setLineType('normalLine');
				}
			}

			setFill(
				selectedObject.fill === 'rgba(255,255,255,0)' ? 'nofill' : 'fill'
			);
		}
	}, [selectedObject]);

	React.useEffect(() => {
		if (hasHeaderFooterJSON) {
			setHasHeaderFooterJSON(false);
			return;
		}
		if (hasHeaderFooter) {
			props.canvas.map((item: any, index: number) => {
				const canvasDom = item.canvasDom;
				let preparedByTextObject: any, preparedForTextObject: any;
				removePreviousHeaderFooterObjects(canvasDom);
				addHeader((headerTitle: any, headerDate: any, line: any) => {
					canvasDom.add(headerTitle, headerDate, line);
				});
				addFooter(
					index + 1,
					(preparedBy: any, preparedFor: any, dateText: any, line: any) => {
						preparedByTextObject = preparedBy;
						preparedForTextObject = preparedFor;
						canvasDom.add(preparedBy, preparedFor, dateText, line);
					}
				);
				// Prepared By Image
				addImage(
					canvasDom,
					`${process.env.REACT_APP_BASE_URL}/uploads/1612851665473.png`,
					'footer',
					'footer-preparedForBy',
					0.5,
					preparedByTextObject.width + 50,
					props.canvasHeight - 28
				);
				// Prepared For Image
				addImage(
					canvasDom,
					`https://kuoreport-cms.ekbana.net/uploads/0QGmM-vp.png`,
					'footer',
					'footer-preparedForImage',
					0.2,
					preparedForTextObject.width + 240,
					props.canvasHeight - 28
				);
			});
		} else {
			props.canvas.map((item: any) => {
				removePreviousHeaderFooterObjects(item.canvasDom);
			});
		}
	}, [hasHeaderFooter]);

	const addImage = (
		canvasDom: any,
		url: string,
		superType: string,
		_id: string,
		scale: number,
		left: number,
		top: number
	) => {
		fabric.Image.fromURL(url, (img: any) => {
			img.scale(scale).set({
				superType,
				_id,
				left,
				top,
				lockMovementX: true,
				lockMovementY: true,
				angle: 0,
			});
			canvasDom.add(img);
		});
	};

	useEffect(() => {
		if (Array.isArray(props.canvas) && props.canvas.length) {
			if (props.canvas.length > 0) {
				const propsToInclude = [
					'id',
					'name',
					'locked',
					'editable',
					'headingType',
				];

				// Check if header or footer pre-exists
				let headerFooterExists: boolean = false;
				props.canvas[0].canvasDom.getObjects().forEach((obj: any) => {
					const newObj = obj.toObject(propsToInclude);
					if (newObj.superType === 'header' || newObj.superType === 'footer') {
						headerFooterExists = true;
						return;
					}
				});

				if (
					Array.isArray(props.canvas[0].canvasDom.getObjects()) &&
					props.canvas[0].canvasDom.getObjects().length &&
					headerFooterExists
				) {
					setHasHeaderFooterJSON(true);
					setInitialLoadJSON(true);
					setHasHeaderFooter(true);

					props.canvas[0].canvasDom
						.getObjects()
						.forEach((item: any, index: number) => {
							if (item.type === 'line') {
								item.strokeWidth && setHeaderLineStroke(item.strokeWidth);

								item.strokeDashArray === null
									? setHeaderLineType('normalLine')
									: setHeaderLineType('dashedLine');
							}

							if (item.toObject(propsToInclude)._id === 'header-title') {
								headerFooterForms.setFieldsValue({
									headerTitle: item.text,
								});
							}

							if (item.toObject(propsToInclude)._id === 'header-date') {
								const date = moment(item.toObject().text, 'Do MMMM YYYY');

								headerFooterForms.setFieldsValue({
									headerDate: date,
								});
							}
						});
				}
			}
		}
	}, [props.canvas]);

	const addHeader = (cb: Function, title?: string, date?: Moment) => {
		// Header Title
		const headerTextOption: ITextOptions = {
			left: 50,
			top: 25,
			evented: false,
			selectable: false,
			fontSize: 16,
			lockMovementX: true,
			lockMovementY: true,
			fontWeight: 'normal',
			fontStyle: 'normal',
			fontFamily: 'Times New Roman',
			fill: 'black',
			underline: false,
			editable: false,
		};
		const headerTitle: any = new fabric.IText(
			title ? title : 'Header Title',
			headerTextOption
		);
		// headerTitle.toObject = () => {
		//   return {
		//     superType: "header",
		//     _id: "header-title",
		//     ...headerTextOption,
		//   };
		// };
		headerTitle.superType = 'header';
		headerTitle._id = 'header-title';
		// Header Date Text
		const headerDateTextOption: ITextOptions = {
			left: Math.trunc(props.canvasWidth) - 150,
			top: 25,
			evented: false,
			selectable: false,
			fontSize: 14,
			lockMovementX: true,
			lockMovementY: true,
			fontWeight: 'normal',
			fontStyle: 'normal',
			fontFamily: 'Times New Roman',
			fill: 'black',
			underline: false,
			editable: false,
		};
		const headerDate: any = new fabric.IText(
			moment(date ? date.toDate() : new Date()).format('Do MMMM YYYY'),
			headerDateTextOption
		);
		// headerDate.toObject = () => {
		//   return {
		//     superType: "header",
		//     _id: "header-date",
		//     ...headerDateTextOption,
		//   };
		// };
		headerDate.superType = 'header';
		headerDate._id = 'header-date';
		// LINE
		const lineCoords = {
			x1: 80,
			y1: 80,
			x2: Math.trunc(props.canvasWidth + 80),
			y2: 80,
		};
		const lineOption = {
			left: 0,
			top: 50,
			stroke: 'black',
			strokeWidth: 2,
			strokeDashArray: headerLineType === 'normalLine' ? [0, 0] : [5, 5],
			lockMovementX: true,
			lockMovementY: true,
			hasControls: false,
			hasBorders: false,
			...lineCoords,
		};
		const line: any = new fabric.Line(
			[lineCoords.x1, lineCoords.y1, lineCoords.x2, lineCoords.y2],
			lineOption
		);
		// line.toObject = () => {
		//   return {
		//     superType: "header",
		//     _id: "header-line",
		//     ...lineOption,
		//   };
		// };
		line.superType = 'header';
		line._id = 'header-line';
		cb(headerTitle, headerDate, line);
	};

	const addFooter = (pageNumber: number, cb: Function) => {
		// Prepared By Text
		const preparedByTextOption: ITextOptions = {
			left: 50,
			top: props.canvasHeight - 30,
			fontSize: 16,
			evented: false,
			selectable: false,
			lockMovementX: true,
			lockMovementY: true,
			fontWeight: 'normal',
			fontStyle: 'normal',
			fontFamily: 'Times New Roman',
			fill: 'black',
			underline: false,
		};
		const preparedBy: any = new fabric.IText(
			'Prepared By: ',
			preparedByTextOption
		);
		// preparedBy.toObject = () => {
		//   return {
		//     superType: "footer",
		//     _id: "footer-preparedBy",
		//     ...preparedByTextOption,
		//   };
		// };
		preparedBy.superType = 'footer';
		preparedBy._id = 'footer-preparedBy';

		// Prepared For Text
		const preparedForTextOption: ITextOptions = {
			left: preparedBy.width + 150,
			top: props.canvasHeight - 30,
			fontSize: 16,
			lockMovementX: true,
			lockMovementY: true,

			evented: false,
			selectable: false,
			fontWeight: 'normal',
			fontStyle: 'normal',
			fontFamily: 'Times New Roman',
			fill: 'black',
			underline: false,
		};
		const preparedFor: any = new fabric.IText(
			'Prepared For: ',
			preparedForTextOption
		);
		// preparedFor.toObject = () => {
		//   return {
		//     superType: "footer",
		//     _id: "footer-preparedFor",
		//     ...preparedForTextOption,
		//   };
		// };

		preparedFor.superType = 'footer';
		preparedFor._id = 'footer-preparedFor';

		// Page Number Text
		const pageTextOption: ITextOptions = {
			left: Math.trunc(props.canvasWidth) - 150,
			top: props.canvasHeight - 30,
			fontSize: 14,
			evented: false,
			selectable: false,
			lockMovementX: true,
			lockMovementY: true,
			fontWeight: 'normal',
			fontStyle: 'normal',
			fontFamily: 'Times New Roman',
			fill: 'black',
			underline: false,
		};
		const pageText: any = new fabric.IText(
			`Page ${pageNumber}`,
			pageTextOption
		);
		// pageText.toObject = () => {
		//   return {
		//     superType: "footer",
		//     _id: "footer-page",
		//     ...pageTextOption,
		//   };
		// };
		pageText.superType = 'footer';
		pageText._id = 'footer-page';

		// LINE
		const lineCoords = {
			x1: 80,
			y1: 80,
			x2: Math.trunc(props.canvasWidth + 80),
			y2: 80,
		};
		const lineOption: ILineOptions = {
			left: 0,
			top: props.canvasHeight - 50,
			evented: false,
			selectable: false,
			stroke: 'black',
			strokeWidth: 2,
			strokeDashArray: headerLineType === 'normalLine' ? [0, 0] : [5, 5],
			lockMovementX: true,
			lockMovementY: true,
			hasControls: false,
			hasBorders: false,
			...lineCoords,
		};
		const line: any = new fabric.Line(
			[lineCoords.x1, lineCoords.y1, lineCoords.x2, lineCoords.y2],
			lineOption
		);

		line.superType = 'footer';
		line._id = 'footer-line';

		// line.toObject = () => {
		//   return {
		//     superType: "footer",
		//     _id: "footer-line",
		//     ...lineOption,
		//   };
		// };
		cb(preparedBy, preparedFor, pageText, line);
	};

	const removePreviousHeaderFooterObjects = (canvasDom: any) => {
		canvasDom.getObjects().map((obj: any) => {
			const propsToInclude = [
				'id',
				'name',
				'locked',
				'editable',
				'headingType',
			];
			if (
				obj.superType === 'header' ||
				obj.toObject(propsToInclude).superType === 'header' ||
				obj.superType === 'footer' ||
				obj.toObject(propsToInclude).superType === 'footer'
			) {
				canvasDom.remove(obj);
			}
		});
	};

	const handleHeaderFooterSettingsUpdate = (values: any) => {
		props.canvas.map((item: any, index: number) => {
			removePreviousHeaderFooterObjects(item.canvasDom);
			let preparedByTextObject: any, preparedForTextObject: any;
			addHeader(
				(headerTitle: any, headerDate: any, line: any) => {
					item.canvasDom.add(headerTitle, headerDate, line);
				},
				values.headerTitle,
				values.headerDate
			);
			addFooter(
				index + 1,
				(preparedBy: any, preparedFor: any, dateText: any, line: any) => {
					preparedByTextObject = preparedBy;
					preparedForTextObject = preparedFor;
					item.canvasDom.add(preparedBy, preparedFor, dateText, line);
				}
			);
			// Load footer images
			fabric.Image.fromURL(
				`${process.env.REACT_APP_BASE_URL}/uploads/1612851665473.png`,
				(img: any) => {
					img.scale(0.5).set({
						superType: 'footer',
						_id: 'footer-preparedForBy',
						left: preparedByTextObject.width + 50,
						top: props.canvasHeight - 28,
						evented: false,
						selectable: false,
						angle: 0,
						lockMovementX: true,
						lockMovementY: true,
					});
					item.canvasDom.add(img);
				}
			);
			fabric.Image.fromURL(
				`https://kuoreport-cms.ekbana.net/uploads/0QGmM-vp.png`,

				function (img: any) {
					img.scale(0.2).set({
						superType: 'footer',
						_id: 'footer-preparedForImage',
						left: preparedForTextObject.width + 240,
						top: props.canvasHeight - 28,
						evented: false,
						selectable: false,
						lockMovementX: true,
						lockMovementY: true,
						angle: 0,
					});
					item.canvasDom.add(img);
				}
			);
		});
	};

	return (
		<div className='setting__sidebar__wrapper'>
			<div className='setting__sidebar'>
				<React.Fragment>
					<Button
						onClick={() => {
							setActive(!active);
							setShowSidebar(null);
							setSideMenuActive({
								firstMenu: sideMenuActive.firstMenu,
								secondMenu: sideMenuActive.secondMenu,
							});
						}}
						className='toggling__btn'>
						<span className={active ? 'icon-left' : 'icon-right'}>
							<DoubleLeftOutlined />
						</span>
					</Button>
				</React.Fragment>
				<Sidermenu
					sideMenuActive={sideMenuActive}
					setSideMenuActive={setSideMenuActive}
				/>
			</div>
			<div>
				<React.Fragment>
					{(showSidebar || active) && (
						<div
							className={
								showSidebar || active
									? 'settings__box settingbox__show'
									: 'settings__box '
							}>
							<div className='setting__box__inner'>
								<div className='text__setting'>
									<Collapse>
										{sideMenuActive.firstMenu && (
											<React.Fragment>
												<Panel header='General' key='1' showArrow={false}>
													<Row>
														<Select
															defaultValue={paperSize}
															value={paperSize}
															onChange={(value: any) => {
																setPaperSize(value);
															}}
															style={{ width: 120 }}>
															<Option value='customsize'>Custom Size</Option>

															<Option value='A3'>A3</Option>
															<Option value='A4'>A4</Option>
															<Option value='A5'>A5</Option>
														</Select>
													</Row>
													<div style={{ marginTop: 20 }}></div>

													<Row>
														<Col span={8}>
															<Button
																type='link'
																icon={<FileOutlined style={{ fontSize: 16 }} />}
																style={{
																	color: modes.portrait ? '#4ca1af' : '#ccc',
																}}
																onClick={() => {
																	setModes({
																		portrait: true,
																		Landscape: false,
																	});
																}}>
																Portrait
															</Button>
														</Col>
														<Col span={8}>
															<Button
																type='link'
																icon={<FileOutlined style={{ fontSize: 16 }} />}
																onClick={() => {
																	setModes({
																		portrait: false,
																		Landscape: true,
																	});
																}}
																style={{
																	color: modes.Landscape ? '#4ca1af' : '#ccc',
																}}>
																Landscape
															</Button>
														</Col>
													</Row>
													<div style={{ marginTop: 20 }}></div>

													<React.Fragment>
														<Row gutter={8} style={{ marginTop: 8 }}>
															<Col span={12}>
																<Typography>Width</Typography>
																<InputNumber
																	value={Math.trunc(props.canvasWidth)}
																	disabled={paperSize !== 'customsize'}
																	onChange={(value) => {
																		props.setWidth(value);

																		props.canvas.map((item: any) => {
																			item.canvasDom.setWidth(value);
																			item.canvasDom.renderAll();
																		});
																	}}
																	placeholder='Width'
																	style={{ marginTop: 8 }}
																/>
															</Col>
															<Col span={12}>
																<Typography>Height</Typography>
																<InputNumber
																	value={Math.trunc(props.canvasHeight)}
																	disabled={paperSize !== 'customsize'}
																	onChange={(value) => {
																		props.setHeight(value);
																		props.canvas.map((item: any) => {
																			item.canvasDom.setHeight(value);
																			item.canvasDom.renderAll();
																		});
																	}}
																	placeholder='Height'
																	style={{ marginTop: 8 }}
																/>
															</Col>
														</Row>
														<div style={{ marginTop: 20 }}></div>
														<Row>
															<Col>
																<div>Enable Header Footer</div>
															</Col>
															<Col offset={1}>
																<Switch
																	checked={hasHeaderFooter}
																	onChange={(value) => {
																		setHasHeaderFooter(value);
																	}}
																/>
															</Col>
														</Row>
													</React.Fragment>

													<div style={{ marginTop: 20 }}></div>
												</Panel>

												<Panel header='Style' key='2' showArrow={false}>
													<CanvasStyleObject
														canvasId={props.canvasId}
														canvas={props.canvas}
														type={type}
														selectedObject={selectedObject}
														color={canvasColor}
														setCanvasColor={setCanvasColor}
													/>
												</Panel>

												{hasHeaderFooter && (
													<Panel
														header='Header/Footer'
														key='10'
														showArrow={false}>
														<HeaderFooterSettings
															canvasId={props.canvasId}
															canvas={props.canvas}
															selectedObject={selectedObject}
															headerFooterForms={headerFooterForms}
															headerLineType={headerLineType}
															setHeaderLineType={setHeaderLineType}
															setHeaderLineStroke={setHeaderLineStroke}
															headerLineStroke={headerLineStroke}
															updateHeaderFooterSettings={
																handleHeaderFooterSettingsUpdate
															}
															headerFooterObject={props.headerFooterObject}
														/>
													</Panel>
												)}
											</React.Fragment>
										)}

										{sideMenuActive.secondMenu && (
											<React.Fragment>
												{(showText ||
													showImage ||
													showShapes ||
													showCharts ||
													showLines) && (
													<Panel header='General' key='3' showArrow={false}>
														<ElementGeneralSettings
															canvasId={props.canvasId}
															canvas={props.canvas}
															selectedObject={selectedObject}
															type={type}
															dimensions={dimensions}
															position={position}
															rotateAngle={rotateAngle}
															setDimensions={setDimensions}
															setPosition={setPosition}
															setRotateAngle={setRotateAngle}
															lock={lock}
															setLock={setLock}
														/>
													</Panel>
												)}
												{showText && (
													<Panel header='Text' key='4' showArrow={false}>
														<TextSetting
															canvasId={props.canvasId}
															canvas={props.canvas}
															selectedObject={selectedObject}
															textInfo={textInfo}
															setTextInfo={setTextInfo}
															textSelectedStyle={textSelectedStyle}
															setTextSelectedStyle={setTextSelectedStyle}
														/>
													</Panel>
												)}

												{showImage && (
													<Panel header='Image' key='5' showArrow={false}>
														<ImageSettings
															canvasId={props.canvasId}
															canvas={props.canvas}
															type={type}
															selectedObject={selectedObject}
														/>
													</Panel>
												)}
												{showImage && (
													<Panel header='Filter' key='6' showArrow={false}>
														<FilterSettings
															canvasId={props.canvasId}
															canvas={props.canvas}
															selectedObject={selectedObject}
															imageFilterChecked={imageFilterChecked}
															brightnessSlider={brightnessSlider}
															contrastSlider={contrastSlider}
															setCheckedImageFilter={setCheckedImageFilter}
															setBrightnessSlider={setBrightnessSlider}
															setContrastSlider={setContrastSlider}
														/>
													</Panel>
												)}

												{showLines && (
													<Panel header='Shapes' key='7' showArrow={false}>
														<ShapesSettings
															canvasId={props.canvasId}
															canvas={props.canvas}
															selectedObject={selectedObject}
															lineType={lineType}
															setLineType={setLineType}
														/>
													</Panel>
												)}

												{(showText || showImage || showShapes || showLines) && (
													<Panel header='Style' key='8' showArrow={false}>
														<Styleobject
															canvasId={props.canvasId}
															canvas={props.canvas}
															type={type}
															selectedObject={selectedObject}
															color={color}
															strokeColor={strokeColor}
															strokeWidth={strokeWidth}
															opacity={opacity}
															fill={fill}
															setFill={setFill}
															setColor={setColor}
															setStrokeColor={setStrokeColor}
															setStrokeWidth={setStrokeWidth}
															setOpacity={setOpacity}
														/>
													</Panel>
												)}

												{showCharts && (
													<Panel header='Chart' key='9' showArrow={false}>
														<GraphSetting
															setFetching={props.setFetching}
															canvas={props.canvas}
															canvasId={props.canvasId}
															selectedParser={props.selectedParser}
															selectedChart={props.selectedObject}
														/>
													</Panel>
												)}
											</React.Fragment>
										)}
									</Collapse>
								</div>
							</div>
						</div>
					)}
				</React.Fragment>
			</div>
		</div>
	);
};

export default Sidebar;
