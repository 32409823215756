import { FETCH_AUTH, LOGOUT } from "./types";
import { IHttpRes } from "../../interfaces/http.interface";
import { IUser } from "../../interfaces/user.interface";
import { login } from "../../services/auth.service";

export const fetchLogin = (username: string, password: string) => (
  dispatch: any
) => {
  login(username, password)
    .then((response: IHttpRes<IUser>) => {
      const { data } = response.data;
      window.localStorage.setItem("accessToken", data.authToken);
      window.localStorage.setItem("auth", JSON.stringify(data));
      dispatch({
        type: FETCH_AUTH,
        payload: data,
      });
    })
    .catch((error) => {});
};

export const logout = () => (dispatch: any) => {
  window.localStorage.removeItem("accessToken");
  window.localStorage.removeItem("auth");
  dispatch({
    type: LOGOUT,
    payload: {},
  });
};
