import React, { useState, useEffect } from "react";
import { Menu, Layout } from "antd";
import {
  ControlOutlined,
  SettingOutlined,
  BuildOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;
interface ISideMenu {
  setSideMenuActive: Function;
  sideMenuActive: { firstMenu: boolean; secondMenu: boolean };
}

const Sidermenu = (props: ISideMenu) => {
  const [selectedKeys, setSelectedKeys] = useState<Array<string>>([]);

  useEffect(() => {
    if (props.sideMenuActive.firstMenu) {
      setSelectedKeys(["1"]);
    } else if (props.sideMenuActive.secondMenu) {
      setSelectedKeys(["2"]);
    }
  }, [props.sideMenuActive]);

  return (
    <Sider>
      <Menu mode="inline" selectedKeys={selectedKeys} defaultOpenKeys={["1"]}>
        <Menu.Item
          key="1"
          onClick={() =>
            props.setSideMenuActive({
              firstMenu: true,
              secondMenu: false,
            })
          }
          icon={<SettingOutlined />}
        ></Menu.Item>

        <Menu.Item
          key="2"
          onClick={() =>
            props.setSideMenuActive({
              firstMenu: false,
              secondMenu: true,
            })
          }
          icon={<ControlOutlined />}
        ></Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidermenu;
