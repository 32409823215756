import { fabric } from "fabric";
import { TableType } from "../../core/enums/enum";
import { TableOption } from "../../core/interfaces/table.interface";

const Table = fabric.util.createClass(fabric.Rect, {
  type: "table",
  superType: "element",
  initialize(tableOption: any, options: any) {
    options = options || {};
    this.callSuper("initialize", options);
    this.set({
      tableOption,
      fill: "rgba(255, 255, 255, 0)",
      stroke: "rgba(255, 255, 255, 0)",
    });
    this.setControlsVisibility({ mtr: false });
  },
  setSource(source: any) {
    if (typeof source === "string") {
      this.setTableOptionStr(source);
    } else {
      this.setTableOption(source);
    }
  },
  setTableOptionStr(tableOptionStr: any) {
    this.set({
      tableOptionStr,
    });
  },
  setTableOption(tableOption: any) {
    this.set({
      tableOption,
    });
    this.distroyTable();
    this.createTable(tableOption);
  },
  createTable(tableOption: TableOption) {
    // Create runs from here
    this.instance = this.element;
    let option: TableOption;
    if (!tableOption) {
      option = {
        type: TableType.TABLE_OF_CONTENTS,
        hasTableHeaders: true,
        contents: [[{ text: "Title" }, { text: "Page No." }]],
      };
    } else {
      option = tableOption;
    }
    option.contents.forEach((contentData, row) => {
      let tr = document.createElement("tr");
      contentData.forEach((content, col) => {
        let tag = "td";
        if (row === 0 && option.hasTableHeaders) {
          tag = "th";
        }
        let td = document.createElement(tag);
        // Check text heading types
        if (content.type && content.type === "heading1") {
          td.classList.add("heading-1");
        } else if (content.type && content.type === "heading2") {
          td.classList.add("heading-2");
        } else if (content.type && content.type === "heading3") {
          td.classList.add("heading-3");
        } else {
          td.classList.add("heading-none");
        }
        let text;
        if (option.type === TableType.TABLE_OF_CONTENTS) {
          if (col === 0) {
            text = document.createTextNode(
              content ? content.text : `Heading Title ${row}`
            );
          }
          if (col === 1) {
            text = document.createTextNode(content ? content.text : `${row}`);
          }
        } else {
          text = document.createTextNode(content ? content.text : "");
        }
        text && td.appendChild(text);
        tr.appendChild(td);
      });
      this.instance.appendChild(tr);
    });

    option.type === TableType.TABLE_OF_CONTENTS &&
      fabric.util.addClass(this.instance, "table-of-contents");
  },
  distroyTable() {
    if (this.instance) {
      this.instance.dispose();
    }
  },
  toObject(propertiesToInclude: any) {
    return toObject(this, propertiesToInclude, {
      tableOption: this.get("tableOption"),
      container: this.get("container"),
      editable: this.get("editable"),
    });
  },
  _render(ctx: any) {
    this.callSuper("_render", ctx);
    if (!this.instance) {
      const {
        canvasId,
        id,
        scaleX,
        scaleY,
        width,
        height,
        angle,
        editable,
        tableOption,
      } = this;
      const zoom = this.canvas.getZoom();
      const left = this.calcCoords().tl.x + 10;
      const top = this.calcCoords().tl.y + 50;
      const padLeft = (width * scaleX * zoom - width) / 2;
      const padTop = (height * scaleY * zoom - height) / 2;
      this.element = fabric.util.makeElement("table", {
        id: `${id}_container`,
        style: `transform: rotate(${angle}deg) scale(${scaleX * zoom}, 
          ${scaleY * zoom});
          width: ${width}px;
          height: ${height}px;
          left: ${left + padLeft}px;
          top: ${top + padTop}px;
          position: absolute;
          user-select: ${editable ? "none" : "auto"};
          pointer-events: ${editable ? "none" : "auto"};`,
      });
      this.createTable(tableOption);
      const container = document.getElementById(`frame_${canvasId}`);
      container && container.appendChild(this.element);
    }
  },
});

const toObject = (obj: any, propertiesToInclude: any, properties: any) => {
  return fabric.util.object.extend(
    obj.callSuper("toObject"),
    propertiesToInclude.reduce(
      (prev: any, property: any) =>
        Object.assign(prev, {
          [property]: obj.get(property),
        }),
      Object.assign({}, properties)
    )
  );
};

export default Table;
