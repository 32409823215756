import React from 'react';
import Images from './components/Images';
import Table from './components/Table';
import Clone from './components/Clone';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { Tooltip, Button, Menu, Dropdown } from 'antd';
import './iconnav.scss';
import { ChartType, FabricObjectType } from '../../../../core/enums/enum';
import { printReportById } from '../../../../core/services/project.service';

interface PropTypes {
	canvas: any;
	canvasId: any;
	shape?: string;
	onSave: () => void;
	onChartClick: (type: ChartType) => void;
	onObjectDelete: () => void;
	onObjectAdd: (objectType: FabricObjectType) => void;
	onTableClick: () => void;
	projectId: string;
	projectTitle: string;
}

const IconNavbar = (props: PropTypes): JSX.Element => {
	const [charts] = React.useState([
		{ title: 'Line', type: ChartType.LINE_CHART },
		{ title: 'Bar', type: ChartType.BAR_CHART },
		{ title: 'Pie', type: ChartType.PIE_CHART },
	]);

	const [shapes] = React.useState<Array<FabricObjectType>>([
		FabricObjectType.LINE,
		FabricObjectType.CIRCLE,
		FabricObjectType.RECTANGLE,
		FabricObjectType.TRIANGLE,
		FabricObjectType.SQUARE,
	]);

	const saveCanvasData = () => {
		props.onSave();
	};

	const handleSettings = () => {
		window.open(`/preview/${props.projectId}`);
	};

	const handlePrint = async () => {
		const res = await printReportById(props.projectId);
		console.log(res.data);
		const url = window.URL.createObjectURL(new Blob([res.data]));

		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `${props.projectTitle}.pdf`);
		link.click();

		window.URL.revokeObjectURL(url);
	};

	const addChart = (type: ChartType) => {
		props.onChartClick(type);
	};

	const addTable = () => {
		props.onTableClick();
	};

	const menu = (
		<Menu>
			{shapes.map((item, index) => (
				<Menu.Item key={index} className='shapes__items'>
					<Button
						className='menu-shapes-btns'
						onClick={() => props.onObjectAdd(item)}>
						<img
							src={`../../assets/images/menubar/${item}.svg`}
							className='img-fluid h-18'
							alt={item}
						/>
						<span>{item}</span>
					</Button>
				</Menu.Item>
			))}
		</Menu>
	);

	return (
		<div className='icon__menubar d-flex align-center'>
			{/* Gare */}
			<div className='menu-save-setting br'>
				<Tooltip placement='bottomLeft' title='Settings'>
					<Button className='menu-btn' onClick={handleSettings}>
						<img
							src='../../assets/images/menubar/setting.svg'
							className='img-fluid'
							alt='setting'
						/>
					</Button>
				</Tooltip>
				<Tooltip placement='bottomLeft' title='Save'>
					<Button className='menu-btn' onClick={saveCanvasData}>
						<img
							src='../../assets/images/menubar/save.svg'
							className='img-fluid'
							alt='save'
						/>
					</Button>
				</Tooltip>
			</div>
			{/* Print */}
			<div className='page-print br'>
				<Tooltip placement='bottomLeft' title='Print'>
					<Button className='menu-btn' onClick={handlePrint}>
						<img
							src='../../assets/images/menubar/print.svg'
							className='img-fluid'
							alt='Print'
						/>
					</Button>
				</Tooltip>
			</div>
			{/* Text */}
			<div className='basic-text-opt br'>
				<Tooltip placement='bottomLeft' title='Text'>
					<Button
						className='menu-btn'
						onClick={() => props.onObjectAdd(FabricObjectType.TEXT)}>
						<img
							src='../../assets/images/menubar/text.svg'
							className='img-fluid'
							alt='Text'
						/>
					</Button>
				</Tooltip>
				{/* Image
        <Images canvas={props.canvas} canvasId={props.canvasId} />*/}

				<Tooltip placement='bottomLeft' title='Image'>
					<Button
						className='menu-btn'
						onClick={() => {
							props.onObjectAdd(FabricObjectType.IMAGE);
						}}>
						<img
							src='../../assets/images/menubar/gallery.svg'
							className='img-fluid'
							alt='Image'
						/>
					</Button>
				</Tooltip>
			</div>
			{/* Charts */}
			<div className='chart-page br'>
				{charts.map((item, index) => (
					<Tooltip
						placement='bottomLeft'
						title={item.title}
						key={index.toString()}>
						<Button className='menu-btn' onClick={() => addChart(item.type)}>
							<img
								src={`../../../../assets/images/menubar/${item.type.toLowerCase()}.svg`}
								className='img-fluid'
								alt='chart'
							/>
						</Button>
					</Tooltip>
				))}
				{/* Table */}
				<Tooltip placement='bottomLeft' title='Table Of Contents'>
					<Button className='menu-btn' onClick={addTable}>
						<img
							src='../../assets/images/menubar/table.svg'
							className='img-fluid'
							alt='table'
						/>
					</Button>
				</Tooltip>
			</div>
			{/* Shapes */}
			<div className='shapes-show br'>
				<Dropdown overlay={menu} className='menu-btn'>
					<div
						className='ant-dropdown-link'
						onClick={(e) => e.preventDefault()}>
						<img
							src='../../assets/images/menubar/shapes.svg'
							className='img-fluid h-18'
							alt='Down Arrow'
						/>
					</div>
				</Dropdown>
			</div>
			{/* Delete Object */}
			<div className='delte__show br'>
				<Tooltip placement='bottomLeft' title='Delete'>
					<Button className='menu-btn' onClick={props.onObjectDelete}>
						<img
							src='../../assets/images/menubar/delete.svg'
							className='img-fluid h-18'
							alt='Delete'
						/>
					</Button>
				</Tooltip>
			</div>
			{/* Clone */}
			<Clone canvas={props.canvas} canvasId={props.canvasId} />
			{/* Object to back */}
			<div className='chart-page br'>
				<Tooltip placement='bottomLeft' title='Send To Back'>
					<Button
						className='menu-btn'
						onClick={() => {
							props.canvas.map((item: any) => {
								if (item.canvasDom.getActiveObject()) {
									item.canvasDom.sendToBack(item.canvasDom.getActiveObject());
								}
							});
						}}>
						<UpOutlined />
					</Button>
				</Tooltip>
			</div>
			{/* Object to front */}
			<div className='chart-page br'>
				<Tooltip placement='bottomLeft' title='Bring to Front'>
					<Button
						className='menu-btn'
						onClick={() => {
							props.canvas.map((item: any) => {
								if (item.canvasDom.getActiveObject()) {
									item.canvasDom.bringToFront(item.canvasDom.getActiveObject());
								}
							});
						}}>
						<DownOutlined />
					</Button>
				</Tooltip>
			</div>
		</div>
	);
};

export default IconNavbar;
