import React from 'react';
import {
	Button,
	Dropdown,
	Typography,
	Form,
	Menu,
	Popover,
	Slider,
} from 'antd';
import { SketchPicker } from 'react-color';
interface PropTypes {
	canvas: any;
	canvasId: any;
	type: string;
	selectedObject: any;
	color: string;
	setCanvasColor: Function;
}

const tailLayout = {
	labelCol: { span: 24 },
	wrapperCol: { span: 24 },
};

const CanvasStyleObject = (props: PropTypes) => {
	const fillColor = (value: any): void => {
		props.canvas.map((item: any) => {
			if (item.id === props.canvasId) {
				item.canvasDom.backgroundColor = value.hex;
				item.canvasDom.renderAll();
			}
		});
	};

	return (
		<div className='text__style__wrapper'>
			<Form {...tailLayout} name='basic'>
				<Form.Item label='Color' name='color'>
					<Popover
						placement='bottom'
						content={
							<SketchPicker
								color={props.color}
								onChangeComplete={(value: any) => {
									props.setCanvasColor(value.hex);
									fillColor(value);
								}}
							/>
						}
						trigger='click'
						overlayClassName='colorpop'>
						<Button className='colorPickers'>C</Button>
					</Popover>
				</Form.Item>

				{/* <Form.Item label="Opacity" name="opacity">
          <Slider
            min={0}
            max={1}
            value={opacity}
            step={0.01}
            onChange={(value: number) => {
              setOpacity(value);
              props.canvas.map((item: any) => {
                item.canvasDom.set({
                  opacity: +value,
                });
                item.canvasDom.renderAll();
              });
            }}
          />
        </Form.Item> */}
			</Form>
		</div>
	);
};

export default CanvasStyleObject;
