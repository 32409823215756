import React, { useState } from "react";
import {
  Button,
  DatePicker,
  Radio,
  Dropdown,
  Form,
  Input,
  Upload,
  Menu,
  Typography,
} from "antd";
import { Moment } from "moment";

interface IForm {
  headerTitle: string;
  headerDate: Moment;
}

interface IProps {
  canvas: any;
  canvasId: any;
  selectedObject: any;
  headerLineType: string;
  headerLineStroke: number;
  setHeaderLineStroke: Function;
  setHeaderLineType: Function;
  headerFooterObject: Array<Object>;
  headerFooterForms: any;
  updateHeaderFooterSettings: (values: IForm) => void;
}

const tailLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const HeaderFooterSettings: React.FC<IProps> = (props) => {
  const onFinish = (values: IForm) => {
    props.updateHeaderFooterSettings(values);
  };

  function getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const imagePropsConfig = (imageType: string) => {
    const imageProps = {
      name: "imageFile",
      action: `${process.env.REACT_APP_API_URL}/upload`,

      onChange(info: any) {
        if (info.file.status !== "uploading") {
        }
        if (info.file.status === "done") {
          getBase64(info.file.originFileObj, (imageUrl: any) => {
            // setResponseImageUrl(imageUrl);
            props.canvas.map((item: any) => {
              item.canvasDom._objects.forEach((obj: any) => {
                if (obj._id === imageType) {
                  obj
                    .set({
                      width: obj.width,
                      height: obj.height,
                      crossorigin: "anonymous",
                    })
                    .setSrc(
                      `${process.env.REACT_APP_BASE_URL}/${info.file.response.file}`,
                      (img: any) => {
                        obj.toObject();
                        item.canvasDom.renderAll();
                      }
                    );
                }
              });
            });
          });
        } else if (info.file.status === "error") {
        }
      },
    };
    return imageProps;
  };

  const menu = (
    <Menu
      onClick={(e) => {
        props.canvas.map((item: any) => {
          item.canvasDom._objects.forEach((obj: any) => {
            if (
              obj.toObject()._id === "footer-line" ||
              obj.toObject()._id === "header-line"
            ) {
              obj.set({
                strokeWidth: +e.key,
              });
              item.canvasDom.renderAll();
            }
          });
        });

        props.setHeaderLineStroke(+e.key);
      }}
    >
      <Menu.Item key="1">
        <Typography>1</Typography>
      </Menu.Item>
      <Menu.Item key="2">
        <Typography>2</Typography>
      </Menu.Item>
      <Menu.Item key="3">
        <Typography>3</Typography>
      </Menu.Item>
      <Menu.Item key="4">
        <Typography>4</Typography>
      </Menu.Item>
      <Menu.Item key="5">
        <Typography>5</Typography>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="text__style__wrapper">
      <Form
        {...tailLayout}
        form={props.headerFooterForms}
        name="basic"
        onFinish={onFinish}
      >
        <Form.Item
          label="Header Title"
          name="headerTitle"
          rules={[
            { required: true, message: "Please input your header title!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Header Date"
          name="headerDate"
          rules={[
            { required: true, message: "Please input your header date!" },
          ]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>

        <Upload {...imagePropsConfig("footer-preparedForBy")}>
          <Button type="default" onClick={() => {}}>
            Upload Prepared For Image
          </Button>
        </Upload>

        <Upload {...imagePropsConfig("footer-preparedForImage")}>
          <Button
            style={{
              marginTop: 14,
              marginBottom: 14,
            }}
            type="default"
            onClick={() => {}}
          >
            Upload Prepared By Image
          </Button>
        </Upload>

        <Radio.Group
          value={props.headerLineType}
          style={{ marginBottom: 14 }}
          onChange={(e) => {
            const lineType = e.target.value;

            props.setHeaderLineType(lineType);
            const propsToInclude = [
              "id",
              "name",
              "locked",
              "editable",
              "headingType",
            ];
            props.canvas.forEach((item: any) => {
              item.canvasDom._objects.forEach((obj: any) => {
                if (obj.toObject(propsToInclude)._id === "footer-line"
                    || obj.toObject(propsToInclude)._id === "header-line"
                    || obj._id === "footer-line"
                    || obj._id === "header-line"
                ) {
                  obj.set({
                    strokeDashArray: lineType === "normalLine" ? [0, 0] : [5, 5]
                  });

                  item.canvasDom.renderAll();
                }
              });
            });
          }}
        >
          <Radio value="normalLine">Line</Radio>
          <Radio value="dashedLine">Dashed Line</Radio>
        </Radio.Group>

        <Typography>Stroke Width</Typography>
        <Dropdown overlay={menu}>
          <Button>{props.headerLineStroke}</Button>
        </Dropdown>
      </Form>
    </div>
  );
};

export default HeaderFooterSettings;
